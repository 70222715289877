.campaignCard {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow: hidden;
  height: 100%;
  justify-content: space-between;
}

.cardContainer {
  width: 100%;
  max-width: 350px;
  border-radius: 15px;
  background-color: #fffdfa;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  font-size: 14px;
  margin: 10px auto; /* Keep uniform margin */
  display: flex;
  flex-direction: column;
}


.descCardContainerMain {
  display: flex;
  flex-direction: column;
}

.descCardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.headerImage {
  width: 100%;
  height: auto;
  border-radius: 15px 15px 0 0;
}

.cardDetails {
  padding: 10px 15px;
  height: 220px;
}

.campaignName {
  word-break: break-word;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-top: 5px;
}

.detailLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 5px 0;
}

.campaignDesc {
  padding: 10px 15px;
  font-size: 14px;
  color: #333;
}

.card_cardActions__1hL6B {
  padding: 10px 5px 5px 10px;
  align-self: center;
  width: 90%;
  height: 40px;
  background-color: var(--primaryColor);
  box-shadow: 0 3px 6px rgba(0,0,0,.1607843137254902);
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.card_cardContainer__2rrDb button {
  width: 100%;
  background-color: transparent;
  color: #fdfdfd;
  font-size: 17px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.cardActions {
  display: flex;
  justify-content: center; /* Center align */
  margin-bottom: 10px; /* Add bottom space */
  height: 25px;
}

.bookNowButton {
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}

.bookNowButton:hover {
  background-color: #d9a20a;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}


.linkButton {
  width: max-content;
  padding: 8px 32px;
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  color: var(--primaryColor);
}

.imageContainer {
  position: relative;
  width: 100%;
}

.headerImage {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.overlayContent {
  position: absolute;
  top: 0px;
  left: 5px;
  right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.5); */
  padding: 10px;
  border-radius: 10px;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ff5600;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0b90b;
}

.author-name {
  font-weight: bold;
  text-decoration: none;
  color: #ff5600;
}

.pub-on {
  font-size: 12px;
  color: #ff5600;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-buttons a {
  color: #ff5600;
  font-size: 18px;
}

.action-buttons a:hover {
  color: #ff5600;
  font-size: 18px;
}

.author-name:hover, .pub-on:hover{
  color: #ff5600;
} 
