/* @import url("https://fonts.googleapis.com/css2?family=Rye&family=Unica+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ephesis&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;700&family=Playfair+Display:wght@400;700&family=Roboto+Condensed:wght@300;400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Marcellus&family=Oswald:wght@300;400;700&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://unpkg.com/aos@next/dist/aos.css"); */


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Telugu:wght@100..900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Mallanna&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Telugu:wght@100..900&display=swap');


/* @import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Ramabhadra&display=swap'); */
/* font-family: 'Rye', cursive;
font-family: 'Unica One', cursive; */

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Telugu:wght@100..900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Mallanna&family=Mandali&family=Noto+Serif+Telugu:wght@100..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');



/* font-family: "Mandali", serif; */

:root {
  --colorheading1: #c15506;
  --colororange2: #e76d14;
  --colororange3: #ff5600;
  --colororange4: #e19872;
  --color-secondary-rgb: 72, 86, 100;
  --colororange4: #9e8a7b;
  --colorRed2: #c93937;
  --colorheadingv2: #5a3f77;
  --colorRed: #b11f2d;
  --colorheadingYellow: #ffbd01;
  --white: #ffffff;
  /* --fontenglish: "Mandali", serif; */
  --fontenglish: "Noto Sans Telugu", sans-serif;
  /* --fontenglish: "Montserrat", sans-serif; */
  /* --fontenglish:    "Mallanna","Poppins", serif; */

  --fontsize1:    14px;
  --fontsize2:    15px;
  --fontsize3:    17px;
  --fontcolorblack: #0c0c0c;
  /* --fonttelugu: "Mandali", serif; */


  --default-color: #444444;
    --heading-color: #040677;
    --accent-color: #1acc8d;
    --surface-color: #ffffff;
    --contrast-color: #ffffff;
}


.h3, h3 {
  font-size:  var(--fontsize3) !important;
  line-height: 29px !important;
  margin-bottom: 0 !important;
}

.font-weight-bold{
  font-weight: 600;
}

.ant-select-selection-item .tiles-designs-events {
  /* background-color: rgb(255 252 191); */
  /* height: 35%; */
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.ant-dropdown, .ant-dropdown-menu-submenu, .ant-select-selector{
  font-family: var(--fontenglish) !important;
}

.noto-serif-telugu-uniquifier {
  font-family: var(--fontenglish);
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.bg-grey2 {
  background-color: lightgray;
}

.font_main {
  font-family: var(--fontenglish) !important;

  font-optical-sizing: auto;
  /* color: var(--fontcolorblack); */
}

.events-block-link-title {
  color: var(--colorRed2);
  font-weight: 500;
  margin-bottom: 10px;
}

.events-block-link-value {
  font-weight: 500;
  color: grey;
}

.txt-white-forgot-password span {
  color: white;
}

.colorHeadingviolet {
  color: var(--colorheading1);
   font-size: var(--fontsize1);
}

.flex-space-between {
  justify-content: space-between;
}

.tiles-main a:hover {
  text-decoration: none;
}

/* a{
  color: black !important;
    text-shadow: 1px 1px 3px #818181;

} */
.fw-7 {
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
   font-size: var(--fontsize1);
}

html,
body {
  height: 100%;
  font-family: var(--fontenglish) !important;
  font-weight: 500;

}

/* .font-condenced {
  font-family: "Oswald", sans-serif;
  font-family: "Roboto Condensed", sans-serif;
} */

.f-grey {
  color: grey;
}

.f1 {
   font-size: var(--fontsize1);
}

.f2 {
   font-size: var(--fontsize2);
}

.f3 {
  font-size: 17px;
}

.f4 {
  font-size: 25px;
}

#root>div {
  height: 100%;
}

.color-yellow {
  color: #f28400;
}

.main-content {
  float: left;
  width: 100%;
  height: calc(100% - 72px);
  overflow: auto;
}

.main-content-home {
  float: left;
  width: 100%;
  height: calc(100% - 72px);
  overflow: auto;
}

.f13-fw-600 {
   font-size: var(--fontsize1);
  font-weight: 600;
}

.search-500 {
   font-size: var(--fontsize1);
  width: 50px;
  font-weight: 500;
}

.search-alert {
   font-size: var(--fontsize1);
}

.search-city {
  width: 46%;
  margin-right: 4%;
}

.search-city .ant-select {
  width: 100%;
}

.menu-side {
  font-size: 22px;
}

@font-face {
  font-family: var(--fontenglish);
  src: url(../../assets/fonts/LeagueSpartan-VariableFont_wght.ttf);
}

.colorb {
  background-color: #00a4ff;
  background-image: linear-gradient(0deg, #2948ab, transparent);
  background-color: #55093e;
}

a:hover {
  /* color: transparent; */
  text-decoration: none;
}

btn {
   font-size: var(--fontsize1);
}

.btn-primary:hover {
  color: #fff;
  background-color: #faad14;
  border-color: #6c757d;
}

.hamb i {
   font-size: var(--fontsize3);
}

.today-horo-text {
  display: none;
}

.login-form,
.add-temples {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(180deg, #ebdef0, #f8d3b8);
  background-repeat: no-repeat;
  background-position: top;
  flex-direction: column;
}

.form-card {
  background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
  border-radius: 20px;
  transition: all 0.3s;
}

.form-card:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
}

.cta.content .cta-btn {
  color: var(--color-white);
  font-weight: 500;
   font-size: var(--fontsize2);
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  background: rgba(var(--color-primary-dark-rgb), 0.9);
}

.form-box {
  padding: 10px 20px !important;
  border-radius: 15px;
  font-family: var(--fontenglish);
  transition: all 0.2s;
  height: 100%;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

#basic .ant-form-item {
  margin-bottom: 0;
}

/* .form-box:hover {
    transform: scale(0.99);
    border-radius: 15px;
} */

.login-form-forgot {
  justify-content: end;
  color: rgb(177 111 55) !important;
  font-weight: 400;
  font-family: var(--fontenglish);
  font-size: 12px;
  padding: 6px 0;
  text-align: right;
float:right;
}
.reg-btn-login a{
  color: rgb(177 111 55) !important;
  font-size: 12px !important; 
}
.login-form-checkbox-text {
color: rgb(177 111 55) !important;
font-size: 12px !important;
font-weight: 400 !important;
}

.login-form-error{
  justify-content: end;
  color: red;
  font-weight: 400;
  font-family: var(--fontenglish);
  font-size: 12px;
  padding: 6px 0;
  text-align: left;
  display:block;
  float:left;
  margin-bottom:0;
}

 
.map-txt{
  text-align: center;
  margin-top: 10px;
  font-size: large;
  color:#c75402 ;
}

.map-txt1{
  text-align: center;
  margin-top: -4px;
  font-size: medium;
  color:#c75402 ;
}

.ant-input,
.ant-input-affix-wrapper {
  background-color: #fff;
  padding: 0 0 0 0px;
  height:27px;
}

span.ant-input-suffix {
  padding: 3px;
}

span.ant-input-prefix {
  padding: 5px;
}

.ant-input {
  /* background-color: #fff !important; */
  padding: 10px !important;
  height:25px;
  font-family: var(--fontenglish) !important;
}

.god_select p {
  margin-bottom: 0px;
  text-align: center;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #e67e22;
}

.ant-btn-primary {

  background-color: #e67e22;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px 10px;
  /* transition: all 0.1s; */
  font-family: var(--fontenglish);
  font-weight: 500;
}

.ant-btn-default {

  border-radius: 5px;
  cursor: pointer;
  padding: 3px 10px;
  box-shadow: 0px 2px 0px #aeaeae;
  /* transition: all 0.1s; */
  font-family: var(--fontenglish);
  font-weight: 500;
}

.ant-btn-primary>span {
   font-size: var(--fontsize2);
}

/* :where(.css-dev-only-do-not-override-diro6f).ant-row {
    justify-content: end !important;
} */
.ant-modal-content .a {
  color: blue;
}

/* .login-form-forgot a:hover {
  color: blue;
} */

:where(.css-dev-only-do-not-override-ed5zg0).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-ed5zg0).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-ed5zg0).ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #e67e22;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #e67e22;
  background: #ffffff;
  border-color: #e67e22;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #e67e22;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #e67e22;
}

.user_icon {
  color: #000;
}

.forget a:hover {
  color: var(--colorheadingYellow);
}

.tile-quicklink button {
  width: auto !important;
}

.ant-btn-primary.hoverbg:not(:disabled):hover {
  color: #fff;
  background-color: #fb9c09 !important;
  box-shadow: 3px 3px 3px #7b7575;
}

.login-form-button {
  width: 100%;
  color: white;
  border-radius: 5px;
  background: #fb9c09;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
  padding: 0px;
}


.slick-dots.slick-dots-none {
  bottom: 15px;
}

.slick-dots.slick-dots-none button::before {
  display: none;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-btn-default:not(:disabled):hover {
  color: none;
  border-color: #ff8c00;
}

.ant-modal-title {
  display: flex;
  /* justify-content: center; */
  color: rgb(255 255 255) !important;
  background: var(--colorheading1);
  padding: 10px;
   font-size: var(--fontsize2);
}

.diag-header {
  display: flex;
  /* justify-content: center; */
  color: rgb(255 255 255) !important;
  background: #dd4c11;
  padding: 10px 10px;
   font-size: var(--fontsize2);
  font-weight: 500;
  justify-content: space-between;
  box-shadow: 0px 1px 10px #d3d3d3;

}



.search-width {
  width: 500px
}


.search-quicklinks a,
.search-quicklinks button {
  margin-bottom: 10px;

}

.search-quicklinks {
  overflow: auto;
  padding-bottom: 14px;
  margin-left: -7px;
}

/* .user-detail-text3 {
  box-shadow: 9px 9px 10px #ddd;
} */

.ant-btn-primary.settings-details {
  background-color: #3c0780;
  padding: 0px 15px;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
}

.ant-btn-primary.settings-details:hover {
  background-color: transparent;
}

/* .ant-btn-primary:not(:disabled):hover {
    background-color: transparent;
} */

.ant-form-item {
  margin-bottom: 0px;
}

.ant-typography {
  font-family: var(--fontenglish);
}

.banner {
  background: url(../../assets/images/mandala_top.png),
    linear-gradient(180deg, #ebdef0, #f8d3b8);
  background-repeat: no-repeat;
  background-position: top;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-form img {
  padding-bottom: 10px;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
}

.dot-active {
  width: 50px;
  height: 15px;
  background-color: #ff9700;
  border-radius: 20px;
}
.pageheader {
  display: flex;
  justify-content: end;
  background-color: #ecdeee;
}
.pageheader-buttons{
overflow-x: auto;
    padding-bottom: 2px;
    white-space: nowrap;
    justify-content: end;
    display: flex
;
}

a.ant-dropdown-trigger {
  background-color: #eb5e41;
  color: white;
  margin-top: 5px;
  margin-right: 7px;
  padding: 3px;
  border-radius: 10px;
}

.ham {
  justify-content: left;
  height: 40px;
  /* background-color: var(--colororange3); */
  position: sticky;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
   background-image: linear-gradient(45deg, #850750, #ed1010, #f5aa02);
}

/* .hamb, .ant-btn-primary, .ant-btn-primary:not(:disabled):hover{
   
    /* margin-top: 0;
    box-shadow: none;
    color: white; */
/* border-radius: 0px;
    padding: 0px 0px;
    margin-left: 10px;
    background: transparent; */

*/ .name {
  color: rgb(250, 238, 238);
  margin-bottom: 10px;
}

.main {
  width: 100%;
  background-image: url("../../assets/images/mandala_top.png");
  background-repeat: no-repeat;
  background-position: right top;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h4.name {
  color: white;
  margin-bottom: 20px;
}

/* .card {
  width: 190px;
} */

.ant-card-cover {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #eee;
  border-radius: 15px 15px 0 0;
}

.ant-card-cover img {
  border-radius: 5px !important;
}

.brang-logo {
  font-family: var(--fontenglish);
  font-size: 28px;
  color: #fff;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.settings-details {
  color: #f5f5f5;
   font-size: var(--fontsize3);
  line-height: 37px;
}

.settings-details:hover {
  color: #f9f9f9;
}

.w-994 {
  width: 100%;
}

.slider-side-lt {
  z-index: 1;
}

.slider-side-rt {
  transform: rotatey(180deg);
}

.flowers_top {
  background-image: url(../../assets/images/flowers_top.png);
  height: 45px;
  width: 100%;
  background-repeat: repeat-x;
  position: absolute;
  z-index: 2;
  background-size: contain;
}

.caroselPic img {
  width: 100%;
  margin: auto;
}

.nearby-no {
  width: 40px;
  border-radius: 6px;
  height: 20px;
  background: white;
  /* border: 1px; */
  color: #000000;
  font-weight: 600;
}

.home-temple-text::first-letter {
   font-size: var(--fontsize3);
}

.home-temple-text {
  color: var(--colorheading1);
   font-size: var(--fontsize1);
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
  line-height: 25px;
  text-align: left;
  font-family: var(--fontenglish);
}

/* .home-temple-text ~ p {
  color: #5a0039;
} */

.tiles-main {
  flex-flow: row wrap;
  justify-content: center;
  display: flex;
  clear:both;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-dropdown {
  min-width: 180px !important;
}
.tileswidth .tiles-design {
  width: 93%;
  margin:3%;
}
.tiles-design {
  width: 250px;
  margin: 10px;
  flex: 0 0 auto;
  transition: box-shadow 0.5s;
  border-radius: 0px;
  overflow: hidden;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15), 0 5px 7px rgba(0, 0, 0, 0.25);
  background: #fff;
  padding: 3px;
}

.tiles-design-temple-cat {
  border: 1px solid #cbcbcb;
  margin: 10px;
  transition: box-shadow 0.5s;
  position: relative;
  animation-duration: 2s;
  border-radius: 5px;
  overflow: hidden;
  transition: all .5s;
  transform: scale(1);

}

.tiles-design a:hover,
.tiles-design-temple-cat a:hover {
  text-decoration: none;
}

.tile-text-temple-cat {
  position: relative;
  z-index: 1;
}

.tiles-design:hover,
.tiles-design-temple-cat:hover {
  box-shadow: 2px 5px 9px #00000045;
  cursor: pointer;
}

.tiles-design-temple-cat:hover .title-img-bg img {
  transform: scale(1.2);

}

.tiles-design-temple-cat .title-img-bg img {
  transform: scale(1);
  transition: all 1s;
}


.library-block {
  transition: transform 0.3s ease;
}

.library-block:hover {
  transform: scale(0.9);
}


.title-img-bg {
  background-color: #fff;
}

.title-img-bg.templescatbg {
  background-color: #fff;
  background-image: url(../../assets/images/tam_cat_bg.jpg);
  width: 198px;
  height: 140px;
  background-size: cover;
    background-repeat: no-repeat;
}

.tile-quicklink {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 5px;
  position: absolute;
  right: 0;
  bottom: 3px;
  width: 98%;
  height: 22px;
  padding-top: 5px;
}

.tiles-quicklink {
  display: flex;
  justify-content: flex-end;
  color: #6c1bb5;
  margin-bottom: 6px;
   font-size: var(--fontsize1);
  /* position: absolute; */
  right: 0;
  bottom: 0px;
}

:where(.css-11lehqq).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fb9c09;
  border-color: #fb9c09;
}

:where(.css-11lehqq).ant-input-affix-wrapper>input.ant-input,
:where(.css-11lehqq).ant-input-affix-wrapper>textarea.ant-input {
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background: transparent;
  color: #090909;
}

.tiles-quicklink i {
  display: flex;
  justify-content: flex-end;
  color: #6c1bb5 !important;
  margin-bottom: 6px;
   font-size: var(--fontsize1);
  /* position: absolute; */
  right: 0;
  bottom: 0px;
}

.tile-text {
  padding-bottom: 20px;
}

.tile-text>div {
  padding: 6px;
}

.tile-text1 {
  padding-bottom: 20px;
}

.tile-text1>div {
  padding: 6px;
}

.tile-quicklink i,
.tiles-quicklink i {
  margin: 0 14px;
   font-size: var(--fontsize1);
  float: left;
  color: var(--colorheading1);
}

/* card */

.card-img-container {
  height: 150px;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  /* border: 3px solid white; */

}

.card-img-container .card-img {
  transform: scale(1);
  transition: all 1s;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card-img-container:hover .card-img {

  transform: scale(1.2);

}



.card-style a:hover {
  text-decoration: none;
  box-shadow: 10px 10px 10px #ddd;
}

/* .card-style a{
    text-decoration: none;
    box-shadow: 10px 10px 10px #000;
    } */
.card-style {
  margin: 15px 15px;
  position: relative;
}

/* card ends */

.mainmenu {
  height: 32px;
  width: 100%;
  background: #341333;
}

.contribution-header,
.notification-header1,
.favourite-header,
.gallery-title,
.articles-header {
  display: flex;
  justify-content: space-between;
  background-color: #ffbc05;
  border-bottom: 2px;
  margin-bottom: 3px;
  color: white;
}

.contribution-list,
.notification-list,
.favourite-list {
  background-color: #f3ecec;
  color: rgb(14, 13, 13);
  border-bottom: 2px;
  padding: 10px;
  margin-bottom: 3px;
  border-radius: 5px;
}

.DeleteOutlined {
  display: flex;
  justify-content: end;
}

/* .ant-drawer-wrapper-body{
    background-color: #ebe841;
} */

.ant-drawer-wrapper {
  z-index: 1000;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-drawer-footer {
  border-top: 1px solid #d9d9d9;
  padding: 16px;
  text-align: right;
}

.ant-drawer-close {
  position: absolute;
  top: 16px;
  right: 16px;
   font-size: var(--fontsize3);
  color: #434343;
  cursor: pointer;
}

.ant-drawer-close:hover {
  color: #c99b00;
}

/* Temple-specific styles */

.ant-drawer-header h2 {
  color: #aa7070;
  text-shadow: 2px 2px 2px #f1ebeb;
}

.ant-drawer .ant-drawer-title {
  color: rgb(243 237 237 / 88%);
}

.ant-pagination {
  display: flex;
  justify-content: center;
}

.ant-drawer-body a {}

.notification-button {
  background-color: rgb(224, 106, 28);
  border-radius: 5px;
  color: rgb(12, 12, 12);
  border: rgb(12, 12, 12);
  margin-right: 5px;
  padding: 7px;
}

.notifi-button {
  display: flex;
  justify-content: end;
  background-color: rgb(224, 106, 28);
  border-radius: 5px;
  color: rgb(250, 242, 242);
  border: white;
  padding: 7px;
}

.drawer-profile-view {
  display: flex;
  /* background-color: var(--colororange3); */
  width: 100%;
  height: 90px;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid grey;
}

.drawer-profile-view section {
  position: relative;
  top: 4px;
  margin-left: 20px;
}

/* .ant-drawer-header {
  background-color: #ff7732;
} */

.ant-drawer-close {
  top: 6px;
  right: 0;
}

.user-pic {
  width: 150px;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid white;
  border-radius: 50%;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  margin-top: 6px;
}

/* .d-flex.flex-column.align-items-center.text-center {
  background: linear-gradient(135deg, #f04d0b, #ff758c);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
 
} */

.user-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.user-pic-left-nav {
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid white;
  border-radius: 50%;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  margin-top: 6px;
}

.user-pic-left-nav img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
  color: #1f1f1f;
}

.upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(254, 105, 0, 0.8);
  visibility: hidden;
  border-radius: 50%;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.user-pic:hover .upload-overlay {
  opacity: 1;
  visibility: visible;
}

.upload-overlay a {
  color: white !important;
  background-color: transparent;
  /* border: 2px solid white; */
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}


.upload-overlay button {
  color: white;
  background-color: transparent;
  border: 2px solid white;
  padding: 5px 10px;
  cursor: pointer;
}

.user-detail {
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: 10px;
}

.user-detail .user-detail-text1 {
   font-size: var(--fontsize3);
  font-weight: 600;
}

.user-detail .user-detail-text2 {
   font-size: var(--fontsize2);
  font-weight: 600;

}

.user-detail .user-detail-text3 {
   font-size: var(--fontsize2);
  font-weight: 600;
  margin-bottom: 10px;
}

.cards {
  width: 310px;
  height: 520px;
  padding: 15px;
  margin-top: 40px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  box-shadow: 15px 10px 25px 0px #3fa1a9;
  background: #fff;
}

.profile form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.field {
  margin: 5px;
  display: flex;
  flex-direction: column;
}

input[type="file"] {
  display: none;
}

.form-item-spacing {
  margin-bottom: -20px;
  /* Adjust as needed */
}

/* 
i.fa-solid.fa-edit {
  color: #c15506 !important;
} */

span.ant-table-column-title span,
th.ant-table-cell span,
td.ant-table-cell a {
  color: #c15506 !important;
  font-weight: bold;
}

.form-item-spacing textarea,
.form-item-spacing input {
  display: block;
  /* Ensure block-level rendering */
  width: 100%;
  /* Full width */
}


h5#nest-messages_pcode {
  margin-top: 4px;
}

h5#nest-messages_cpcode {
  margin-top: 4px;
}

.details-icons-list:hover span,
.details-icons-list:hover i {
  color: grey;
}

button:focus:not(:focus-visible),
.btn:active:focus,
.btn:focus {
  outline: none !important;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none !important;
}

.ant-modal>div {
  height: 100%;
}


.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #a97f3f, #e48b18);
  margin-bottom: 25px;
}

.img-wrap {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before {
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}

.img-upload:hover:before {
  opacity: 1;
}

/* img {
    width: auto;
    height: 100%;
} */

/* label {
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
} */

input {
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
   font-size: var(--fontsize3);
  transition: 0.2s;
}

input:focus {
  outline: none;
  border: 1px solid #64d488;
}

input::placeholder {
  color: rgba(101, 101, 101, 0.504) !important;
}

.name {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
  max-width: 220px;
  overflow-wrap: break-word;
}

.status {
  text-align: center;
  max-width: 220px;
  overflow-wrap: break-word;
}

.cards button {
  position: relative;
  color: #054231;
  letter-spacing: 1px;
  margin: 20px;
   font-size: var(--fontsize3);
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  border-radius: 10px;
  cursor: pointer;
  border-radius: 25px;
  border: none;
  background: #64d488;
}

.save {
  font-weight: 600;
  left: -20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 20px;
  box-shadow: 20px 0px 40px 0px #63d3a6;
}

.edit {
  color: #fff;
  width: 180px;
}

.cards button:hover {
  left: 0;
  color: #fff;
  width: 180px;
  box-shadow: 0px 0px 20px 0px #63d3a6;
}

.cards button:focus {
  outline: none;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}



.bg-sec1 {
  background-color: #f1f1f1;
  margin-top: 70px;

  background-size: cover;
  display: flex;
  background-image: url("../../assets/images/bgs.jpg");

  justify-content: center;
  background-attachment: fixed;
  flex-direction: column;
  text-align: center;
  filter: saturate(80%);
  border: 20px var(--colororange3);
  ;
  border-style: ridge;
}

.sec-diag1-text {
  font-size: 50px;
  color: white;
  text-shadow: 4px 19px 5px #000;
  margin-top: 30px;
  font-family: var(--fontenglish);
  line-height: 60px;
}

.sec-diag1-text2 {
  font-size: 60px;
  color: yellow;
}

.cta {
  margin: 30px auto;
  background-image: url("../../assets/images/mandala_design.png") !important;
}



.cta .content h3 {
  color: var(--color-secondary);
  font-size: 48px;
  font-weight: 700;
}

.livetv {
  background-image: url("../../assets/images/tv.png");
  width: 100%;
  height: 200px;
  text-align: center;
  margin-top: 170px;
  border-radius: 15px;
  background-size: contain;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  border-style: dotted;
  border-color: #c3531a;
 
}

.links {
 height: calc( 100% - 91px );
 overflow: auto;
}

.ant-menu-item-only-child.menu-item.transparent .ant-menu-title-content, .ant-menu-item-only-child span{
  padding-left:20px;
}
.ant-menu-item-icon {
  /* display: none !important; */
}

.links a {
  width: 100%;
  height: 50px;
  color: #626262;
  line-height: 40px;
  /* padding-left: 20px; */
  cursor: pointer;
  align-items: center;
  display: flex;
  font-family: var(--fontenglish);
  font-weight: 500;
}

.links a:hover {
  color: #3d2529;
  /* text-shadow: 2px 3px 4px #38020263; */
  text-decoration: none;
  font-weight: 600;
}

.links a:active {
  position: relative;
  top: -2px;
}

:where(.css-dev-only-do-not-override-1htoz2s).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-1htoz2s).ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-color: #fafafa;
}

.links .ant-menu-title-content {
  text-align: left;
  font-family: var(--fontenglish) !important;
}

.live-blinker {
  height: 10px;
  position: absolute;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  top: 2px;
  animation: blinker 1s linear infinite;
  right: 3px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

:where(.css-dev-only-do-not-override-diro6f).ant-btn-primary:not( :disabled):active {
  position: relative;
  top: -2px;
}

:where(.css-dev-only-do-not-override-diro6f).ant-btn-primary:not( :disabled):hover {
  color: #3d2529;
  text-shadow: 2px 3px 4px var(--colororange3);
  ;
}

.profile {
  display: flex;
  justify-content: center;
}

.filter-god-txt {
  background: white;
  display: flex;
  align-items: center;
  padding-right: 10px;
   font-size: var(--fontsize2);
  padding-left: 10px;
  font-weight: bold;
}

.gods-section {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #2d0334c7;
  height: 60px;
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.title-head-txt {
  padding-left: 24px !important;
}

.nav_link {
  color: #000 !important;
}

.nav_link i {
  margin-right: 10px
}

/*
.notifi-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.notification-form {
    width: 400px;
    background: linear-gradient(270deg, #a97f3f, #e48b18);
    /* background-color: #00ff75; */
/*border-radius: 10px;
    padding: 20px;
}

.notification-form .n-form {
    display: flex;
    align-items: center;
    padding: 10px;
}

.notification-form .n-form label {
    width: 180px;
}

.notification-form .n-form input {
    border-radius: 0;
    width: 160px;
}

.notification-form button {
    margin-left: 100px;
    padding: 5px 15px;
    background-color: transparent;
    color: white;
    font-weight: 700;
    border: 1px solid white;
    border-radius: 5px;
}

 .profile-form {
    display: flex;
   flex-direction: column;
    align-items: center;
    background-color: #a97f3f;
    padding: 40px;
    border-radius: 5px;
    width: 500px;
}
.profile-form button,.profile-form input {  
    margin-left: 90px;
    border-radius: 0;
}
.profile-form label{
    width: 420px;
    color: white;
}
.profile-form select{
    margin-left: 175px;
}
.profile-form .imageUpload{
    border: 1px solid blue;
    text-align: center;
    color: rgb(10, 10, 10);
    padding:5px 0;
    background-color: white;
} */

/* .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-form label {
    margin: 10px 0;
  }
  
  .profile-form input,
  .profile-form select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 6px 0;
  }
  
  .profile-form button[type="submit"] {
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    padding: 10px 20px;
  }
  
  .profile-form button[type="submit"]:hover {
    background-color: #40a9ff;
  }
  
  .profile-form .imageUpload {
    border: 1px dashed #ccc;
    padding: 10px;
    text-align: center;
    cursor: pointer;
  }
  
  .profile-form .imageUpload input {
    display: none;
  }
  
  .profile-view img {
    max-width: 100%;
    margin-bottom: 10px;
  }
  
  .profile-view button {
    background-color: #f5222d;
    color: #fff;
    border: none
  } */

/* profile page css */
.profile-form label {
  display: block;
  margin-bottom: 10px;
}

.profile-form select,
.profile-form input[type="text"],
.profile-form input[type="file"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.profile-form select,
.profile-form input[type="password"] {
  width: 100%;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  width: 2p;
  height: 2px;
}

/* .add-page-container .ant-input {
  width: 100%;
  height: 20px  ;
  padding: 8px 12px;
   font-size: var(--fontsize2);
  border-radius: 4px;
  border: 1px solid #ccc;
} */

.profile-form .imageUpload {
  border: 1px dashed #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.imageUpload input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.imageUpload:hover {
  background-color: #f5f5f5;
}

.imageUpload span {
  margin-top: 5px;
}

.profi-form {
  max-width: 300px;
  margin: 0 auto;
}

.profile-form button {
  background-color: #1890ff;
  /* color: #fff; */
  border: none;
  /* padding: 10px 20px; */
  border-radius: 5px;
  cursor: pointer;
  margin-right: 30px;
}

.profile-form button:hover {
  background-color: #40a9ff;
}

.deleteButton {
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.deleteButton:hover {
  background-color: #ff7875;
}

.profile-update {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.profile-update img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

.profile-update button {
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.profile-update button:hover {
  background-color: #0062cc;
}

.profile-update p {
  margin-top: 10px;
   font-size: var(--fontsize2);
}

.profile-update p:first-child {
  margin-top: 0;
  font-weight: bold;
}

.profile-update p:last-child {
  margin-bottom: 0;
}

.login-form-forgot {
  display: block;
}

.forget {
  color: var(--colorheadingYellow) !important;
}

i.fa-solid.fa-up-right-from-square {
  color: #ff7732;
}

:where(.css-dev-only-do-not-override-diro6f).ant-drawer .ant-drawer-header {
  padding: 0;
}

/* profile page css ends */

.nav-icon {
  line-height: 14px;
  height: 17px;
  width: 40px;
  background: white;
  border-radius: 4px;
  padding: 0px;
  text-align: center;
  position: relative;
  display: none;
}

.nav-icon-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.details-icons-main {
  background: #f7f7f7;
  /* border-top: 1px solid #b7b7b7; */
  border-bottom: 1px solid #d3d3d3;
}

.details-icons {
  display: flex;
  /* justify-content: space-evenly; */
  font-size: 40px;
  margin-top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  grid-gap: 15px;
  gap: 15px;
}

.ant-dropdown-menu-title-content span {
   font-size: var(--fontsize1);
}

.ant-dropdown-menu-item-active {
  background: transparent;
  color: inherit;
  /* text-align: center; */
  background: var(--colorheading1);
  color: white;
  font-weight: bold;
  /* text-decoration: none; */
  border-radius: 0;
}

.menu-icon-single {
   font-size: var(--fontsize3);
  margin-left: 5px;
  margin-right: 10px;

}

.btn-c1 {
  color: #2889a3
}

.btn-c2 {
  color: #28a352
}

.btn-c3 {
  color: #db9223
}

.btn-c4 {
  color: #d958d8
}

.btn-c5 {
  color: #2749ef
}

.btn-c6 {
  color: #b3a839
}

.btn-c7 {
  color: #20809a
}

.btn-c8 {
  color: #689212
}

.btn-c9 {
  color: #ef2763
}

.btn-c10 {
  color: #3d2def
}


.details-icons-list {
  display: flex;
      font-size: var(--fontsize1);
      /* flex-direction: column; */
      align-items: center;
      justify-content: center;
      color: #b9b4b4;
      font-weight: 400;
      cursor: pointer;
      background: #ffffff7a;
      padding: 4px 6px 0px 6px;
      border: 1px solid #dbdbdb;
      border-radius: 5px;
      white-space: pre;
      line-height: 16px;
}

.details-icons-list:hover {
  box-shadow: 1px 1px 7px #d1d1d1;
}



.details-icons-list span {
   font-size: var(--fontsize2);
}

.details-icons i {
   font-size: var(--fontsize2);
  text-shadow: 3px 3px 3px #ddd;
  margin-right: 7px;
}

.nav-icon-main span {
  color: white;
  font-weight: 500;
   font-size: var(--fontsize2);
}

.nav-icon-main:hover .nav-icon i {
  text-decoration: underline;
  cursor: pointer;
  transform: rotateY(360deg);
  transition-duration: 1s;
}

.nav-icon-main:active {
  position: relative;
  top: -2px;
}

.nav-icon-main:hover {
  cursor: pointer;
}

.nav-icon-main:hover .nav-icon {
  box-shadow: inset 2px 2px 3px #6c6c6c;
  /* background: #ffc700; */
  color: #ffc700;
}

.nav-icon-main:hover span {
  color: #ffc700;
}

.nav-icon i {
   font-size: var(--fontsize1);
}

.nav-icon span {
  padding-top: 5px;
  color: white;
  font-weight: 500;
}

.nav-icon-main.active .nav-icon {
  color: #fff;
  background-image: linear-gradient(45deg, #f1296e, #ffbc42);
}

.nav-icon-main.active span {
  color: #ffc700;
}

/* Style for the notification form */
.notifi-form {
  display: flex;
  justify-content: center;
}

.notification-form {
  width: 80%;
  max-width: 400px;
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.n-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.notification-form label {
   font-size: var(--fontsize3);
  font-weight: bold;
  margin-bottom: 5px;
}

.notification-form input,
textarea,
select {
   font-size: var(--fontsize2);
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease-in-out;
  width: 100%;
  margin-top: 5px;
}

.notification-form input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: #3498db;
}

.notification-form button[type="submit"] {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
   font-size: var(--fontsize3);
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-top: 20px;
}

.notification-form button[type="submit"]:hover {
  background-color: #2980b9;
}

/* Style for the notification form */

.nav-topbanner {
  width: 50%;
}

.ant-tabs-nav-list {
  justify-content: space-evenly;
  width: 100%;
  background-image: linear-gradient(0deg, #c3c3c35e, transparent);
}

:where(.css-dev-only-do-not-override-diro6f).ant-tabs-top>.ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-diro6f).ant-tabs-bottom>.ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-diro6f).ant-tabs-top>div>.ant-tabs-nav::before,
:where(.css-dev-only-do-not-override-diro6f).ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: 1px solid rgb(5 5 5 / 38%);
}

:where(.css-dev-only-do-not-override-diro6f).ant-tabs .ant-tabs-ink-bar {
  background: #55093e;
}

:where(.css-dev-only-do-not-override-diro6f).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5a0039;
}

:where(.css-dev-only-do-not-override-diro6f).ant-tabs .ant-tabs-tab:hover {
  color: #5a0039;
}

:where(.css-dev-only-do-not-override-diro6f).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-diro6f).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-diro6f).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-diro6f).ant-tabs .ant-tabs-tab-remove:active {
  color: #5a0039;
}

:where(.css-dev-only-do-not-override-diro6f).ant-tabs .ant-tabs-tab {
  padding: 3px 0;
}

.footer-mobile {
  display: none;


}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 16;
}

a:active {
  color: black;
  font-family: var(--fontenglish);
}

.language {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 15px;
  width: 90px;
  height: 24px;
}

.language1 {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 15px;
  height: 24px;
}

.language .ant-dropdown-trigger {
  margin-right: 20px;
  margin-top: 20px;
}

.language .ant-space {
  padding: 0 10px;
}




.Dropdown {
  display: flex;
  justify-content: end;
  align-items: center;
}

.search-form input {
  border: 0;
   font-size: var(--fontsize2);
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  /* padding: 3px 26px 3px 8px; */
  border-radius: 3px 0 0 3px;
  transition: 0.3s;
  width: 80%;
}

.search-form input:focus,
.search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.search-bar {
  min-width: 500px;
  padding: 0 10px;
  height: 100%;
  display: flex;
}

:where(.css-dev-only-do-not-override-diro6f).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: orange;
}

:where(.css-dev-only-do-not-override-diro6f).ant-select-dropdown {
  border-radius: 3px;
}

.ant-select-dropdown{
  z-index: 10000;
}

:where(.css-dev-only-do-not-override-diro6f).ant-select-single .ant-select-selector {
  border-radius: 3px;
}

.search-form {
  grid-gap: 5px;
  gap: 5px;
}

.search-form button {
  border: 0;
  padding: 0;

  background: none;
}

.search-form button i {
  color: #012970;
}

.search-form .button-sec {
  border: 0;
  padding: 0;

  background: none;
  display: flex;
  width: 17px;
  justify-content: center;
  width: 7%;
}

.search-form .button-sec button {
  border: 0;
  padding: 0;
  background: none;

  width: 100%;
  background: #ffa900;
}

.header_quick {
  margin-left: -17px;
}

.language .ant-select-selector {
   font-size: var(--fontsize1);
  /* height: 25px !important; */
  padding: 0 4px !important;
}

.language>div {
  width: 90px;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 24px;

}

.language .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
  font-size: small;
  font-weight: 500;
  /* margin-top: -1px; */
  /* height: 23px; */
  line-height: 27px;
   font-size: var(--fontsize1);
}


.temple-fav-icon {
  padding: 0 !important;
  height: 0 !important;
  bottom: 2px;
}

.share-button {
  padding: 0 !important;
  height: 0 !important;
  bottom: 2px;
}


.radio-group {
  display: flex;
  justify-content: flex-start;
  /* Align to the left */
  margin-bottom: 20px;
}

.radio-columns {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px !important;
  gap: 10px !important;
}

/* .radio-columns .ant-radio {
  margin-bottom: 10px; 
} */




.search-lt-sec {
  width: 250px;
  border-right: 1px solid #dddddd;
}

.search-rt-sec {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.search-rt-sec1 {
  width: 100%;
  height: 100%;
}

.search-rt-sec .ant-tabs-content-holder {
  overflow: auto;
}

/* .search-rt-sec {
  width: 100%;
  height: calc( 100% - 120px );
  overflow: auto;
} */

.bc-link {
  font-family: var(--fontenglish);
}

.search-breadcrumb {
  justify-content: space-between;
  padding: 0px;
  border-bottom: 1px solid #bfbfbf;
  color: #686868;
  font-weight: 400;
   font-size: var(--fontsize2);
  display: flex;
  align-items: center;
}

.ant-tabs {
  height: 100%;
}

.select-god:hover {
  cursor: pointer;
  box-shadow: none;
}

.select-god:active {
  position: relative;
  top: -2px;
}

.select-god {
  padding: 0px 7px;
  background: #f9f9f9;
  border-radius: 6px;
  margin-right: 10px;
  /* margin-top: -7px; */
  position: relative;
  color: #f28400;
  font-weight: 500;
  /* height: 16px; */
  box-shadow: 1px 1px 2px #a5a5a5;
   font-size: var(--fontsize1);
  line-height: 18px;
  border: 1px solid #f28400;
}

.favicon-heart {
  color: #dbdbdb;
  text-shadow: 1px 1px 2px #9d277c !important;
}

:where(.css-dev-only-do-not-override-diro6f).ant-btn-link {
  color: #ffc700;
  text-shadow: 0px -1px 2px #504f4a;
}

:where(.css-dev-only-do-not-override-diro6f).ant-row {
  justify-content: center;
}

.search-link {
  color: #f28400;
}

.search-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* .search-link-main a:last-child {
    color: black !important;
    cursor: default !important;
    pointer-events: none;

} */

.search-link-main {
  display: flex;
  flex-direction: row;
}

.search-breadcrumb aside {
  display: flex;
  flex-direction: row;
  align-items: center;
   font-size: var(--fontsize2);
}

.search-breadcrumb aside span {
   font-size: var(--fontsize2);
}

.my-link {
  position: relative;
  display: inline-block;
}

.my-link:hover::after {
  content: "Add Temples";
  display: inline-block;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
   font-size: var(--fontsize2);
  line-height: 1;
}

.plus:hover::after {
  content: "Add Temple to Group";
  display: inline-block;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: 30px;
  left: 30%;
  transform: translateX(-50%);
   font-size: var(--fontsize2);
  line-height: 1;
}

.add-temple-group:hover::after {
  content: "Create Group";
  display: inline-block;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  top: 50px;
  left: 40%;
  transform: translateX(-50%);
   font-size: var(--fontsize2);
  line-height: 1;
}

.my-link1:hover::after {
  /* content: "Notifications"; */
  display: inline-block;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: 40px;
  left: 93%;
  transform: translateX(-50%);
   font-size: var(--fontsize2);
  line-height: 1;
}

.fa-user:hover::after {
  /* content: "Login"; */
  display: inline-block;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: 30px;
  left: 40%;
  transform: translateX(-50%);
  font-size: 10px;
  line-height: 1;
}

.my-link2:hover::after {
  /* content: "Favourites"; */
  display: inline-block;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: 40px;
  left: 96%;
  transform: translateX(-50%);
   font-size: var(--fontsize2);
  line-height: 1;
}

.my-link:hover::after {
  display: block;
}

.my-link {
  margin-left: 2px;
}

.my-link3 {
  position: absolute;
  width: 100%;
}

/* a {
  color: #212529 !important;
  text-decoration: none;
  background-color: transparent;
} */

:where(.css-dev-only-do-not-override-ed5zg0).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fb9c09 !important;
  border-color: #fb9c09 !important;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #fb9c09;
  border-color: white !important;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #faad14;
}

.my-link3:hover::after {
  content: "Search";
  display: inline-block;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
   font-size: var(--fontsize2);
  line-height: 1;
}

.add-temple-icon2 {
  position: absolute;
  color: #d5d5d5;
  cursor: pointer;

  left: 8px;
  top: 2px;
  line-height:24px;
}

.add-temple-icon2 i {
   font-size: var(--fontsize1);
}

.add-temple-icon {
  position: relative;
  color: #2e2e2e;
  cursor: pointer;
  margin-left: 12px;
  /* margin-top: 4px; */
  float: left;
}

.add-temple-icon .fa-plus {
  font-size: 10px;
  /* margin-top: -13px; */
  position: absolute;
  right: -7px;
}

:where(.css-dev-only-do-not-override-diro6f).ant-tabs-top>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-diro6f).ant-tabs-bottom>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-diro6f).ant-tabs-top>div>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-diro6f).ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0 0 0px 0;
}

.slider-txt {
  bottom: 170px;
  color: white;
}

.subtitle {
  margin-bottom: 0;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #6c757d;
  font-weight: bold;
}

/* @keyframes breath {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.bkg {

    animation: breath 20s linear infinite;
    transform: scale(1);
} */

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.heading {
  text-align: center;
}

p.subtitle.text-primary {
  color: var(--colorheadingv2) !important;
}

.stretched-link {
  color: #cba30d;
  font-weight: 600;
  display: block;
  font-size: var(--fontsize1);
}

.god-category-list>div {}

.all-gods-sec {
  text-align: left;
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid var(--colorheadingv2);
  margin-bottom: 9px;
   font-size: var(--fontsize1);
  color: var(--colororange2);
}

.god-category-list>div>div {
  display: flex;
  justify-content: left;
  align-items: center;
}

.god-category-list1>div>div {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  grid-gap: 5px;
  gap: 5px;
}

.card-poster {
  width: 180px;
}

.gradient-overlay {
  filter: grayscale(70%);
}

.gradient-overlay:hover {
  filter: grayscale(0%);
}

.gradient-overlay .overlay-content {
  position: absolute;
  z-index: 20;
  width: 100%;
  color: white;
  text-shadow: 1px 1px 3px #000;
  background-color: #0000002e;
}

.tile-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 30;
}

.hover-animate:hover,
.hover-animate:focus {
  transform: translate3d(0, -2px, 0);
}

.hover-animate {
  margin: 20px;
  overflow: hidden;
  border-radius: 20px !important;
}

.display-1 {}

.hover-animate {
  transition: all 0.2s ease-in-out;
}

.guides-slider {
  flex-flow: row wrap;
  justify-content: center;
}

.heading:after {
  content: " ";
  display: block;
  width: 100px;
  height: 2px;
  margin: 0px auto 15px;
  background: #ff7473;
}

.card-container {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.loop-wrapper {
  margin: 0 auto;
  position: relative;
  display: block;
  width: 100%;
  height: 250px;
  overflow: hidden;
  color: #fff;
}

.mountain {
  position: absolute;
  right: -900px;
  bottom: -20px;
  width: 2px;
  height: 2px;
  box-shadow: 0 0 0 50px #9d3d01, 60px 50px 0 70px #ab7517,
    90px 90px 0 50px #729f0b, 250px 250px 0 50px #7e4f0b,
    290px 320px 0 50px #f2e608, 320px 400px 0 50px #c3b008;
  transform: rotate(130deg);
  animation: mtn 20s linear infinite;
}

.hill {
  position: absolute;
  right: -900px;
  bottom: -50px;
  width: 400px;
  border-radius: 50%;
  height: 20px;
  box-shadow: 0 0 0 50px #4db6ac, -20px 0 0 20px #4db6ac, -90px 0 0 50px #4db6ac,
    250px 0 0 50px #4db6ac, 290px 0 0 50px #4db6ac, 620px 0 0 50px #4db6ac;
  animation: hill 4s 2s linear infinite;
}

.tree {
  position: absolute;
  height: 80px;
  width: 80px;
  bottom: 0;
  background: url(../../assets/images/templeimg.png) no-repeat;
  background-size: contain;
}

.tree2 {
  position: absolute;
  height: 80px;
  width: 80px;
  bottom: 0;
  background: url(../../assets/images/templeimg2.png) no-repeat;
  background-size: contain;
}

.tree3 {
  position: absolute;
  height: 80px;
  width: 80px;
  bottom: 0;
  background: url(../../assets/images/templeimg2.png) no-repeat;
  background-size: contain;
}


.rock {
  margin-top: -17%;
  height: 2%;
  width: 2%;
  bottom: -2px;
  border-radius: 20px;
  position: absolute;
  background: #ddd;
}

.truck,
.wheels {
  transition: all ease;
  width: 85px;
  margin-right: -60px;
  bottom: 0px;
  right: 50%;
  position: absolute;
  background: #eee;
}

.truck {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/truck.svg) no-repeat;
  background-size: contain;
  height: 60px;
}

.truck:before {
  content: " ";
  position: absolute;
  width: 25px;
  box-shadow: -30px 28px 0 1.5px #fff, -35px 18px 0 1.5px #fff;
}

.wheels {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/130015/wheels.svg) no-repeat;
  height: 15px;
  margin-bottom: 0;
}

.tree {
  animation: tree 10s 0s linear infinite;
}

.tree2 {
  animation: tree3 8s 0.05s linear infinite;
}

.tree3 {
  animation: tree2 15s 0.03s linear infinite;
}

.rock {
  animation: rock 4s -0.53s linear infinite;
}

.truck {
  animation: truck 4s 0.08s ease infinite;
}

.wheels {
  animation: truck 4s 0.001s ease infinite;
}

.truck:before {
  animation: wind 1.5s 0s ease infinite;
}

@keyframes tree {
  0% {
    transform: translate(1350px);
  }

  50% {}

  100% {
    transform: translate(-50px);
  }
}

@keyframes tree2 {
  0% {
    transform: translate(650px);
  }

  50% {}

  100% {
    transform: translate(-50px);
  }
}

@keyframes tree3 {
  0% {
    transform: translate(2750px);
  }

  50% {}

  100% {
    transform: translate(-50px);
  }
}


@keyframes rock {
  0% {
    right: -200px;
  }

  100% {
    right: 2000px;
  }
}

@keyframes truck {
  0% {}

  6% {
    transform: translateY(0px);
  }

  7% {
    transform: translateY(-6px);
  }

  9% {
    transform: translateY(0px);
  }

  10% {
    transform: translateY(-1px);
  }

  11% {
    transform: translateY(0px);
  }

  100% {}
}

@keyframes wind {
  0% {}

  50% {
    transform: translateY(3px);
  }

  100% {}
}

@keyframes mtn {
  100% {
    transform: translateX(-2000px) rotate(130deg);
  }
}

@keyframes hill {
  100% {
    transform: translateX(-2000px);
  }
}

.badgeno {
  font-size: 10px;
  background: #ffffff;
  padding: 0px 3px;
  font-weight: 600;
  border-radius: 50%;
  line-height: 16px;
  color: #3a3333;
  margin-top: -5px;
  float: left;
  margin-left: -5px;
  display: block;
  width: 16px;
  height: 16px;
  text-align: center;
}

.bg-mandala {
  background-image: url("../../assets/images/mandala_top.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 150px;
}

#hero .btn-get-started {
  font-family: var(--fontenglish);
  font-weight: 500;
   font-size: var(--fontsize2);
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 24px;
  color: #ffffff;
  font-weight: 700;
  background: var(--colororange3);
  box-shadow: 0 8px 28px rgba(22, 223, 126, 0.45);
  text-decoration: none;
  text-shadow: 1px 2px 1px #232323;
}

#hero .btn-get-started:hover {
  background: var(--colorRed2);
}


.banner-txt-content {
  width: 70%;
  float: left;
  ;
}

.hero-img {
  display: flex;
  margin-top: 70px;
  justify-content: end;
  width: 30%;
  float: left;
  align-items: center;
}

.d-flex.flex-column.justify-content-start {
  margin-top: 2%;
}

.banner22 {
  width: 100%;
  margin: auto;
  display: flex;
}

#hero h2 {
  color: #f5ccff;
  margin: 10px 0 30px 0;
   font-size: var(--fontsize3);
  font-weight: 300;
}

#hero .btn-watch-video {
   font-size: var(--fontsize2);
  display: inline-block;
  transition: 0.5s;
  margin-left: 25px;
  color: #5f687b;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#hero h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  color: var(--colorheadingYellow);
  font-family: var(--fontenglish);
  text-shadow: 2px 2px 6px #202020;
  margin-top: 20px;
  margin-bottom: 25px;
}

#hero .img-fluid {
  max-width: 100%;
  height: 260px !important;
  margin-top: -12%;
}

.calendar-block {
  width: 140px;
  margin: 0.5%;
}

.bg-details-sec1 {
  width: 44%;
  margin: 0.5%;
}

.bg-details-sec2 {
  width: 54%;
  margin: 0.5%;
  display: flex;
  background: #ffffeb;
  border-radius: 9px;
  flex-direction: row-reverse;
}


.bg-details-sec3 {
  flex: 1 1;
  margin: 1%;
}

.cal-1 {
  position: relative;
}

.bg-details {
  /* background: url(/static/media/bg.00db1823.jpg); */
  background-size: contain;
  background: #341333db;
  z-index: 1;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 3px 8px #00000042;
  background: #efefef;
  margin: 10px 0px 0 0px;
  border: 1px solid #ddd;
}

.search_banner {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: 50px 0 30px 0;
}

.search-temples-list .ant-tabs-tab {
  padding: 5px;
}

.search-rt-sec .ant-tabs-nav {
  margin-bottom: 5px;
}

.ant-tabs-tab-btn {
   font-size: var(--fontsize2);
}

.search_banner_title {
  /* font-family: 'Ephesis', cursive; */
  font-size: 24px;
  line-height: 48px;
  text-transform: uppercase;
}

.bycity {
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
   font-size: var(--fontsize2);
}

.bycitycheckbox input {
  width: 20px;
  height: 17px;
}

.card-design {
  height: 100%;
  width: 25%;
  border-radius: 0 0 10px 10px;
  opacity: 0.5;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.durga-pic {
  background-image: url("../../assets/images/durga-god.png");
}

.linga-pic {
  background-image: url("../../assets/images/linga.jpg");
}

.shiva1 {
  background-image: url("../../assets/images/shiva1.jpg");
}

.shiva2 {
  background-image: url("../../assets/images/shiva2.jpg");
}

.shiva3 {
  background-image: url("../../assets/images/shiva3.jpg");
}

.shiva4 {
  background-image: url("../../assets/images/shiva4.jpg");
}

.vishnu {
  background-image: url("../../assets/images/vishnu.jpg");
}

.narashimha {
  background-image: url("../../assets/images/narashimha.jpg");
}

.murugan {
  background-image: url("../../assets/images/murugan.jpg");
}

.cd1 {
  background-image: url("../../assets/images/cd1.jpg");
}

.card-design-text {
  width: 100%;
  padding: 10px 15px;
  padding-bottom: 10px;
  margin-bottom: 0;
  color: white;
   font-size: var(--fontsize2);
  height: 50px;
  line-height: 28px;
}

.card-design-text2 {
  position: absolute;
  left: 0;
  font-size: 100px;
  line-height: 120px;
  color: #ffffff;
  bottom: 0;
  /* z-index: inherit; */
}

.card-bg {
  position: relative;
  display: flex;
  flex-direction: column;

  background: #282e48;
  background-image: linear-gradient(270deg, #cc1353, transparent);
  border-radius: 10px;
  margin-right: 15px;
  width: 100% !important;
  margin: 0 10px;
}

.card-design-text3 {
  display: block;
   font-size: var(--fontsize2);
  line-height: 14px;
  margin-left: 6px;
  margin-bottom: 10px;
}

.card-bg:hover .onhover-text {
  opacity: 0.9;
}

/* .search_button {
  margin-top: -4%;
} */

.onhover-text {
  height: 99%;
  width: 98%;
  border-radius: 0 0 10px 0;

  background: white;
  padding: 4px;
  transition: opacity 1s;
  margin: 2px;
  line-height: 16px;
  transition: opacity 1s;
  cursor: pointer;
}

.donation {
  font-family: var(--fontenglish);
}

.donation-text {
  color: #c15506;
  font-weight: 500;
}

input[type="date"] {
  color: black;
}

.onhover-text span {
  color: #ffffff;
  font-weight: bold;
  position: absolute;
  top: 0px;
  display: block;
   font-size: var(--fontsize1);
  background: #391132;
  border-radius: 7px;
  padding: 3px 10px;
  line-height: 19px;
  right: 10px;
  /* margin-bottom: 10px; */
  top: 11px;
  border: 1px solid #ffffff;
}

.shrines-block .nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
  background: #f8f0f7;
  color: #4d2448;
   font-size: var(--fontsize2);
}

.shrines-block .nav-pills>li.active>a,
.shrines-block .nav-pills>li.active>a:focus,
.shrines-block .nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #e3376e;
}

.onhover-text aside {
  margin-top: 15px;
  overflow-y: auto;
  height: 276px;
}

@keyframes color {
  0% {
    background: #282e48;
  }

  40% {
    background: #5e72c8;
  }

  100% {
    background: #282e48;
  }
}

.search_banner_title2 {
  color: white;
  justify-content: space-around;
   font-size: var(--fontsize2);
  line-height: 22px;
}

.search_banner_title2 span {}

/* ---------------------popver pageheader------------------ */

.custom-popover {
  width: 400px;
  max-height: 400px;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); */
  border-radius: 4px;
  overflow: hidden;
  /* Hide the default scrollbar */
}



.custom-popover .popover-content {
  padding: 0;
}

.custom-popover .notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e4e0de;
}

/* .custom-popover .profile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e4e0de;
    border-radius: 5px;
    
  }

  .popover-content Button, .popover-content p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e4e0de;
    border-radius: 5px;
    margin-top: 10px;
  } */

.custom-popover .notification-header a {
  color: #333333;
  text-decoration: none;
   font-size: var(--fontsize2);
  transition: color 0.3s;
}

.custom-popover .notification-header h4 {
   font-size: var(--fontsize2);
  font-weight: bold;
  margin: 0;
}

.custom-popover .popover-content {
  max-height: 260px;
  overflow: auto;
}

.notification-close {
  position: absolute;
  top: 3px;
  right: 10px;
  color: grey;
}

.notification-close:hover {
  color: red;
  cursor: pointer;
}

.user-txt {
  background: #391132;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px; /* Center initials */
  font-size: var(--fontsize1);
  border-radius: 50%;
  margin-right: 8px;
  font-weight: bold;
  color: #ffffff;
  box-shadow: 1px 0px 0px #5c5c5c;
  font-family: "FontAwesome";
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.user-txt img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.image-txt {
  font-size: var(--fontsize1);
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
}


.book-main {
  height: 200px;
  width: 200px;
  background-image: url(../../assets/images/bookbg.jpg);
  background-repeat: repeat-x;
  background-size: cover;
}

.custom-popover .notification-button {
  margin-right: 25px;
  background-color: transparent;
  color: #606060;
  border: none;
  padding: 0;
   font-size: var(--fontsize2);
  cursor: pointer;
  transition: color 0.3s;
}

.custom-popover .notification-button:hover {
  color: #ff0000;
}

.custom-popover .notification-list-container {
  max-height: 200px;
  /* Set a maximum height for the list container */
  overflow-y: auto;
  /* Enable vertical scrolling for the list container */
}

.custom-popover .notification-list {
  padding: 8px 16px;
  border-bottom: 1px solid #e0d7d7;
  transition: background-color 0.3s;
  position: relative;
  margin-bottom: 10px;
  margin-right: 10px;
}

/* .custom-popover .notification-list:last-child {
  border-bottom: none;
} */

.custom-popover .notification-list:hover {
  background-color: #e5e2e7;
}

.custom-popover .notification-list h6 {
   font-size: var(--fontsize2);
  font-weight: bold;
  margin-bottom: 4px;
}

.custom-popover .notification-list p {
   font-size: var(--fontsize1);
  margin-top: 4px;
}

.custom-popover .notification-list .anticon-delete {
   font-size: var(--fontsize2);
  color: #999;
  cursor: pointer;
  transition: color 0.3s;
  position: absolute;
  top: 1px;
  right: 1px;
}

.custom-popover .notification-list .anticon-delete:hover {
  color: #ff0000;
}

.custom-popover .empty-notification {
  color: #999;
  text-align: center;
   font-size: var(--fontsize2);
  padding: 16px;
}

.custom-popover .empty-notification .anticon {
  margin-bottom: 8px;
  font-size: 24px;
  color: #ccc;
}

.custom-popover .empty-notification p {
  margin: 0;
}

/* -----------------search bar---------------------- */
.search-form1 input {
  border: 0;
   font-size: var(--fontsize1);
   font-family: var(--fontenglish);
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 1px 8px 1px 26px;
  border-radius: 3px 0 0 3px;
  transition: 0.3s;
  width: 100%;
  border-radius: 6px;
  height: 24px;

}

.search-form1 .button-sec button {
  background: transparent;
  border: 0;
}

.search-form1 .button-sec button i {
   font-size: var(--fontsize2);
  padding: 5px;
  color: #b1a8a8;
  margin-left: 0px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-btn-link {
  color: white;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-btn-link:not(:disabled):hover {
  color: grey;
}

.badges-gods {
  margin-top: 10px;
  padding: 0px 3px;
  border-radius: 6px;
  margin-right: 4px;
}

.badges-gods span {
  font-size: 14px !important;
  line-height: 18px;
  display: block;
}


.pr-2 {
  position: relative;
}

.custom-popover1 {
  position: absolute;
  right: 0;
  width: 300px;

  border-radius: 4px;
}

.popover-content {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto; */
  /* text-align: center; */
}

.google-profile-view {
  display: flex;
  align-items: center;
}

.user-pic1 {
  display: flex;

  flex-direction: column;
}

.user-pic1 h5 {
  text-transform: uppercase;
  font-weight: 700;
  color: #4f4f4f;
  max-width: 220px;
  overflow-wrap: break-word;
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-pic1 img {
  width: 110px;
  height: 110px;
  border-radius: 10%;
  object-fit: cover;
}

.user-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.user-details-text {
  flex-grow: 1;
  margin-right: 10px;
}

.user-details-text1 {
  font-weight: bold;
}

.user-details-text2 {
  color: #666;
   font-size: var(--fontsize1);
}

.profile-header {
   font-size: var(--fontsize2);
  font-weight: bold;
  margin-bottom: 10px;
  color: #391132;
  text-transform: uppercase;
  font-weight: 500;
}

.profile-header::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 5px;
}

.popover-content .ant-btn {
  border: none;
  outline: 0;
  display: inline-block;
  /* padding: 8px; */
  color: white;
  background-color: #f28400;
  text-align: center;
  cursor: pointer;
  width: 100%;
   font-size: var(--fontsize3);
}

input.ant-input.css-dev-only-do-not-override-ed5zg0 {
  height: 27px;
  /* top: -2px; */
}

/* :where(.css-dev-only-do-not-override-ed5zg0).ant-result {
  padding: 73px 32px;
} */

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Adjust based on your container height */
  width: 100%;
}



.custom-toast-success {
  background-color: rgb(245, 245, 245) !important;

  position: relative;
}

.custom-toast-success .Toastify__toast-icon svg {
  fill: #ff7732 !important;
  /* Change the tick color */
}

.custom-toast-success .Toastify__progress-bar {
  background: #ff7732 !important;
  /* Change the progress bar color */
}


form.search-form {
  display: flex;

  grid-gap: 5px;

  gap: 5px;
}

.popover-content .ant-btn:hover {
  opacity: 0.7;
  color: black !important;
}

.popover-content .ant-btn:last-child {
  margin-top: 5px;
}

.popover-content a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

/* .popover-content .ant-btn:hover {
  background-color: #f5f5f5;
} */


.d-flex.align-items-center.back-arrow {
  margin-top: 10px;
}



.grp-descr-img {

  margin: auto;
  display: block;
  border: 8px solid #ccc8c8;
  margin-top: 0px;
  margin-bottom: 10px;
  width:70%;
}


.ignore-styles {
  color: inherit !important;
  text-decoration: none !important;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 28px;
  /* Any other styles you want to remove */
}

a.stretched-link {
  text-decoration: none;
}


a.stretched-link:hover {
  color: #6a5916;
  text-decoration: none;
}

.para p {
  /* text-align: center; */
  text-transform: uppercase;
  font-weight: 400;
  /* color: #f28400; */
  /* max-width: 220px; */
  overflow-wrap: break-word;
  /* margin-left: -30px; */
  margin-top: 10px;
}

.para span {
  /* text-align: center; */
  text-transform: capitalize;
  /* font-weight: 700; */
   font-size: var(--fontsize2);
  /* color: #f28400; */
  max-width: 220px;
  overflow-wrap: break-word;
  /* margin-left: -30px; */
  /* margin-top: 10px; */
}

.ignore-style {
  color: inherit !important;
  text-decoration: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 5px;
  font-family: var(--fontenglish);
}

.ignore {
  border: 0;
  padding: 0;
  background: none;

  width: 100%;
  background: #ffa900;
}

.ignore:hover {
  color: white;
}

.upload-profile label {
  color: #007bff;
  cursor: pointer;
  display: flex;
  justify-content: end;
  /* margin-right: 7%; */
}

.upload-profile label:hover {
  color: rgb(97, 97, 197);
  cursor: pointer;
  -webkit-text-decoration: 1px rgb(87, 87, 124) dotted;
          text-decoration: 1px rgb(87, 87, 124) dotted;
}

.profile-remove:hover {
  color: #080808 !important;
}

.ant-modal-content {
  /* background-image: url(../../assets/images/log.png); */
  padding: 0 !important;
}

/* Modal Styles */
.modal {
  display: flex;
  /* Use flex to center the content */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgba(0, 0, 0, 0.6);
  /* Dark background with opacity */
  overflow: auto;
  /* Enable scroll if needed */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  /* Increased width for larger modal */
  max-width: 600px;
  /* Increased max width for larger modal */
  max-height: 80%;
  /* Increased max height */
  overflow: hidden;
  /* Hide overflow */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  /* Rounded corners */
  text-align: center;
  /* Center content */
  position: relative;
  /* Position relative for close button */
}

/* Close Button */
.close {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
}

/* Crop Image Styling */
.modal-content img {
  width: 100%;
  max-height: 200px;
  /* Ensure image doesn't exceed modal size */
  object-fit: contain;
  /* Contain image within its box */
  margin-bottom: 10px;
}

/* Additional styling to ensure button visibility */
.modal-content button {
  margin-top: 20px;
  /* Add margin to separate button from cropper */
}


.logo_login {
  background-image: url(../../assets/images/img.png);
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-position: center;
  margin-bottom: 10px;
}

.tiles-mains {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.tiles-mains>* {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 4);
  /* Display 4 items */
  box-sizing: border-box;
}

.tiles-mains::-webkit-scrollbar {
  height: 8px;
}

.tiles-mains::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.tiles-mains::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.tile-quicklinks {
  display: flex;
  justify-content: center;
  margin-left: 58%;
  color: #c15506;
}

.tile-quicklinks {
  position: relative;
}

.additional-icons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.additional-icons.visible {
  opacity: 1;
  transform: scale(1);
}

i {
  cursor: pointer;
}



.donation-but {
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.login-txt {
  font-family: var(--fontenglish);
  /* text-transform: uppercase; */
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
   font-size: var(--fontsize3);
  color: #fd6c02 !important;
  margin: 10px 0;
  text-transform: uppercase;
}

.reg-btn-login {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  color: grey;
   font-size: 12px;
  font-family: var(--fontenglish);
}

.ant-checkbox-wrapper span {
   font-size: var(--fontsize1);
  text-transform: capitalize;
  font-weight: 300;
}

.ant-modal-close {
  background-color: transparent;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: var(--colororange2) !important;
}

.btn-outline-secondary {
  font-size: 14px !important;
  border-color: #c7c7c7 !important;
  border-radius: 6px !important;
}

.ant-select-item-option-content {
   font-size: var(--fontsize1);
   font-family: var(--fontenglish);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
}

.btn-outline-dark2 {
  color: #343a40;
  border-color: #343a40;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
}

.shadow-card {
  box-shadow: 4px 4px 9px #ddd;
  border: 1px solid #ddd !important;
}

.select-state .ant-select {
  width: 200px !important;
}



.near-map {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px !important;
  justify-content: center;
}

/* :where(.css-dev-only-do-not-override-ed5zg0).ant-image .ant-image-mask {
  display: none;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-image-preview-root
  .ant-image-preview-img {
  display: none;
} */

ul.ant-image-preview-operations {
  display: none;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-image {
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: end;
}

.initials-placeholder {
  color: #f5f0f0;
  background-color: #e20048 !important;
  background-image: linear-gradient(45deg, #ff6100, #d62b86);
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 19px;
   font-size: var(--fontsize1);
  border-radius: 50%;
  padding: 3px;
  margin-right: 8px;
  margin-left: 8px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 1px 1px 1px #9d9999;
  /* box-shadow: 1px 0px 0px #5c5c5c; */
  font-family: "FontAwesome";
  cursor: pointer;
}

/* .initials-placeholder:hover{
  color: #f5f0f0;
background-color: #e20048 !important;
background-image: linear-gradient(45deg, #ff6100, #d62b86);
} */

.initials-placeholder h5 {
  color: rgb(233, 228, 228);
  top: 10%;
  margin-top: 25px;
}

/* .initials-placeholder h5:hover{
  color: rgb(238, 231, 231);
  top: 10%;
  margin-top: 25px;
} */

.initials-placeholder h5 .firstname {
  margin-left: 10px;
}

.initials-placeholder1 {
  background-color: var(--colororange3);
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 19px;
   font-size: var(--fontsize1);
  border-radius: 50%;
  padding: 3px;

  font-weight: bold;
  color: #ffffff;
  font-family: "FontAwesome";
  cursor: pointer;
}

.initials-placeholder1 h5 {
  color: rgb(253, 247, 247);
  top: 10%;
  margin-top: 25px;
}

.edits {
  margin-top: 2px;
}

.ALL Button {
  top: 42px !important;
  margin-left: 50px;
}

.ALLS Button {
  top: 42px !important;
  margin-left: 170px;
}

.but {
  display: flex;
  justify-content: end;
}

/* Styles for modal */
.temple-details-map {
  width: 80%;
  /* Adjust the width as needed */
}

/* Styles for map container */
.map-details {
  width: 100%;
  /* Adjust the width as needed */
  height: 60vh;
  /* Set a default height */
}


.add {
  margin-right: 5px;
}

.ant-row.css-dev-only-do-not-override-ed5zg0 {
  /* justify-content: space-between !important; */
  justify-content: left !important;
}

.god_select:hover {
  cursor: pointer;
}

.loc-button {
  display: flex;
  justify-content: center !important;
  margin-left: 15%;
  margin-top: 0;
}

.zoom-in {
  transition: transform 0.3s ease-in-out;
}

.zoom-in:hover {
  transform: scale(1.1);
  /* You can adjust the scaling factor as needed */
}


:where(.css-dev-only-do-not-override-ed5zg0).ant-form-item .ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  /* overflow: hidden; */
  white-space: nowrap;
  text-align: start;
  vertical-align: middle;
}

/* textarea.ant-input.css-dev-only-do-not-override-ed5zg0 {
  height: 100px;
} */

/* General Calendar Styling */
.react-calendar {
  width: 400px;
  max-width: 100%;
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  font-family: var(--fontenglish);
  box-shadow: 2px 5px 9px #00000045;
}

.react-calendar__navigation button {
  color: #fff;
  min-width: 44px;
  background: #e67e22;
   font-size: var(--fontsize2);
  font-weight: bold;
  margin: 5px;
  border: none;
  border-radius: 5px;
  padding: 8px;
}

.react-calendar__navigation button:hover {
  background: #005bb5;
  cursor: pointer;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: bold;
  color: #333;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 10px 0;
   font-size: var(--fontsize2);
  color: #333;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 15px;
  background: #fff;
  border-radius: 5px;
  border: none;
   font-size: var(--fontsize2);
  color: #333;
}

.react-calendar__tile--now {
  background: #ffd700;
  color: #000;
  border-radius: 5px;
}

.react-calendar__tile--active {
  background: #0073e6;
  color: #fff;
  border-radius: 5px;
}

.react-calendar__tile--active:hover {
  background: #005bb5;
}

.react-calendar__tile:hover {
  background-color: #e6f7ff;
  cursor: pointer;
  color: #0073e6;
}

.react-calendar__tile--disabled {
  color: #ccc;
}

.react-calendar__tile--weekend {
  color: #d9534f;
}

.react-calendar__tile--weekend:hover {
  background-color: #fdecea;
}



.search-back {
  padding: 0 15px;
  margin-right: 10px;
  color: white;
}

.search-back:hover {
  color: #fff;
}

.fa-share-nodes,
.fa-location-dot {
  cursor: pointer;
}

.breadcrum {
  position: relative;
  width: 100%;
  background: #341333;
  height: 35px;
  display: none;
}

.bc-link {
  font-family: var(--fontenglish);
}

.bc-active-link {
  color: var(--colorheading1);
  text-decoration: underline;
  font-family: var(--fontenglish);

}

.bc-active-link:hover {
  color: var(--colorheading1);
  text-decoration: underline;
  cursor: pointer;
}

.breadcrum-block {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 0;
  height: 100%;
  align-items: center;
}

.dashboard_modal {
  width: 100%;
}

/* .events-block::after {
  content: "";
  position: absolute;
  right: -27px;
  width: 0px;
  height: 50px;
  border-left: 0px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 116px solid #9b9b9b;

} */
.banner2 {
  box-shadow: none;
  background: #e8daff;
  position: relative;
  z-index: 1;

  /* background: url(../../assets/images/bg.jpg); */
  background-size: contain;
}

.banner2>div {
  background-color: #ffffffc9;
  padding-top: 10px;
  border: 1px solid #c5c5c5;
}

.banner2-text1 {
  background: #651e8969;
  color: #8b0b45;
  font-weight: bold;
  padding: 0 10px;
}

.cal-1 {
  display: flex;
  color: #6c6c6c;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
}

.f-c-2 {
  color: #529718;
  font-weight: bold;
}

.f-c-3 {
  color: var(--colorRed2);

}

/* .calender-block{
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 7px 1px 10px #a39b9b;
  } */

.calender-block div {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
}

.cal-top {
  background: var(--colororange3);
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 10px;
  /* border-radius: 10px 10px 0 0; */
  color: white;
  font-weight: bold;
  padding: 0 5px;
}

.cal-bottom {
  text-align: center;
  color: #b3b3b3;
  font-weight: bold;
  font-size: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 40px;
  line-height: 40px;
}

.tiles-design {
  position: relative;
}

.tiles-design-main>a {
  display: flex;
  flex-direction: column;
}

/* button.btn1{
    background-color: #efefef;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    line-height: 16px;
    font-size: 14px !important;
    padding: 4px 10px;
    height: auto;
}
button.btn1 span{
   font-size: var(--fontsize1);
  font-weight: bold;
} */

.search_input_library input {
  padding: 0px;
}

button.btn1 {
   font-size: var(--fontsize1);
  padding: 2px 15px 2px 15px !important;
  /* margin-top:10px; */
  transition: all 1s ease 0s;
  text-transform: capitalize;
  font-weight: 500;
}

.background-blur {
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
}


button.btn1:hover {

  /* color: #494949 !important; 
  border-radius: 50px; */
  border-color: #494949 !important;
  box-shadow: 1px 1px 1px #b9b9b9;

}

button.btn1 i {
  display: none;
}

.aside-block {
  height: 200px;
  /* background: rebeccapurple; */
  position: relative;
  z-index: 1;

  /* margin-top: 2%; */
  width: 80%;
  /* margin-bottom: 5%; */
  margin: 20px auto;
  background: url(../../assets/images/hindu.jpg);
  background-size: cover;
  border-radius: 10px;
  box-shadow: 10px 10px 10px #0000006b;
  border-style: ridge;
  /* border-color: #e1b12c; */
  border: 2px ridge gold;
  /* background-image: linear-gradient(45deg, black, #dcb035); */
}

.ant-modal-content .welcomebg {
  background-image: linear-gradient(45deg, #ff8719, #f95959);
}


.welcomebg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3a3a3a80;
  background-image: linear-gradient(45deg, #e36e0775, #4a004866);
  z-index: 1000;
}

.welcome-image {
  background: url(../../assets/images/namaste.png);
  background-size: contain;
  height: 300px;
  width: 300px;
  background-repeat: no-repeat;
  margin-right: 30px;
}

.full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
}

.year-txt {
  background: #341333;
  border-radius: 10px;
  width: 100%;
}

.events-block {
  /* position: relative;
  width: 400px;
  background: white;
  border: 1px solid #ddd; */
}

.events-block-links {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.events-block-links-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  color: var(--colorRed2);
  font-weight: 600;
}

a.ant-breadcrumb-link {
  color: white;
}

a.ant-breadcrumb-link:hover {
  color: rgb(218, 212, 212);
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-breadcrumb .ant-breadcrumb-separator {
  margin-inline: 8px;
  color: rgb(255 255 255);
  /* margin-top: 7px; */
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-select-single {
   font-size: var(--fontsize2);
  width: 100px;

}

:where(.css-dev-only-do-not-override-ed5zg0).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 24px;
  /* padding: 0 20px; */
  font-size: large;
}

.contribute {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  flex-direction: column;
}

.ant-popconfirm-buttons button:hover {
  background-color: #e67e22 !important;
}

.ctgry.container {
  margin-top: 10px;
}

.side.container {
  display: flex;
}

.ant-card-cover {
  height: 100px;
}

.ham .ant-btn-primary.settings-details {
  background-color: transparent;
}

/* .detail-but {
  font-size: 12px !important;
  color: #b22222;
  background: #ffc82d;
  padding: 2px 15px;
  font-weight:500;
  line-height: 10px;
  border: 1px solid #b12222;
  border-radius: 8px;
  line-height: 15px;
  transition: box-shadow 1s;
  white-space: pre;
} */

.detail-but {
  color: #b22222;
  background: #ffc82d;
  padding: 0px 3px;
  font-weight: 500;
  /* line-height: 10px; */
  border: 1px solid #ec9494;
  border-radius: 8px;
  /* height: 23px; */
  transition: box-shadow 1s;
  white-space: pre;
  display: inline-flex;
  align-items: center;
  margin-bottom: 3px;
  margin-top: 8px;
  height: 18px;
  
}

.detail-but-god {
  font-size: 12px !important;
  color: var(--colorheadingv2);
  /* background: white; */
  padding: 0px 0px;
  line-height: 10px;
  /* border: 1px solid var(--colorheadingv2); */
  border-radius: 8px;
  line-height: 17px;
  height: 18px;
  transition: box-shadow 1s;
  white-space: pre;
  font-weight: 700;
}

.detail-but:hover {
  box-shadow: 2px 3px 3px #cdcdcd;
  cursor: pointer;
  text-decoration: none;
  color: #b22222;
}

.cat-detail {
  margin: 0px 5px 0 0px;
  padding: 10px;
  /* border: 1px solid #cbcbcb; */
  height: 100%;
  overflow: hidden;
  background: #ededed;
  border-radius: 5px;
}


.cat-detail span {
  color: var(--colorheading1);
  align-items: center;
}

.tile-add {
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  margin: 0;
   font-size: var(--fontsize2);
  text-transform: capitalize;
  text-align: left;
}

.cat-txt {
  padding: 0 0px;
  /* height: 50px; */
  margin-bottom: 8px;
}

.day-up {
  background-color: var(--colororange3) !important;
  border-radius: 10px 10px 0 0;
  height: 20px !important;
  text-align: center;
  /* background-image: linear-gradient(45deg, black, transparent); */
  line-height: 15px;
  box-shadow: inset 0px 7px 6px #a38a8a;
}

.day-down {
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  height: 20px !important;
  text-align: center;
  /* background-image: linear-gradient(180deg, white, transparent); */
  line-height: 15px;
  box-shadow: inset 0px -3px 3px #a38a8a;
  color: #eb405e;
}

.card-img-temple-cat {
  width: 198px;
  /* box-shadow: 3px 3px 15px #ddd; */
  height: 140px;
  background-size: cover;
  background-position: center center;
  padding: 3px;
  padding-bottom: 0;
}

.tile-text-temple-cat span {
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
   font-size: var(--fontsize1);
  font-weight: 500;
  /* text-shadow: 1px 1px 0 #000; */
}

.tile-text-temple-cat span:nth-child(2) {
  display: none;
}

.tile-text-temple-cat span:nth-child(1) {

  /* text-shadow: 0px 5px 8px #f1481e8c; */
  color: var(--colorheading1);
  text-transform: uppercase;
  display: block;
  /* margin-left: -1px; */
  /* width: 105%; */
  white-space: pre;
  justify-content: left;
  text-overflow: ellipsis;
  padding: 0px 5px;
  overflow: hidden;
  /* box-shadow: 2px 1px 7px #00000069; */
  /* background-image: linear-gradient(93deg, #6b096240, #f15e087d); */
  /* border-style: dotted; */
  border-width: thin;
  /* border-radius: 3px; */
  /* position: absolute; */
  text-align: center;
  top: 0px;

  background-color: #ebebeb;
  padding: 10px 2px;
  font-weight: 500;
}

.cal-1 {
  /* Add your existing cal-1 styles here */
}

.scrollable-content-vertical {
  height: 110px;
  /* Adjust this value based on your design */
  overflow: hidden;
  /* border: 1px solid #ccc;  */
}

#scroll-text a:hover {
  color: var(--colororange3);
}

.f1.events-block-links {
  display: block;
  font-weight: 500;
  text-align: left;
   font-size: var(--fontsize2);
  border-bottom: 1px solid #d9d9d9;
  color: #505050;
  margin-bottom: 10px;
}

.f1.events-block-link {
  display: block;
  font-weight: 500;
  text-align: left;
   font-size: var(--fontsize2);
  /* border-bottom: 1px solid #8b8b8b; */
}

.scrollable-inner {
  display: block;
  flex-direction: column;
  animation: scroll 15s linear infinite;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* Add this to your CSS file */

@keyframes foldOut {
  0% {
    transform: perspective(800px) rotateX(0deg);
  }

  100% {
    transform: perspective(800px) rotateX(-180deg);
    opacity: 0;
  }
}

@keyframes foldIn {
  0% {
    transform: perspective(800px) rotateX(180deg);
    opacity: 0;
  }

  100% {
    transform: perspective(800px) rotateX(0deg);
  }
}

.fold-out {
  animation: foldOut 0.6s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards;
  opacity: 0;
  transform-origin: bottom center;
}

.fold-in {
  animation: foldIn 0.6s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards;
  opacity: 1;
  transform-origin: top center;
}

.up-arrow,
.down-arrow {
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  color: #000;
  justify-content: center;
  margin: 5px auto;
  /* Use 'auto' margin for centering */
  transform: translateY(0);
  /* Reset the transform */
  transition: transform 0.3s ease-in-out;
}

.form-item-row1 {
  display: flex;
  margin-left: 33%;
}

#scroll-container {
  border: 1px solid #fff;
  border-radius: 5px;
  height: 116px;
  overflow: hidden;
  color: black;
  padding: 0 7px;
  background: white;
  box-shadow: inset 0px 0px 11px #cbcbcb;
}

/* #scroll-text a{
  display: block;
  float:left;
} */

#scroll-text {
  height: auto;

  /* animation properties */
  transform: translateY(100%);
  animation: my-animation 15s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

#scroll-text>span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.ant-dropdown-menu-item>span {
  text-align: left;
  position: relative;
  top:3px;
}

.downarrow {
  margin-left: 3px;
  font-size: 10px;
}

.ant-dropdown-menu {
  border-radius: 4px !important;
}

.nav-icon-main:hover {
  background-color: #e9121294;

}

.ant-dropdown-menu-item-active {

  color: #ffffff !important;
  /* font-weight: bold !important; */
  
  background: #c93937 !important;
  ;
}

/* .library-nav .ignore-style::after{
  content: '\27A4';
  height: 14px;
  width: 9px;
  display: block;
  align-items: center;
  line-height: 13px;
  margin-left: 6px;
  color: white;
} */

/* for Firefox */

/* for Chrome */

@keyframes my-animation {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(-100%);
  }
}

.day-up {
  background-color: #eb405e;
  border-radius: 10px 10px 0 0;
  height: 15px;
  text-align: center;
  /* background-image: linear-gradient(45deg, black, transparent); */
  line-height: 15px;
  box-shadow: inset 0px 7px 6px #a38a8a;
}

.serv:hover {
  text-decoration: none;
}

.day-down {
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
  height: 15px;
  text-align: center;
  /* background-image: linear-gradient(180deg, white, transparent); */
  line-height: 15px;
  box-shadow: inset 0px -3px 3px #a38a8a;
  color: #eb405e;
}

.cal-1 {
  /* Add your existing cal-1 styles here */
}

.scrollable-content-vertical {
  height: 130px;
  /* Adjust this value based on your design */
  overflow: hidden;
  /* border: 1px solid #ccc;  */
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

/* Add this to your CSS file */

@keyframes foldOut {
  0% {
    transform: perspective(800px) rotateX(0deg);
  }

  100% {
    transform: perspective(800px) rotateX(-180deg);
    opacity: 0;
  }
}

@keyframes foldIn {
  0% {
    transform: perspective(800px) rotateX(180deg);
    opacity: 0;
  }

  100% {
    transform: perspective(800px) rotateX(0deg);
  }
}

.fold-out {
  animation: foldOut 0.6s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards;
  opacity: 0;
  transform-origin: bottom center;
}

.fold-in {
  animation: foldIn 0.6s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards;
  opacity: 1;
  transform-origin: top center;
}

.up-arrow,
.down-arrow {
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  color: #000;
  justify-content: center;
  margin: 5px auto;
  /* Use 'auto' margin for centering */
  transform: translateY(0);
  /* Reset the transform */
  transition: transform 0.3s ease-in-out;
}

.d-flex.p-2.w-100.font-condenced.container.justify-content-between.bg-details {
  margin-bottom: 20px;
}

.details-style {
  color: #911755 !important;
  font-size: medium;
}

.tile {
  border: 1px solid #cbcbcb;
  width: 38%;
  margin: 10px;
  transition: box-shadow 1s;
  padding: 8px 8px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-card .ant-card-body {
  padding: 10px;
  border-radius: 0 0 8px 8px;
}

.all-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
}

/* span.ant-btn-icon {
  margin-top: 10px;
} */

button.ant-btn.css-17seli4.ant-btn-link.ant-btn-icon-only {
  color: white;
}

span.ant-input-affix-wrapper.css-17seli4.ant-input-outlined.resource-search {
  height: 33px;
  border-color: #938282;
  /* margin-left: -24px; */
}

.ant-space.css-17seli4.ant-space-horizontal.ant-space-align-center.ant-space-gap-row-small.ant-space-gap-col-small {
  margin-left: 10px;
}

/* .container.d-flex.mt-2.mb-2.custom-scrollbar {
  margin-left: 394px;
  margin-top: 16px !important;
}

.container.d-flex.mt-2.mb-2 {
  margin-left: 390px;
}

span.anticon.anticon-down.ant-select-suffix {
  margin-bottom: 7px;
} */

.ant-select.ant-select-outlined.select.css-17seli4.ant-select-single.ant-select-show-arrow.ant-select-open {
  display: flex;
  align-items: center;
}

:where(.css-17seli4).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: '';
}

.search-form input:focus,
.search-form input:hover {
  outline: none;
  box-shadow: none;
  border: none;
}

:where(.css-17seli4).ant-input-outlined:hover {
  border-color: #212529;
  background-color: #ffffff;
}

:where(.css-17seli4).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #212529;
  border-color: #faad14;
  background: #faad14;
}

:where(.css-17seli4).ant-select-single {
   font-size: var(--fontsize2);
  /* height: 31px; */
}

.ant-select-single {
  height: 27px;
}

.ant-form-item .ant-form-item-label>label {
  float: left;
}

.button-row {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.date-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 27px;
  font-weight: 500;
   font-size: var(--fontsize1);
   /* padding-top:8px; */
}


/* :where(.css-17seli4).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background-color: #fb9c09 !important;
  box-shadow: 3px 3px 3px #7b7575;
} */

/* span.ant-btn-icon {
  margin-top: 10px;
} */


:where(.css-dev-only-do-not-override-ed5zg0).ant-typography {
  color: rgb(90 63 119);
  word-break: break-word;
  line-height: 1.5714285714285714;
}

input {
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  transition: .2s;
}

:where(.css-17seli4).ant-form-item .ant-form-item-label {
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  /* text-align: end; */
  vertical-align: middle;
}

/* i.fa-solid.fa-share-nodes {
  color: #c15556;
} */

.detai {
  margin-bottom: 5px;
  margin-left: 70%;
  background-color: #891491;
  color: white;
}

.tiles-designs {
  width: 100%;
  padding: 0px 0px;
  display: block !important;
  float: left;
  margin-top: 10px;
  transition: box-shadow 1s;
}
.tiles-design-divider{
border-top: 1px dashed #972222;
    margin-top: 20px;
    padding-top: 20px;
}



.tiles-designs h4 {
  margin-bottom: 0;
   font-size: var(--fontsize1);
  font-weight: 500;
  width: 140px;
  /* margin-left: 20px; */
  color: #972222;
  text-align: left;
  line-height:var(--fontsize2);
}

.ant-form-item-required::before {
  position: absolute;
  left: -9px;
  top: -2px;
}

.tiles-designs label {
  height: auto !important;
}

.tiles-designs .ant-form-item-control-input {
  min-height: auto;
}

.tiles-designs h5,
.tiles-designs h5 div {
  margin-bottom: 0;
   font-size: var(--fontsize2);
}

.tiles-designs a:hover {
  text-decoration: none;
}


.ant-menu-submenu {
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0 !important;
  /* box-shadow: 1px 0px 7px #ddd; */
  margin-top: 2px;
  margin-bottom: 2px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  border-radius: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: var(--fontenglish);
  font-weight: 500;
}

/* :where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-image: linear-gradient(45deg, #340785, #662a7f, #f5027e);
} */

/* :where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light>.ant-menu .ant-menu-submenu-selected>.ant-menu-submenu-title {
  color: #662a7f !important;
} */

:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  height: 50px;
  line-height: 40px;

  /* text-shadow: 5px 4px 6px #bdbdbd; */
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light>.ant-menu .ant-menu-item-selected {
  color: #fff;
}

.left_nav {
  padding-left: 1px !important;
  line-height: 1px !important;
}

/* .ant-drawer-content-wrapper {
  width: 250px !important;
} */

:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-ed5zg0).ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-color: transparent;
}

.back-arrow {
  color: white;
  /* font-size: 30px; */
  padding-left: 17px;
  cursor: pointer;
  position: absolute;
}

/* .ball {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: orange;
  box-shadow: 0 0 35px 5px yellow, 0 0 25px 10px yellow inset;
  animation: sizechange 10s infinite;
} */

.ball {
  /* animation: sizechange 10s infinite; */
  rotate: 0deg;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url(../../assets/images/sun_pic.png);
  background-size: contain;
  width: 70px;
  height: 70px;
}

.cta.container {
  padding: 20px;
  background: rgb(255 246 219 / 72%);
  /* border-radius: 15px;
  box-shadow: inset 7px 7px 30px #ddd; */
}

.cta .content h3 {
  color: var(--color-secondary);
  font-size: 30px;
  font-weight: 500;
  margin-bottom:15px;
}

/* @keyframes sizechange {
  0%,
  100% {
    height: 75px;
    width: 75px;
    rotate: 360deg;
  }

  50% {
    height: 55px;
    width: 55px;
    rotate: 180deg;
  }
} */
.btn-secondary {
  color: var(--colorheading1) !important;
  background-color: #f5f5f5 !important;
  border-color: 1px solid var(--colorheading2) !important;
  border-radius: 3px !important;
  padding-right: 24px !important;
  position: relative;
  padding-left: 10px !important;
  margin-left: 20px;
  box-shadow: 0px 1px 1px #edc8ad;
}

.btn-secondary:focus-visible {
  border: 0;
}

.btn-secondary i {
  color: #99775f;
   font-size: var(--fontsize3);
  position: absolute;
  top: -1px;
  right: -8px;
  animation-duration: 5s;
  background: white;
  border-radius: 50%;
}

.card-text-title2 {
  margin-top: 10px;
  padding: 0px 0;
  font-size: var(--fontsize2);
  font-family: var(--fontenglish);
  font-weight: 700;
  color: var(--colororange3);
    border-bottom: 1px solid #dddddd;
  float: left;
  width: 100%;
}

/* .details-temple-main .card-text-title{
  margin-top: 10px;
  color: #972222;
  border-bottom: 0px solid grey;
  float: left;
  width: 100%;
} */

.card-text-title {
  margin-top: 0px;
  padding: 5px 0;
   font-size: var(--fontsize2);
   font-family: var(--fontenglish);
  font-weight: 700;
  color: var(--colororange3);
  border-bottom: 1px solid grey;
  /* border: 1px solid red; */
  float: left;
  width: 100%;
}

.card-text-title span {
  color: #612d81;
   font-size: var(--fontsize1);
  font-weight: 400;
}

.special-addon{
  grid-gap: 15px;
  gap: 15px;
    color: #a33d38;
    margin-top: 5px;
}

.det-image {
  /* width: 30vw; */

  height: 36vh;
  border-radius: 10px;
  margin: 2%;
}

.deta-image {
  width: 30vw;
  height: 50vh;
}

.line {
  border-bottom: 1px solid black;
  margin-top: 3%;
  width: 80vw;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid var(--colororange3);
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}




/* Centers the spinner and ensures it's not blurred */
.modal-blur .center-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  /* Make sure the spinner is above blurred content */
}


.spinner-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #85858552;
}

.library-button {
  border: none;
  color: #a110ac;
  font-weight: bold;
}

.detail-button,
.library-button {
  background-color: #ddd;
  border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  text-transform: uppercase;
  padding: 10px 40px;
}

.detail-button.active,
.library-button.active {
  background-color: var(--colorRed);
  box-shadow: 5px 5px 15px #afafaf;
  color: white !important;
}

.active-details {
  width: 100%;
  /* margin: 1% 2%; */
  background: #ffffff85;
  padding-bottom: 10px;
  float: left;
  font-family: var(--fontenglish);
  font-weight: 500;
}

.active-tab-details {
  border-top: 2px solid #a7a0a0;
  width: 96%;
  margin: 1% 2%;
  background: white;
  padding-bottom: 10px;
}

.detail-button.hover,
.library-button.hover {
  border-color: var(--colorRed);

  color: var(--colorRed) !important;
}

.detail-button span,
.library-button span {
   font-size: var(--fontsize2);
  font-weight: 500;
}

.edit-button {
  background-color: #891491;
  color: white;
}

.carousel-inner {
  margin-top: 3%;
  position: relative;
  width: 100vw !important;
  overflow: hidden;
}

.ant-carousel,
.slick-slider,
.slick-list,
.slick-track,
.slick-slide,
.slick-slide>div,
.banner22 {
  height: 100%;
}

.banner22 {
  display: flex;
  align-items: center;
  justify-content: center;
}


#hero {
  position: relative;
  z-index: 1;
  height: 100%;
}

/* .ant-col.css-dev-only-do-not-override-ed5zg0 {
  margin-left: 10px;
} */

.carousel {
  width: 100%;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  /* Adjust margin as needed */
}

.carousel-indicators button {
  width: 20px;
  /* Set the width to create dash-like appearance */
  height: 5px;
  /* Set the height */
  border: none;
  border-radius: 0;
  background-color: #555;
  /* Set the background color */
  margin: 0 5px;
  /* Adjust spacing between indicators */
  cursor: pointer;
}

.carousel-indicators button.active {
  background-color: #fff;
  /* Set the active indicator color */
  width: 20px;
  /* Set the width to create dash-like appearance */
  height: 5px;
  /* Set the height */
  border: none;
  border-radius: 0;
}

.ant-input-clear-icon .anticon {
  float: left;
}

.ant-dropdown-menu-title-content a:hover {
  text-decoration: none !important;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-form-item .ant-form-item-label>label::after {
  content: "";
  position: relative;
  margin-block: 0;
  margin-inline-start: 2px;
  margin-inline-end: 8px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  font-family: var(--fontenglish);
  border: none;
  border-radius: 5px;
  outline: none;
  line-height: 27px;
}

a.carousel-control-next {
  display: none;
}

.clear-icon {
  margin-left: -25px;
  /* Adjust the margin according to your design */
  cursor: pointer;
  margin-top: 8px;
}

.temple-details-ratingsec {
  line-height: 25px;
  display: flex;
  align-items: center;
}


.ant-modal-body {
  margin-top: -8px;
  border-radius: 7px;
}

/* .ant-image-preview-mask {
  display: none;
} */
.god_pic>img {
  height: 60px;
  width: 60px;
  border-radius: 20px;
  box-shadow: 2px 3px 3px #ddd;
}

.god_pic>button {
  height: 60px;
  width: 60px;
}

.god_pic>p {
   font-size: var(--fontsize1);
  margin-bottom: 0;
  text-align: center;
}


.t-details {
  display: block !important;
}

.select-element {
  padding: 0px;
   font-size: var(--fontsize2);
  width: 49%;
}

.button-sec button {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.button-sec button:hover {
  background-color: #0056b3;
}

.detail-container-image img {
  height: 100px !important;
  width: 100% !important;
}

.search_page {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: 0px 0 0px 0;
  top: 7px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-input-affix-wrapper>input.ant-input {
  width: 101%;
}



.detail-container {
  position: relative;
  display: inline-block;
}

span.ant-modal-close-x {
  color: aliceblue;
}



/* CSS */
.godCtgry {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 2px solid #ff8c00;
  /* Custom border color */
  border-radius: 5px;
  cursor: pointer;
   font-size: var(--fontsize2);
  transition: all 0.3s ease;
  background-color: #ffe4b5;
  /* Custom background color */
  color: #ff4500;
  /* Custom text color */
}

.godCtgry.filterMatch {
  background-color: #c15506;
  border-color: #c15506;
  color: #fff;
  margin-left: 5px;
}

.godCtgry.filterMismatch {
  background-color: #ff8c00;
  border-color: #d9d9d9;
  color: #f9f1f1;
  margin-left: 5px;
}

.godCtgry:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: white !important;
  border: #000;
}


.next-row {
  margin-top: 20px;
  display: flex;
  justify-content: center !important;
  grid-gap: 5px;
  gap: 5px;
  margin-bottom: 20px;
}

.close-icon {
  position: absolute;
  top: -8px;
  right: -12px;
  margin-right: 5px !important;
  cursor: pointer;
  margin-top: 4px !important;
  background-color: var(--colorheading1);
  border-radius: 50%;
  color: white !important;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.close-icon:hover, .close-icon2:hover {
  background-color: red; /* Change background color on hover */
  transform: scale(1.1); /* Slightly enlarge */
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.5); /* Add a subtle glow */
}

.close-icon svg, .close-icon2 svg {
  height: 20px;
  width: 20px;
  transition: transform 0.2s ease;
}

.close-icon:hover svg, .close-icon2:hover svg {
  transform: rotate(10deg); /* Slight rotation effect */
}


.activate-btn {
  padding: 0 8px;
  border: 2px solid #ff4d4f; /* Red border */
  cursor: pointer;
  background-color: #ff4d4f; /* Red background */
  color: white;
  border-radius: 4px;
  font-size: 12px; /* Smaller font size */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  margin-bottom: 8px; /* Adds space between button and image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  margin-left: 2px;
}

/* Active state */
.activate-btn.active {
  background-color: #e6362f; /* Darker red on activation */
  border-color: #e6362f;
}

/* Hover effect */
.activate-btn:hover {
  transform: scale(1.05); /* Slightly enlarges the button */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

/* Hover effect for active state */
.activate-btn.active:hover {
  background-color: #d72f27; /* Even darker red on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
  transform: scale(1.05);
}

/* Disabled state */
.activate-btn:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
  border-color: #ccc;
}

.close-icon2 {
  position: absolute;
  top: 20px;
  right: -8px;
  margin-right: 5px !important;
  cursor: pointer;
  margin-top: 4px !important;
  background-color: var(--colorheading1);
  border-radius: 50%;
  color: white !important;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}


:where(.css-dev-only-do-not-override-ed5zg0).ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  top: -4px;
  left: -9px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-form-item .ant-form-item-label {
  overflow: visible;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-carousel .slick-dots-bottom {
  bottom: 12px;
  /* margin-left: -15%; */
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-carousel .slick-dots li {
  box-shadow: 0px 1px 3px #8b8b8b;
}

/* :where(.css-dev-only-do-not-override-ed5zg0).ant-col {
  width: 100%;
} */

.slick-carousel-container {
  position: relative;
}

.leftoutlined,
.rightoutlined {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  border: none;
  outline: none;
  font-size: 24px;
  /* Adjust the font size as needed */
  color: #341333;
  /* Button color */
  cursor: pointer;
  font-weight: bold;
  /* Make the text bold */
  border-radius: 5px;
  width: 6%;
  height: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Adjust the font size as needed */
a.btn.btn-warning.shadow-0.yellowbtn {
  font-weight: 500;
  color: black;
}

.leftoutlined {
  left: 10px;
  /* Adjust the left position as needed */
}

.rightoutlined {
  right: 10px;
}

.leftoutlined:hover,
.rightoutlined:hover {
  color: #b1b1b1;
}

.details-main {
  background-color: #efefef;
  padding: 10px 0;
}

.yellowbtn:hover {
  color: var(--mdb-btn-hover-color);
  background-color: var(--mdb-btn-hover-bg);
  border-color: var(--mdb-btn-hover-border-color);
}

.yellowbtn {
  font-size: 12px !important;
  padding: 2px 15px !important;
  font-weight: 500;
  color: #341333;
}

.profile-form-button {
  width: 100%;
  /* color: white; */
  border-radius: 5px;

  background-image: linear-gradient(45deg, #ff6100, #d62b86);
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
  padding: 0px;
}

.profile-form-button:hover {
  width: 100%;
  color: white !important;
  border-radius: 5px;

  background-image: linear-gradient(45deg, #ff6100, #d62b86);
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: bold;
  padding: 0px;
}

/* Tooltip Styles */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  background-color: #ffc107;
  color: #212529;
  padding: 4px 10px;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
   font-size: var(--fontsize2);
  margin-bottom: 10px;
}

.tooltip-buttons {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #333;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip-container:hover .tooltip-buttons {
  visibility: visible;
  opacity: 1;
}

.tooltip-buttons button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tooltip-buttons button:hover {
  background-color: #0056b3;
}

.custom-marker {
  width: 30px;
  height: 30px;
}

.edit-icon {
  color: #c15506;
  margin-top: 4px;
   font-size: var(--fontsize1);
}

button.ant-modal-close {
  margin-top: -15px;
}

.temp-details-slider div .slick-slide div {
  display: flex;
  align-items: center;
}

.temp-details-slider div .slick-slide img{
margin: 16px;
border-radius: 16px;
overflow: hidden;
}
.temp-details-slider div .slick-slide {
  display: flex !important;
  justify-content: center !important;
  /* background-color: red; */
  background-image: url(../../assets/images/img_bg.jpg);
  background-size: contain;
  border: 1px solid #e5e5e5;
  box-shadow: inset 0px 0px 20px #e7e7e7;
  height: 100%;
  align-items: center;
  border-radius: 15px;
  margin: 0;
}

/* .slick-slide img {
  display: block;
  box-shadow: 0px 4px 5px #a5a5a5;
  border: 2px solid #ddd;
} */



.like-share-section {
  display: flex;
  width: 40px;
  align-items: center;
  grid-gap: 20px;
  grid-gap: 40px;
  grid-gap: 40px;
  gap: 40px;
  justify-content: space-between;
  width: 100%;
}

.detailstxt-artical-icon {
  background-color: transparent;
  border: 0;
}

.detailstxt-artical-icon i {
  font-size: 20px !important;
  color: #e67e22;
}


.like-btn,
.share-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #e67e22;
  font-size: 50px;
}

.like-btn:hover,
.share-btn:hover {
  color: grey;
}


.image-column {
  display: flex;
  flex-direction: column;
}

/* CSS for grid container */
.tiles-grid {
  display: grid;
  grid-template-columns: repeat(4,
      1fr);
  /* Adjust the number of columns as needed */
  grid-gap: 20px;
  gap: 20px;
  /* Adjust the gap between items as needed */
}

/* CSS for individual image items within the grid */
/* .tiles-main {
  text-align: center;
} */

/* Modal
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

/* Modal content */
/* .modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
}  */

.carousel-control-prev {
  left: 0;
  display: none !important;
}

.custom-popover .notification-list p:hover {
  text-decoration: none !important;
}

.ant-image-img.card-img {
  border: 2px solid #606060;
  box-shadow: 3px 3px 6px #838383;
  border-radius: 10px;
}

/* :where(.css-dev-only-do-not-override-ed5zg0).ant-image .ant-image-img {
  width: 70px;
  height: 70px;
  vertical-align: middle;
} */

:where(.css-dev-only-do-not-override-ed5zg0).ant-image-preview-root .ant-image-preview-img {
  border: 1px solid #fff;
  box-shadow: 1px 1px 8px #5a5a5a;
}

.notification-link:hover {
  text-decoration: none !important;
  /* Add any other styles you want for the link on hover */
}

span.ant-form-item-feedback-icon.ant-form-item-feedback-icon-success {
  margin-bottom: 6px;
}

.ant-modal-body {
  padding: 0;
}

.dialog-content {
  padding: 0 15px;
  height: 300px;
  overflow: auto;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.cat-description {
  height: calc(100% - 45px);
}

.dialog-buttons-footer {
  padding: 10px;
  text-align: center;
  float: left;
  width: 100%;
  display: none;
}

.contribute-history form {
  float: left;
  width: 100%;
  height: calc(100% - 53px);
}

.contribute-history form>div.ant-form-item {
  height: 100%;
}

.contribute-history form>div .ant-form-item-row {
  height: 100%;
  display: block;
}

.contribute-history form>div .ant-form-item-row .ant-form-item-control {
  height: calc(100% - 48px);
}

.contribute-history form>div .ant-form-item-row .ant-form-item-control-input,
.contribute-history form>div .ant-form-item-row .ant-form-item-control-input-content {
  height: 100%;
}

.contribute-history form>div .ant-form-item-row .ant-form-item-control-input textarea {
  height: 100% !important;
}

#div-gpt-ad-123456789-0 {
  border: 1px solid red;
  background-color: #f0f0f0;
  /* Just for debugging */
}

#div-gpt-ad-responsive {
  background-color: white;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-secondary1 {
  color: #341333;
  background-color: #e9e9e9 !important;
  border-color: #6c757d !important;
  border-radius: 8px !important;
  padding-right: 24px !important;
  position: relative;
  padding-left: 10px !important;
  margin-right: 20px;
}

.filter-link {
  color: #ffb300;
   font-size: var(--fontsize2);
  margin-left: 10px;
  cursor: pointer;
}

.filter-link:hover {
  color: var(--colorheading1);
}

.mainsec {
  height: calc(100% - 72px);
  overflow: auto;
}

.mainsec-block {
  height: calc(100% - 48px);
  overflow: auto;
}

.youtube-video {
  width: 100%;
}

.mainsec-block-detailspage {
  height: calc(100% - 90px);
  overflow: auto;
}

.bg-details-highlight {
  /* border-radius: 10px; */
  /* border: 0px solid #e9e9e9; */
  /* background: linear-gradient(45deg, #59174e, #ff5500); */
  /* background: linear-gradient(225deg, #401487, #ae4800) */
    /* margin-bottom: 40px; */


    /* border-radius: 10px; */
    /* border: 1px solid #e9e9e9; */
    /* background: linear-gradient(45deg, #59174e, #ff5500); */
    /* background: linear-gradient(225deg, #e9c8b4, #ededed)  */



    background: linear-gradient(45deg, #e7e7e7 5%, #fff 10%, #ccc 30%, #f5f5f5 50%, #e1e1e1 70%, #fff 80%, #dddddd 95%);
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  
    transition: all 0.2s ease-in-out;

}



.text-white {
  color: white;
}



.details-headertxt-singleLine {
  color: #3b3b3b !important;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
   font-size: var(--fontsize3);
  /* background-image: linear-gradient(180deg, #efa51e00, #bbbbbb); */
  /* text-shadow: 1px 0px 0px #97380c; */
  text-transform: capitalize;
  margin-bottom: 0;
  display: flex;

  white-space: pre;
  display: flex;


  background-image: url("../../assets/images/mandala_top.png");
  background-size: 90px 47px;
  background-repeat: no-repeat;
  background-position: top right;

  align-items: center;
  justify-content: space-between;

}

.details-headertxt-singleLine::after,
.details-headertxt-singleLine::before {
  display: none;
}

.details-headertxt::after,
.details-headertxt::before {
  display: none;
}

.details-headertxt {
  color: #fff !important;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  font-size: 24px;
  /* background-image: linear-gradient(180deg, #efa51e00, #bbbbbb); */
  text-shadow: 1px 0px 0px #97380c;
  text-transform: capitalize;
  margin-bottom: 0;
  display: flex;

  white-space: pre;
  font-weight: 700;
  display: flex;
  flex-direction: row;

  background-image: url("../../assets/images/mandala_top.png");
  background-size: 90px 47px;
  background-repeat: no-repeat;
  background-position: top right;

}

.view-count {
   font-size: var(--fontsize1);
  margin-left: 5px;
  line-height: 25px;
}

.header_star i {
   font-size: var(--fontsize2);
}

.header_txt {
  float: left;
   font-size: var(--fontsize3);
  line-height: 28px;
  margin-right: 20px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: initial;
  text-align: left;
  /* text-shadow: 1px 1px 1px #000; */
  text-overflow: ellipsis;
color: #994c16;
position: relative;
top: 3px;
}


.highlighted-text {
  font-weight: bold;
  color: #fff;
  font-size: 24px;
  text-shadow: 2px 2px 3px #d1a117;
}

.header_star {
  float: left;

}

.details-title {
  font-weight: 700;
  width: 150px;
  margin-left: 20px;
  color: var(--colorheadingv2);
}

.bg-details-highlight-hide::after {
  content: " ";
  display: block;
  background-image: url(../../assets/images/flowers_top.png);
  height: 45px;
  width: 99.89%;
  background-repeat: repeat-x;
  position: absolute;
  z-index: 2;
  background-size: contain;
}

.details-value {}

/*ant modal close button*/
button.ant-modal-close {
  display: none;
}

.login-diag-close {
  cursor: pointer;
  display: flex;
  justify-content: right;
  margin-right: -3px;
  position: absolute;
  right: 7px;
  top: 0px;
  background: #e0e0e0;
  width: 22px;
  height: 22px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  z-index: 1;
  display:none;
}

.login-diag-close i {
   font-size: var(--fontsize2);
}

.diag-close {
  cursor: pointer;
  display: flex;
  justify-content: right;
  margin-right: 7px;
}

.diag-close i {
   font-size: var(--fontsize3);
}

.library-icons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 8px;
  display: none;
}

.library-icons>div {
  width: 60px;
  height: 60px;
  background: #f7f7f7;
  border-radius: 12px;
  border: 1px solid #c5c5c5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.library-icons>div i {
  font-size: 35px;
  color: #a882cb;
  text-shadow: 1px 1px 4px #b3b3b3;
}

.heading-text-home {
  margin-top: 15px;
}

.library-icons2 {
  margin-bottom: 10px;
  width: 60px;
  height: 60px;
  background: #f7f7f7;
  border-radius: 12px;
  border: 1px solid #c5c5c5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.library-icons2>i {
  font-size: 35px;
  color: #875aa7;
  text-shadow: 1px 1px 4px #b3b3b3;
}

.library-block {
  width: 20%;
  justify-content: center;
}

.featured-services .service-item:hover {
  transform: translateY(-10px);
  /* box-shadow: 0px 0 60px 0 #ddd; */
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-header h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: violet;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.block-float {
  display: flex;
  flex-direction: column;
}

.featured-services .service-item {
  padding: 30px;
  transition: all ease-in-out 0.4s;
  /* background: white; */
  height: 100%;
}

.section-header h2 {
   font-size: var(--fontsize3);
  font-weight: 600;

  padding-bottom: 10px;
  position: relative;
  margin-top: 20px;
}

.section-header {
  text-align: center;
}

.section-header p {
  margin-bottom: 0;
  color: #6f6f6f;
}

.library-icons-content {
  display: block;
}

.mainsec-block-single-buttons-detailspage {
  height: calc(100% - 125px);
  overflow: auto;
}

.mainsec-block-addtemple-detailspage {
  height: calc(100% - 95px);
  overflow: auto;
  padding-top: 10px;
  margin-top: 5px;
}

.mainsec-block-addtemple-detailspage2 {
  height: calc(100% - 80px);
  overflow: auto;
}

.mainsec-block-addtemple-detailspage .container form,
.mainsec-block-addtemple-detailspage form {
  float: left;
  width: 100%;
}

.mainsec-block-addtemple-detailspage2 .container form,
.mainsec-block-addtemple-detailspage2 form {
  float: left;
  width: 100%;
}

.mainsec-block-horo-detailspage1 {
  height: calc(100% - 142px);
  overflow: auto;
  min-height: 300px;

}

.mainsec-block-panchang-detailspage {
  height: calc(100% - 92px);
  overflow: auto;
}

.mainsec-block-addtemple-detailspage1 {
  height: calc(100% - 190px);
  overflow: auto;
}

/* :where(.css-dev-only-do-not-override-ed5zg0).ant-picker .ant-picker-input>input {
  color: rgb(193 85 6);
  border-color: #c15506;
} */

:where(.css-dev-only-do-not-override-ed5zg0).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-ed5zg0).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-ed5zg0).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #efa51e;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-picker-dropdown .ant-picker-header-view button:hover {
  color: #efa51e;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-picker .ant-picker-suffix {
  color: #080808;
}

.ant-picker.css-dev-only-do-not-override-ed5zg0 {
  /* border: 1px solid #c15506; */
  height: 27px;
}

.transparent-ui {
  background: linear-gradient(45deg, #9f829a, #ff5500);
  opacity: 0.5;
  /* Set the desired transparency level */
  pointer-events: none;
  /* Allow click-through on transparent elements */
}

.btn-success {
  height: 27px;
  font-weight: 700 !important;
  font-size: small !important;
  padding: 1px 10px !important;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.nodata-image {
  height: 80vh;
  width: 72vw;
}

.nav-icon-main {
  width: 150px;
  /* Adjust as needed */
}

.dropdown-menu {
  width: 170px;
  /* Adjust as needed */
}

.nav-icon-main span {
  white-space: nowrap;
  /* Prevents wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
}

/* YourComponentStyles.css */
.tiles-design-temple-cat {
  position: relative;
}

.hover-links {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hover-links a {
  color: #ff7732;
  font-weight: 700;
  font-size: large;
}

.hover-links a:hover {
  color: black;
  font-weight: 700;
  font-size: large;
}

.tiles-design-temple-cat:hover .hover-links {
  display: flex;
}

.card-img-container {
  position: relative;
}

.highlighted {
  background-color: yellow;
  /* or any other highlighting style */
}

.highlight {
  border: 3px solid #ffcc00 !important;
  border-radius: 10px !important;
  transition: border 0.3s ease-in-out;
}


.close-icon1 {
  position: absolute;
  top: -6px;
  left: 2px;
  /* Add this */
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  color: white;
}


/* .map-details{
  width: 33vw;
  height: 30vh;
}

@media (max-width: 600px) {
  .map-details {
    width: 20vw;
    height: 20vh;
  }

  
} */

button:disabled {
  background-color: Grey;
  color: red;
}

/* .ant-form-horizontal .ant-form-item-control {
  flex: auto;
} */

.search-icon-mobile {
  display: none;
}

.search-icon-web {
  display: block;
}


:where(.css-dev-only-do-not-override-ed5zg0).ant-menu .ant-menu-title-content {
  text-align: left;

}

.add-temple-icon i {
   font-size: var(--fontsize3);
}

.youtube-video {
  width: auto !important;
}

.ant-image-mask {
  display: none;
}

button.ant-btn.css-dev-only-do-not-override-ed5zg0.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
  height: 30px;
}

.resource-search input {
  padding-left: 0px !important
}

.resource-search {
  margin-left: 1%;
  width: 50%;
  /* padding: 0px;
  
       font-size: var(--fontsize2);
     
      width: 80%;
      margin-left: 5%; */
}

.tile-add {
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  margin: 0;
   font-size: var(--fontsize1);
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
}

.gm-style-iw-chr {
  position: absolute;
  right: 0;
}

.gm-style .gm-style-iw-d {
  padding-top: 13px;
}

.dialog-content-text-only {
   font-size: var(--fontsize2);
  line-height: 22px;
  font-weight: 500;
  height: calc(100% - 150px);
  overflow: auto;
}

.dialog-buttons {
  padding-top: 5px;
  display: flex;
  justify-content: center;
}

.dialog-content-text {
   font-size: var(--fontsize2);
  line-height: 22px;
  font-weight: 500;
  height: calc(100% - 144px);
  overflow: auto;

}

.hr_line {
  background-color: #545454;
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
}

.center-align {
  width: 100%;
  display: flex;
  justify-content: center;
}


.map-buttons-bottom {
  display: flex;
  justify-content: space-evenly;
  padding: 0 20px;
}

element.style {
  display: flex;
  display: flex;
  justify-content: space-around;
}

.lib-block {
  width: 120px;
}

.bg-lib-sec {
  height: 80px;
  width: 100%;
  border-radius: 20px;
  background-image: linear-gradient(45deg, #fff2c1, transparent);
  /* margin-left: 10px; */
  box-shadow: 0px 10px 10px #ddd;
}

.lib-sec-img {
  float: left;
  width: 90%;
  margin-left: 5%;
  height: 0;
}

.lib-sec {
  display: flex;
  margin-top: 40px;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 20px;
  width:1000px;
  margin:auto;
}

.lib-sec-img img {
  width: 100%;
  margin-top: -80px;
}




.bg-lib-sec-text-sec {
  text-align: center;
  width: 100%;
  display: block;
   font-size: var(--fontsize2);
  font-weight: 600;
  margin-top: 15px;
}


.more-info-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.diag-image-content {
  white-space: pre;
  height: calc(100% - 170px);
  overflow: auto;
  color: #000;
  /* font-weight: 500; */
   font-size: var(--fontsize2);
  line-height: 28px;
}









.acceptButton {
  width: 80px;
  height: 30px;
  background-color: #ff8b57;
  transition-duration: .2s;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px #f3ca7e, 0 2px 4px -1px #f3997e;
  transition: all .6s ease;
}

.search .declineButton {
  margin: 5px;
}

.declineButton {
  background-color: #fff;
  font-family: var(--fontenglish);
  transition-duration: .2s;
  color: rgb(96 96 96);
  border: none;
  cursor: not-allowed;
  font-weight: 500;
  border-radius: 5px;
  /* box-shadow: 0 4px 6px -1px #d8a4a4, 0 2px 4px -1px #bebdbd; */
  box-shadow: 0 2px 2px -1px #d8a4a4;
  transition: all .6s ease;
  white-space: nowrap;
  margin-right: 10px;
  padding: 3px 5px 0 5px;
   font-size: var(--fontsize1);
  line-height: 22px;
  text-transform: capitalize;
  /* padding-top:2px; */
}

/* .declineButton i {
  display: none;
} */

orance

.declineButton:hover {
  background-color: #ebebeb;
  box-shadow: 0 5px 5px -3px #bebdbd;
  transition-duration: .2s;
}

.acceptButton:hover {
  background-color: #9173ff;
  box-shadow: 0 10px 15px -3px #977ef3, 0 4px 6px -2px #977ef3;
  transition-duration: .2s;
}







.temp-detail-demo-sec {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.temp-detail-demo-sec h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  font-family: inherit;
   font-size: var(--fontsize1);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  color: #7e97b8;
  background-color: #e0e8ef;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: rgba(255, 255, 255, 0.333);
  border-radius: 40px 40px 40px 40px;
  padding: 8px 4px 8px 4px;
  transform: translate(0px, 0px) rotate(0deg);
  transition: 0.2s;
  box-shadow: -4px -2px 16px 0px #ffffff, 4px 2px 16px 0px rgb(95 157 231 / 48%);
  width: 160px;
}

.temp-detail-demo-sec h2:hover {
  color: #516d91;
  background-color: #E5EDF5;
  box-shadow: -2px -1px 8px 0px #ffffff, 2px 1px 8px 0px rgb(95 157 231 / 48%);
}

.temp-detail-demo-sec h2:active {
  box-shadow: none;
}






/* From Uiverse.io by JasonMep */
.button_move-copy {

   font-size: var(--fontsize1);

  border: 1px solid #f39c12;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px 10px;
  box-shadow: 0px 1px 0px #d35400;
  transition: all 0.1s;
  font-family: var(--fontenglish);
  font-weight: 500;
  height: 27px;
  display: inline-block;
  line-height: 20px;

  text-shadow: 1px 0px 0px #97380c;
}

.button_move-copy {
  text-decoration: none !important;
}


.button_move, .button_move1 {
  color: #ffffff;
  font-size: var(--fontsize1);
  background-color:#c75402;
  border: 1px solid #af3709;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px 1px 10px;
  /* box-shadow: 0px 1px 0px #d35400; */
  text-shadow: 0 0 BLACK;
  transition: all 0.1s;
  font-family: var(--fontenglish);
  font-weight: 500;
  height: 27px;
  display: inline-block;
  line-height: 20px;
  letter-spacing: .2px;
}

.button_move[disabled] {
  background-color: #dfdfdf;
  border: 1px solid #b7b3ac;
  text-shadow: 1px 1px 1px #ffffff;
  box-shadow: 0px 1px 0px #b1b1b1;
  color: #a7a7a7;
}

.button_move[disabled]:hover {
  background-color: #dfdfdf;
  border: 1px solid #b7b3ac;
  text-shadow: 1px 1px 1px #ffffff;
  color: #a7a7a7;
}

.button_move:hover {
  position: relative;
  color: white;
  text-decoration: none;
}

.button_move:active,
.button_move:focus {
  box-shadow: 0px 2px 0px #d35400;
  position: relative;
  color: white;
  text-decoration: none;
}

.popup-bg {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: #333333d1;
  z-index: 10000;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 80%;
  max-width: 800px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.article-titletxt {
   font-size: var(--fontsize2);
  color: #000;
  font-weight: 700;
}

.popup-main-artical {
  height: 80%;
  background: #fff;
  width: 600px;
  margin-bottom: 10px;
  border-radius: 10px;
  border-radius: 30px 30px #858585;
  /* background-image: url(../../assets/images/templebg.png); */
  background-position: bottom right;
  background-repeat: no-repeat;
}

.popup-main-header-text-artical {
  display: block;
  float: left;
  /* height: 20px; */
   font-size: var(--fontsize3);
  padding: 0px;
  font-weight: 600;
  color: #670505 !important;
  /* white-space: pre; */
  text-overflow: ellipsis;
  overflow: hidden;
  height: 25px;
  width: 100%;
  white-space: pre;
  margin-bottom: 10px;
  top:3px;
  position: relative;
}



.popup-main-header-artical {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 0 10px;
  /* background-image: linear-gradient(180deg, #faad14, transparent); */
  /* background-color: var(--colororange3); */
  background-color: #ffe5c8;
  position: relative;
  border-radius: 10px 10px 0 0;
  padding-bottom: 10px;
  /* flex-direction: column; */
}

.dialog-content-text-articaltxt-articalmain-data {
  height: calc(100% - 20px);
  overflow: auto;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  /* text-shadow: 1px 1px 3px #2b0606; */
  font-weight: 500;
  color: #000;
   font-size: var(--fontsize2);
  line-height: 24px;
  /* letter-spacing: .2px; */
  word-spacing: 1px;
  background-color: #eaeaeaed;
  font-weight: 500;
}

.scrollable-text {
  display: inline-block;
  animation: scroll-lines 40s linear infinite;
  animation-delay: 2s;
  /* white-space: nowrap;  */
}

@keyframes scroll-lines {
  0% {
    transform: translateY(0);
    /* Start at the top */
  }

  100% {
    transform: translateY(-100%);
    /* Scroll up all the content */
  }
}

.dialog-content-text-articaltxt-articalmain-data:hover .scrollable-text {
  animation-play-state: paused;
  /* Pause on hover */
}

.popup-main-container-artical {
  width: 100%;
  height: calc(100% - 125px);
  box-sizing: border-box;
  padding: 10px;
  margin: auto;

  position: relative;
  overflow: auto;
  border-radius: 0 0 10px 10px;
}

.htinner-popup-main-container-artical {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: end;
}

.dialog-content-text-artical {
   font-size: var(--fontsize2);
  line-height: 22px;
  font-weight: 500;
  width: 100%;
  overflow: auto;

}

.dialog-content-text-articaltxt {
  color: white;
  height: calc(100% - 30px);
}

.detailstxt-artical {
  color: inherit !important;
  text-decoration: none !important;
  /* font-size: 26px !important; */
}

.detailstxt-artical-title {
   font-size: var(--fontsize3);
  font-weight: 200;
  line-height: normal;
  text-shadow: 2px 2px 1px #000;
}

.popup-main {
  height: 80%;
  background: linear-gradient(180deg, #ffd89b, #ffe5ce);
  width: 600px;
  margin-bottom: 10px;
  border-radius: 10px;
  border-radius: 30px 30px #858585;
}

.popup-main-login {
  height: 550px;
  background: white;
  width: 600px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px #717171;
  border: 3px solid #ff9b04;
}

.role-buttons {
  display: flex;
  justify-content: center;
}

.popup-main-header::after {
  content: "";
  display: block;
  height: 140px;
  width: 140px;
  background-image: url(../../assets/images/m2.png);
  background-size: contain;
  position: absolute;
  right: 0;
  animation: rotate 10s linear infinite;
  top: 0;
  opacity: .4;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.content-filter {
  border: 1px solid #d1d1d1;
  padding: 10px 20px;
  border-radius: 10px;
  background: #f1f1f1;
  margin-top: 6px;
}

.popup-main-filter {
  height: 555px;
  background: #fff;
  width: 600px;
  margin-bottom: 10px;
  border-radius: 10px;
  border-radius: 30px 30px #858585;
}

.popup-main-header-filter {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  background: #ffbe00;
  /* background-color: var(--colororange3); */
  position: relative;
  border-radius: 10px 10px 0 0;
  margin-bottom: 10px;
}

.popup-main-header-text-filter {
  display: block;
  float: left;
  /* height: 20px; */
   font-size: var(--fontsize2);
  padding: 0px;
  font-weight: 600;
  color: #670505 !important;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  top:3px;
  position: relative;
}

.popup-main-container-filter {
  background-image: linear-gradient(359deg, white, #ffffffa6);

  width: 97%;
  height: calc(100% - 95px);
  box-sizing: border-box;

  margin: auto;
  position: relative;
  overflow: auto;
}


.popup-main-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  /* background: #ffbe00; */
  /* background-color: var(--colororange3); */
  position: relative;
  border-radius: 10px 10px 0 0;
}

.popup-main-header-text {
  display: block;
  float: left;
  /* height: 20px; */
   font-size: var(--fontsize2);
  padding: 0px;
  font-weight: 600;
  color: #670505 !important;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  top:3px;
  position: relative;
}

.popup-back {
  display: none;
  background: var(--colororange3);
  width: 30px;
  text-align: center;
  position: absolute;
  float: right;
  top: -36px;
  height: 30px;

  align-items: center;
  justify-content: center;
  padding: 20px;
  left: 10px;
  color: white;
  border-radius: 10px;

}

.popup-close-search {
  background: var(--colororange3);
  width: 30px;
  text-align: center;
  position: absolute;
  float: right;
  top: -36px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  right: 10px;
  color: white;
  border-radius: 10px;
}

.popup-close-search i {
  font-size: 22px;
}

.popup-close {
  background: var(--colororange3);
  width: 30px;
  text-align: center;
  position: absolute;
  float: right;
  top: -36px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  right: 10px;
  color: white;
  border-radius: 10px;
}

.popup-close i {
  font-size: 22px !important;
  color: white !important;
}
.popup-main-container {
  background-image: linear-gradient(359deg, white, #ffffffa6);

  width: 95%;
  height: calc(100% - 95px);
  box-sizing: border-box;
  padding: 10px;
  margin: auto;
  position: relative;
  overflow: auto;
}

.popup-main-container1 {
  background-image: linear-gradient(359deg, white, #ffffffa6);

  width: 95%;
  height: calc(100% - 145px);
  box-sizing: border-box;
  padding: 10px;
  margin: auto;
  position: relative;
  overflow: auto;
}

.popup-main-footer {
  /* background: #dfdfdf; */
  float: left;
  width: 100%;
  height: 50px;
  border-radius: 0 0 10px 10px;
  /* justify-content: center; */
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px;
}

.popup-main-footer-search {
  /* background: #dfdfdf; */
  float: left;
  width: 100%;
  height: 50px;
  border-radius: 0 0 10px 10px;
  /* justify-content: center; */
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px;
}


.popup-main-footer1 {
  background-color: #ffe4cb;
  padding: 10px;
  height: auto; /* Allow height to adjust dynamically */
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  grid-gap: 1rem;
  gap: 1rem; /* Add space between rows */
}

.location-button {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem; /* Space between buttons */
}

.location-button1 {
  margin-left: auto;
}


.cropper-container {
  height: 100% !important;
  width: 100% !important;
}


.main_acc-button {
  background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: var(--fontenglish);
   font-size: var(--fontsize2);
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.main_acc-button:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.main_acc-button:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}


.btn-31,
.btn-31 *,
.btn-31 :after,
.btn-31 :before,
.btn-31:after,
.btn-31:before {
  border: 0 solid;
  box-sizing: border-box;
}

.btn-31 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: var(--fontenglish);
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
}

.btn-31:disabled {
  cursor: default;
}

.btn-31:-moz-focusring {
  outline: auto;
}

.btn-31 svg {
  display: block;
  vertical-align: middle;
}

.btn-31 [hidden] {
  display: none;
}

.btn-31 {
  border-width: 1px;
  padding: 1rem 2rem;
  position: relative;
  text-transform: uppercase;
}

.btn-31:before {
  --progress: 100%;
  background: #fff;
  clip-path: polygon(100% 0,
      var(--progress) var(--progress),
      0 100%,
      100% 100%);
  content: "";
  inset: 0;
  position: absolute;
  transition: clip-path 0.2s ease;
}

.btn-31:hover:before {
  --progress: 0%;
}

.btn-31 .text-container {
  display: block;
  overflow: hidden;
  position: relative;
}

.btn-31 .text {
  display: block;
  font-weight: 900;
  mix-blend-mode: difference;
  position: relative;
}

.btn-31:hover .text {
  animation: move-up-alternate 0.3s ease forwards;
}

@keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(80%);
  }

  51% {
    transform: translateY(-80%);
  }

  to {
    transform: translateY(0);
  }
}


header-button {}

.bannerbutton2 {
  font-family: inherit;
   font-size: var(--fontsize1);
  background: linear-gradient(to bottom, #ff9100 0%, #ff5600 100%);
  color: #fde6db;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  font-weight: 700;
}

.bannerbutton2:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.bannerbutton2:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}



.bannerbutton {
  font-family: inherit;
   font-size: var(--fontsize1);
  background: linear-gradient(to bottom, #ff9100 0%, #ff5600 100%);
  color: #7c2c04;
  padding: 0.8em 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  font-weight: 700;
}

.bannerbutton:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.bannerbutton:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.bannerbutton span {
  display: block;
  margin-left: 0.4em;
  transition: all 0.3s;
}

.bannerbutton svg {
  width: 18px;
  height: 18px;
  fill: white;
  transition: all 0.3s;
}

.bannerbutton .svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 0.5em;
  transition: all 0.3s;
}

.bannerbutton:hover .svg-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
}

.bannerbutton:hover svg {
  transform: rotate(45deg);
}


.popup-main-container-login {
  background-image: url(../../assets/images/bg-login.jpg);
  float: left;
  width: 100%;
  height: calc(100% - 50px);
  box-sizing: border-box;
  padding: 10px;
  background-size: cover;
  border-radius: 10px 10px 0 0;

}

.popup-main-footer-login {
  background-repeat: repeat-y;
  float: left;
  width: 100%;
  height: 50px;
  border-radius: 0 0 10px 10px;
  /* justify-content: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-size: contain;
}

.popup-login-main {
  width: 70%;
  margin: auto;
  margin-top: 50px;
  box-shadow: none;
}

.h3text-login {
  color: white;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 20px;
  display: none;
}



.search-india {
  width: 50% !important;
}

/* .htinner-popup-main-container {
  height: calc(100% - 10px);
} */

.htinner-popup-main-container form div,
.htinner-popup-main-container form {
  height: 100%;
}

.htinner-popup-main-container form div textarea {
  height: 63vh !important;
   font-size: var(--fontsize2);
}

.search-back-mob {
  margin-right: 15px;
  color: black;
  margin-left: 5px;
}

.page-title {
  background-image: linear-gradient(180deg, #ddd, #ffffff00);
  height: 40px;
  display: flex;
  align-items: center;
  /* margin-bottom: 9px;*/
}

.page-title .left-sec-mob-nav i {
  font-size: 22px;
  margin-left: 13px;
  margin-right: 13px;
  color: var(--colorRed2);
  text-shadow: 2px 1px 4px #c7c7c7;
}

/* .god-filter-btn i {
   font-size: var(--fontsize2);
  margin-left: 5px;
  color: var(--colorRed2);
  margin-right: 5px;
} */

.page-title .left-sec-mob-nav span {
   font-size: var(--fontsize2);
  font-weight: 600;
  color: var(--colorRed2);
  white-space: pre;
}

.mob-nav {
  display: none;
}

.breadcrumb-link {
  display: flex;
}

.add-temple-img {
  cursor: pointer;
  width: 100px;
}

.calendar-wrapper {
  width: 350px;
  border: 3px solid #e7e7e7;
  box-shadow: 1px 10px 10px #ddd;
  border-radius: 10px;
  position: absolute;
  right: 0;
}

.icon-btn-top {
  background: #dddddd;
  padding: 4px 6px;
  border-radius: 4px;
}

.buttons-left-right-jumping {
  float: right;
}

.search-mobile {
  display: none;
}


.search-mobile .search {
  width: 100%;
  position: relative;
  display: flex;
  padding: 5px;
}

.search-mobile .searchTerm {
  width: 100%;
  border: 1px solid #b3b3b3;
  border-right: none;
  padding: 5px;
  height: 30px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
   font-size: var(--fontsize1);
}

.search-mobile .searchTerm:focus {
  color: #00B4CC;
}

.search-mobile .searchButton {
  width: 40px;
  height: 30px;
  border: 1px solid #b3b3b3;
  background: #b3b3b3;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
   font-size: var(--fontsize3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-mobile .searchButton i {
   font-size: var(--fontsize2);
}



.god-filter-btn {
  display: flex;
  align-items: center;
  border: 1px solid #ff5600;
  /* box-shadow: inset 0px 0px 10px #ff56005c; */
  font-weight: 600;
  font-family: var(--fontenglish);
  color: #ff5600;
  background: white;
  margin-right: 5px;
  padding-top: 4px;
  height: 22px;
  border-radius: 5px;

  /* animation: blinkContinuous 2s infinite alternate;  */
}


.god-filter-btn i{

  animation: blinkContinuous 2s infinite alternate; 
  border-radius: 50%;
  padding: 3px;
  margin-top:-8px;
  position: relative;
  top: 1px;
}

@keyframes blinkContinuous {
  0% {
    background-color: white;
    color: #ff5600;
    border-color: #ff5600;
    box-shadow: inset 0px 0px 10px #ff56005c;
  }
  50% {
    background-color: #ff5600;
    color: white;
    border-color: #ff5600;
    box-shadow: inset 0px 0px 15px #ff5600;
  }
  100% {
    background-color: white;
    color: #ff5600;
    border-color: #ff5600;
    box-shadow: inset 0px 0px 10px #ff56005c;
  }
} 

.god-button {
  color: var(--colorheadingv2);
  font-size: var(--fontsize1);
  padding: 2px 13px 2px 2px;
  border-radius: 5px;
  line-height: 16px;
  height: 18px;
  transition: box-shadow 1s;
  font-weight: 600;
  position: relative;
  top: -1px;
  background: transparent;  
}

.god-button:hover {
  box-shadow: 2px 3px 3px #cdcdcd;
  cursor: pointer;
}

.god-filter-btn i {
  font-size: var(--fontsize2);
  margin-left: 5px;
  color: var(--colorRed2);
  margin-right: 5px;
  animation: blinkContinuous 2s infinite alternate; /* Added blink animation to icon */
}

.cropper-wrap-box, .cropper-canvas, .cropper-drag-box, .cropper-crop-box, .cropper-modal {
  bottom: 0px !important;
  left: 80px;
  position: absolute;
  right: 0;
  /* top: -15px; */
}

.article_btm-btns {
  display: flex;
  flex: 1 1;
  justify-content: space-around;
}

.details-icons-main-horoscope {
  display: flex;
  flex: 1 1;
}

.zodaic-logo-main {
  height: 52px;
  width: 16.65%;
  cursor: pointer;
}

.zs-bg1 {
  background-color: #f78579;
}

.zs-bg2 {
  background-color: #c49a6c;
}

.zs-bg3 {
  background-color: #89d3a7;
}

.zs-bg4 {
  background-color: #77b8e8;
}

.zs-bg5 {
  background-color: #f7e06b;
}

.zs-bg6 {
  background-color: #a3785a;
}

.zs-bg7 {
  background-color: #00a79d;
}

.zs-bg8 {
  background-color: #a28bbb;
}

.zs-bg9 {
  background-color: #f99c5d;
}

.zs-bg10 {
  background-color: #95877d;
}

.zs-bg11 {
  background-color: #94eadd;
}

.zs-bg12 {
  background-color: #a4a4ee;
}

.zodaic-logo-main .names {
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 500;
  padding: 0px;
}

.zs-icon-top {
  height: 20px;
  width: 20px;
  background-size: cover;
  margin: auto;
  margin-top: 7px
}

.zs-aries-icon {
  background-image: url(../../assets/images/aries_icon.png);
}

.zs-tarus-icon {
  background-image: url(../../assets/images/tarus_icon.png);
}

.zs-gemini-icon {
  background-image: url(../../assets/images/gemini_icon.png);
}

.zs-cancer-icon {
  background-image: url(../../assets/images/cancer_icon.png);
}

.zs-leo-icon {
  background-image: url(../../assets/images/leo_icon.png);
}

.zs-virgo-icon {
  background-image: url(../../assets/images/virgo_icon.png);
}

.zs-libra-icon {
  background-image: url(../../assets/images/libra_icon.png);
}

.zs-scorpio-icon {
  background-image: url(../../assets/images/scorpio_icon.png);
}

.zs-sagittarius-icon {
  background-image: url(../../assets/images/sagittarius_icon.png);
}

.zs-capricorn-icon {
  background-image: url(../../assets/images/capricorn_icon.png);
}

.zs-aquarius-icon {
  background-image: url(../../assets/images/aquarius_icon.png);
}

.zs-pisces-icon {
  background-image: url(../../assets/images/pisces_icon.png);
}

.zs-aries-icon-main {
  background-image: url(../../assets/images/aries.jpg);
}

.zs-tarus-icon-main {
  background-image: url(../../assets/images/tarus.jpg);
}

.zs-gemini-icon-main {
  background-image: url(../../assets/images/gemini.jpg);
}

.zs-cancer-icon-main {
  background-image: url(../../assets/images/cancer.jpg);
}

.zs-leo-icon-main {
  background-image: url(../../assets/images/leo.jpg);
}

.zs-virgo-icon-main {
  background-image: url(../../assets/images/virgo.jpg);
}

.zs-libra-icon-main {
  background-image: url(../../assets/images/libra.jpg);
}

.zs-scorpio-icon-main {
  background-image: url(../../assets/images/scorpio.jpg);
}

.zs-sagittarius-icon-main {
  background-image: url(../../assets/images/sagittarius.jpg);
}

.zs-capricorn-icon-main {
  background-image: url(../../assets/images/capricorn.jpg);
}

.zs-aquarius-icon-main {
  background-image: url(../../assets/images/aquarius.jpg);
}

.zs-pisces-icon-main {
  background-image: url(../../assets/images/pisces.jpg);
}


.zs-icon-main {
  height: 100px;
  width: 100px;
  background-size: contain;
  float: left;
  border-radius: 50%;
  box-shadow: 10px 10px 10px #dbd8d8;
}

.mainsec-block-horo-detailspage1 .tiles-designs {
  /* border: 1px solid #dfdfdf; */
  border-radius: 3px;
  padding: 10px;
  background: #f7f7f7;
}

.mainsec-block-horo-detailspage1 .tiles-designs h4 {

  display: flex;
  align-items: center;
  flex-direction: column;
}

.custom-modal {
  width: 90%;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  background-color: transparent;
}


.temple-details-container {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.form-item-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
}

.form-item-label {
  flex: 1 1;
  min-width: 150px;
  /* Adjust based on your layout needs */
}

.form-item-inputs {
  flex: 2 1;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.form-item-section {
  flex: 1 1;
}



.modalStyle {
  height: '400px',
}

.profile-butt {
  margin-bottom: '2%';
  margin-left: '50%';
  display: flex;
  justify-content: center;
}

.custom-scrollbar {
  overflow-x: auto;
  padding-bottom: 10px;
  padding: 5px;
}

.ant-row.css-dev-only-do-not-override-ed5zg0 {
  justify-content: center;
}

.btn1 {
  margin-left: 8px;
}

.btn {
  /* background: transparent; */
  /* color: inherit; */
}

.selected {
  background: #fff4dc !important;
  color: var(--colororange3) !important;
 border:1px solid var(--colororange3);
}
.selected i{

  color: var(--colororange3) !important;
}

/* .selected:hover {
  color: white !important;
} */
.my-custom-select {
  width: 80%;
}

/* .mainsec-block-horo-detailspage1 .tiles-designs {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 20px;
} */

.details-icons-main-horoscope-tile {
  /* box-shadow:0 8px 10px #ddd; */
}


.details-temple-main {
  background: #f9f9f9;
}

.templedetails-add-block {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;

  float: left;
  background-color: #ffffffd6;
}

.templedetails-add-block-main {
  flex: 1 1;
  padding: 0 0px;
  margin:0 2%;
  width: 96%;
}

.mainsec-block-detailspage-title {
   font-size: var(--fontsize2);
  font-weight: 600;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: #ffc82d;
  padding: 2px 2px;
  float: left;
  width: 100%;
}

.temple_icon_details-icons-title {
   font-size: var(--fontsize3);
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0;
  float: left;
  width: 100%;
  padding: 5px 10px;

}

.temple_icon_details-icons-pos {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
cursor: pointer;
  border-radius: 6px;
}

.mainsec-block-detailspage-title-text {
   font-size: var(--fontsize2);
  font-weight: 600;
  margin-top: 0px;
  line-height: 26px;
  margin-bottom: 0px;
  float: left;
  width: 100%;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}


.temple-details-icon-sec {
  float: left;
  width: 100%;
  margin-top: 11px;
  border-radius: 10px;
  background: #ffffffd6;

  /*   
  border-style: solid;
  border-width: 0px 0px 10px 0px;
  border-image: url(../../assets/images/h1.png) 0 0 60 0 repeat repeat; */


}

.temple-details-icon-sec-top {
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  width: 100%;

}

.temple_icon_details {
  /* background-image: url(/static/media/temple_icon_details.42e03076.svg); */

  width: 35px;
  height: 35px;
  background-repeat: no-repeat;

  border-radius: 30%;
  background-size: 60px 60px;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  line-height: 35px;
margin:auto;

}

.temple_icon_details i {
   font-size: var(--fontsize3);
  text-shadow: 4px 6px 6px #9d9797;
}

.temple-details-icon-text {
  float: left;
  clear: both;
  width: 100%;
  /* background: red; */
  text-align: center;
  color: var(--fontcolorblack);
  font-weight: 600;
}

.clr-bth {
  clear: both;
}

.overflow-y-auto {
  overflow-y: auto;
}

.temple_detailsbg {
  background-image: url(../../assets/images/templebg.jpg);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  background-attachment: fixed;

}

.mainsec-block-detailspage-new {
  height: calc(100% - 98px);
  overflow: auto;
}

.f-w-500 {
  font-weight: 400;
}

.details-info-txt {
  color: #561e78;
  width: 120px;
  display: block;
  float: left;
}

.details-info2 {
  background: #ededed;
  border-radius: 10px;
  padding: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

.templedetails-add-sec {
  flex: 1 1;
  width: 100%;

  flex-direction: column;

}

.gridbg3 {
  width: 250px;
}

.gridbg2 {
  width: 250px;
}

.gridbg1 {
  width: 150px;
}

.timming-grid {
  border: 1px solid #ddd;
  float: left;
  width: 100%;
  background-color: #fff;
}

.timming-grid tr td {
  padding: 5px;
  color:var(--fontcolorblack);
}

.timming-grid tr th {

  text-align: left;
  color: #383838;
  background: #ddd;
  padding: 5px 15px;
}

.timming-grid tr td:nth-child(1) {
  width: 30%;
}

.article-image {
  width: 110px;
  height: 110px;
  background: black;
  background: url(../../assets/images/templeimg.png) no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.temp-details-secheader-btnsec {
  flex-direction: row;

}



/* .add-w-control {
  width: calc(100% - 120px)
} */


.mainsec-block-detailspage-title-text1 {
  float: left;
}

.details-info-txt-label {
  float: left;
  color: #f36408;
  width: 120px;
}

.details-info-txt-value {
  float: left;
  display: block;
  width: calc(100% -120px);
  white-space: break-spaces;
  margin-left: 7px;
  flex:1 1;
  color: var(--fontcolorblack);
}

.details-info-txt-value1 {
  float: left;
  display: block;
  width: calc(100% -120px);
  white-space: break-spaces;
  margin-left: 7px;
  text-transform: capitalize;

  flex:1 1;
}

.mainsec-block-detailspage-title-text-main {
  background: #ffc260;
  padding: 3px 7px;
  border-radius: 6px;
}



.type--A {
  --line_color: #555555;
  --back_color: #ffecf6;
}

.type--B {
  --line_color: #1b1919;
  --back_color: #e9ecff;
}

.type--C {
  --line_color: #00135c;
  --back_color: #defffa;
}

.button-c {
  position: relative;
  z-index: 0;
  width: 240px;
  height: 56px;
  text-decoration: none;
   font-size: var(--fontsize2);
  font-weight: bold;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  color: var(--colororange3);
}

.button__text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.button-c::before,
.button-c::after,
.button__text::before,
.button__text::after {
  content: "";
  position: absolute;
  height: 3px;
  border-radius: 2px;
  background: var(--colororange3);
  transition: all 0.5s ease;
}

.button-c::before {
  top: 0;
  left: 54px;
  width: calc(100% - 56px * 2 - 16px);
}

.button-c::after {
  top: 0;
  right: 54px;
  width: 8px;
}

.button__text::before {
  bottom: 0;
  right: 54px;
  width: calc(100% - 56px * 2 - 16px);
}

.button__text::after {
  bottom: 0;
  left: 54px;
  width: 8px;
}

.button__line {
  position: absolute;
  top: 0;
  width: 56px;
  height: 100%;
  overflow: hidden;
}

.button__line::before {
  content: "";
  position: absolute;
  top: 0;
  width: 150%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 300px;
  border: solid 3px var(--colororange3);
}

.button__line:nth-child(1),
.button__line:nth-child(1)::before {
  left: 0;
}

.button__line:nth-child(2),
.button__line:nth-child(2)::before {
  right: 0;
}

.button-c:hover {
  letter-spacing: 6px;
  color: var(--colororange3);
}

.button-c:hover::before,
.button-c:hover .button__text::before {
  width: 8px;
}

.button-c:hover::after,
.button-c:hover .button__text::after {
  width: calc(100% - 56px * 2 - 16px);
}

.button__drow1,
.button__drow2 {
  position: absolute;
  z-index: -1;
  border-radius: 16px;
  transform-origin: 16px 16px;
}

.button__drow1 {
  top: -16px;
  left: 40px;
  width: 32px;
  height: 0;
  transform: rotate(30deg);
}

.button__drow2 {
  top: 44px;
  left: 77px;
  width: 32px;
  height: 0;
  transform: rotate(-127deg);
}

.button__drow1::before,
.button__drow1::after,
.button__drow2::before,
.button__drow2::after {
  content: "";
  position: absolute;
}

.button__drow1::before {
  bottom: 0;
  left: 0;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-60deg);
}

.button__drow1::after {
  top: -10px;
  left: 45px;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(69deg);
}

.button__drow2::before {
  bottom: 0;
  left: 0;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-146deg);
}

.button__drow2::after {
  bottom: 26px;
  left: -40px;
  width: 0;
  height: 32px;
  border-radius: 16px;
  transform-origin: 16px 16px;
  transform: rotate(-262deg);
}

.button__drow1,
.button__drow1::before,
.button__drow1::after,
.button__drow2,
.button__drow2::before,
.button__drow2::after {
  background: var(--back_color);
}

.button-c:hover .button__drow1 {
  animation: drow1 ease-in 0.06s;
  animation-fill-mode: forwards;
}

.button-c:hover .button__drow1::before {
  animation: drow2 linear 0.08s 0.06s;
  animation-fill-mode: forwards;
}

.button-c:hover .button__drow1::after {
  animation: drow3 linear 0.03s 0.14s;
  animation-fill-mode: forwards;
}

.button-c:hover .button__drow2 {
  animation: drow4 linear 0.06s 0.2s;
  animation-fill-mode: forwards;
}

.button-c:hover .button__drow2::before {
  animation: drow3 linear 0.03s 0.26s;
  animation-fill-mode: forwards;
}

.button-c:hover .button__drow2::after {
  animation: drow5 linear 0.06s 0.32s;
  animation-fill-mode: forwards;
}

@keyframes drow1 {
  0% {
    height: 0;
  }

  100% {
    height: 100px;
  }
}

@keyframes drow2 {
  0% {
    width: 0;
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  11% {
    opacity: 1;
  }

  100% {
    width: 120px;
  }
}

@keyframes drow3 {
  0% {
    width: 0;
  }

  100% {
    width: 80px;
  }
}

@keyframes drow4 {
  0% {
    height: 0;
  }

  100% {
    height: 120px;
  }
}

@keyframes drow5 {
  0% {
    width: 0;
  }

  100% {
    width: 124px;
  }
}


.button-c:not(:last-child) {
  margin-bottom: 64px;
}

.container-c {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top:20px;
}

.details-img-god {
  height: auto;
  width: 70px;
  margin-right:15px;
}

.sec-cat {
  width: 30%;
}

.sec-cat input {
  padding: 0 !important;
}




:where(.css-dev-only-do-not-override-ed5zg0).ant-drawer .ant-drawer-header {
  border-bottom: 0;
}

.tile-design-city .tiles-design {
  background-image: url(../../assets/images/mandala_top.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
}

/* .ant-drawer-close::after {
  content: "\00AB";
  font-size: 40px;
  color: white;
  line-height: 10px;
} */


.ant-select-selection-item {
  line-height: 24px;
}

.grid-style {
  border: 1px solid #e7e7e7;
  border-collapse: collapse;
  border-radius: 5px;


}

.grid-style tr td,
.grid-style tr th {
  padding: 10px 10px !important;
}

.grid-style .ant-pagination {
  margin: 10px 0 !important;
}


.menu-link {
  display: block;
  text-decoration: none;
}

.menu-item {
  text-align: center;
  padding: 10px;
  transition: background 0.3s ease, color 0.3s ease;
}

/* .menu-item:hover {
  background: #ddd;
   color: #340785 !important; 
} */

.menu-item-active {
  background: var(--colororange3) !important;
  color: white;
}

.menu-item-active:hover {
  background: rgb(159 54 0 / 46%) !important;
  color: white;
}

.with-share-btn {
  display: flex;
  flex-direction: row;
}



.with-share-btn-left {
  margin-left: 20px;
}

.ant-form-item .ant-form-item-label>label::after {
  visibility: hidden;
}

.weather-card .pub-on {
   font-size: var(--fontsize1);
  color: #cbcbcb;
  text-shadow: 1px 1px 1px #000;
  margin-top: 2px;
}

.weather-card .art-sub {
  float: left;
  margin-left: 15px;
  font-size: 10px;
  color: #ffffff;
  border: 1px solid #b9b9b9;
  padding: 2px 10px;
}

.weather-card .tile-quicklink1 {
  float: left;
  padding-right: 10px;

  justify-content: space-around;
  height: 20px;
  width: 100%;
}

.weather-card .tile-quicklink1 .share-button1 {
  float: left;
  margin-left: 30px;

}

.weather-card .tile-quicklink1 i {
  color: white;
}


.weather-card {
  margin: 10px auto;
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 38px rgba(0, 0, 0, 0.15), 0 5px 12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.weather-card .top {
  position: relative;
  height: 180px;
  width: 100%;
  overflow: hidden;

  background-size: cover;
  background-position: center center;
  text-align: center;

}

.weather-card .top .wrapper {

  position: relative;
  z-index: 1;
  height: 100%;
}

.weather-card .top .wrapper .mynav {
  height: 20px;
}

.weather-card .top .wrapper .mynav .lnr {
  color: #fff;
   font-size: var(--fontsize3);
}

.weather-card .top .wrapper .mynav .lnr-chevron-left {
  display: inline-block;
  float: left;
}

.weather-card .top .wrapper .mynav .lnr-cog {
  display: inline-block;
  float: right;
}

.weather-card .top .wrapper .heading2 {
   font-size: var(--fontsize2);
  font-weight: 400;
  color: #fff;
  text-align: left;
  padding: 0 15px;
  margin: 10px 0;
}

.weather-card .top .wrapper .heading2 .face-icon {
  height: 30px;
  width: 30px;
  background-color: #fbca61;
  display: block;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
}

.weather-card .top .wrapper .location {

   font-size: var(--fontsize2);
  font-weight: 400;
  color: #fff;
  line-height: 22px;
  padding: 10px;
  text-align: left;
  word-break: break-word;
  min-height: 50%;
  cursor: pointer;
}

.weather-card .top .wrapper .author-sec {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.weather-card .top .wrapper .temp {
  margin-top: 20px;
}

.weather-card .top .wrapper .temp a {
  text-decoration: none;
  color: #fff;
}

.weather-card .top .wrapper .temp a .temp-type {
  font-size: 85px;
}

.weather-card .top .wrapper .temp .temp-value {
  display: inline-block;
  font-size: 85px;
  font-weight: 600;
  color: #fff;
}

.weather-card .top .wrapper .temp .deg {
  display: inline-block;
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  vertical-align: top;
  margin-top: 10px;
}

.weather-card .top:after {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.weather-card .bottom {
  padding: 0 15px;
  background: #fff;
  height: 60px;
}

.weather-card .bottom .wrapper {
  height: 100%;
}

.weather-card .bottom .wrapper .forecast {
  height: 100%;
}

.weather-card .bottom .wrapper .forecast {
  overflow: hidden;
  margin: 0;
  font-size: 0;
  padding: 0;

}

.weather-card .bottom .wrapper .forecast a {
  text-decoration: none;
  color: #000;
}

.weather-card .bottom .wrapper .forecast .go-up {
  text-align: center;
  display: block;
  font-size: 25px;
  margin-bottom: 10px;
}

.weather-card .bottom .wrapper .forecast li {
  display: block;
  font-size: 25px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1em;
  height: 100%;
}

.weather-card .bottom .wrapper .forecast li .date {
  display: inline-block;
  word-break: break-all;
   font-size: var(--fontsize2);

  height: 100%;
  overflow: hidden;
  color: #7a7a7a;
  padding-top: 10px;
}

.weather-card .bottom .wrapper .forecast li .condition {
  display: inline-block;
  vertical-align: middle;
  float: right;
  font-size: 25px;
}

.weather-card .bottom .wrapper .forecast li .condition .temp {
  display: inline-block;
  vertical-align: top;
  font-family: var(--fontenglish);
   font-size: var(--fontsize3);
  font-weight: 400;
  padding-top: 2px;
}

.weather-card .bottom .wrapper .forecast li .condition .temp .deg {
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  margin-left: 3px;
  vertical-align: top;
}

.weather-card .bottom .wrapper .forecast li .condition .temp .temp-type {
   font-size: var(--fontsize3);
}

.weather-card .bottom .wrapper .forecast li.active {
  color: rgba(0, 0, 0, 0.8);
}

.weather-card .youtube-vdo-title {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.weather-card .youtube-vdo-wrapper {
  position: absolute !important;
  top: 0px;
  background: linear-gradient(1deg, #00000099, transparent);
  width: 100%;
}

.weather-card .youtube-vdo-icon {
  top: 37% !important;
}

.weather-card .showmore {
    display: block;
    height: 60px;
    background-size: cover;
    background-position: bottom;
    position: absolute;
    bottom: 10px;
    width: calc(100% - 30px);
    overflow: hidden;
    margin-left: -15px;
}

.showmore1 {
  display: block;
  height: 100px;
  background: linear-gradient(360deg, #ffffff, transparent);
  position: absolute;
  bottom: 10px;
  width: calc(100% - 60px);
}

.weather-card .showmore::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/images/library_design.jpg) no-repeat;
  background-size: cover;
  background-position: bottom;
  filter: grayscale(100%); 
  z-index: 0; 
  opacity: 0.4
}


.weather-card .showmore button {
  margin: auto;
  display: block;
  margin-top: 20px;
  position: relative; 
  z-index: 1; 
}

.weather-card .showmore1 button {
  margin: auto;
  display: block;
  margin-top: 60px;
}

.weather-card.rain .top {
  background: url("http://img.freepik.com/free-vector/girl-with-umbrella_1325-5.jpg?size=338&ext=jpg") no-repeat;
  background-size: cover;
  background-position: center center;
}



.play-button-overlay {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
}

.play-button-overlay i {
   font-size: var(--fontsize2);
  color: red;
}

.tiles-design .card-img {
  position: relative;
}

label {
  font-weight: 500;
  font-family: var(--fontenglish);
}

.slider-btn {
  align-items: start;
}

.complaint-sec .ant-form-item-label {
  height: 30px;
}

.complaint-sec form div textarea {
  height: 250px !important;
}

.article-image {
  /* background: url("https://s-media-cache-ak0.pinimg.com/564x/cf/1e/c4/cf1ec4b0c96e59657a46867a91bb0d1e.jpg") no-repeat; */
  background: url(../../assets/images/article.jpg) no-repeat;
}

.books-image {
  background: url(../../assets/images/books_library.jpg) no-repeat;
}

.mantras-image {
  background: url(../../assets/images/mantras.jpg);
}

.audio-image {
  background: url(../../assets/images/audio.jpg);
}

.library-image {
  background: url(../../assets/images/library_design.jpg) no-repeat;
}

.videos-image {
  /* background: url("https://s-media-cache-ak0.pinimg.com/564x/cf/1e/c4/cf1ec4b0c96e59657a46867a91bb0d1e.jpg") no-repeat; */
  background: url(../../assets/images/video.jpg) no-repeat;
}

.livetv-image {
  /* background: url("https://s-media-cache-ak0.pinimg.com/564x/cf/1e/c4/cf1ec4b0c96e59657a46867a91bb0d1e.jpg") no-repeat; */
  background: url(../../assets/images/video.jpg) no-repeat;
}

.audio-image {
  /* background: url("https://s-media-cache-ak0.pinimg.com/564x/cf/1e/c4/cf1ec4b0c96e59657a46867a91bb0d1e.jpg") no-repeat; */
  background: url(../../assets/images/audio.jpg) no-repeat;
}

.horoscope-year-input {
  width: 150px;
}

.temple-details-temlplelink {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contribute-warning {
  color: rgb(254 105 0);
}

.showmore-deity{
  cursor: pointer;
}


/* .scrollable {
  max-height: 100%; 
  overflow: hidden; 
  position: relative;
  word-break: break-word;
}


.auto-scroll {
  animation: scroll-text 10s linear infinite; 
}


@keyframes scroll-text {
  0% {
    transform: translateY(0); 
  }
  100% {
    transform: translateY(-100%); 
  }
}


.scrollable:hover {
  animation-play-state: paused; 
} */

button.detailstxt-artical-icon {
  display: contents;
}

:where(.css-11lehqq).ant-input-affix-wrapper>input.ant-input,
:where(.css-11lehqq).ant-input-affix-wrapper>textarea.ant-input {
  font-size: inherit;
  border: none;
  border-radius: 5px;
  outline: none;
  background: transparent;
  color: #090909;
  /* line-height: 27px; */
  height: 33px;
  /* line-height: 50px; */
}

:where(.css-11lehqq).ant-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
   font-size: var(--fontsize2);
  line-height: 1.5714285714285714;
  border-radius: 6px;
  transition: all 0.2s;
  border-width: 1px;
  margin-top: 3px;
}

.panchang-icon2 {
  font-size: 40px;
  color: green;
}

.panchang-icon2-red {
  font-size: 40px;
  color: red;
}

.panchang-icon3 {
  font-size: 40px;

}


.grey-bg {
  background-color: #F5F7FA;
}

.weather-card .top-sec {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.panchang-main-sec>div.ant-form-item {
  border-radius: 4px;
  padding: 5px 10px;
  background-color: #ededed;
}

.weather-card .panchang-img {
  background-size: cover;
  background-position: center center;
  text-align: center;
  background: url(../../assets/images/panchangam.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  margin: 10px auto;
}

.weather-card .bottom-sec {
  padding: 0 15px;
  background: #fff;
}

.panchang-main-sec .ant-row.ant-form-item-row {
  flex-direction: column;
}

.rotate-180 {
  transform: rotate(180deg);
}

.horoscope-item h3 {
   font-size: var(--fontsize2);
}

.select-element1 {
  width: 15%;
}

span.ant-input-affix-wrapper.css-11lehqq.ant-input-outlined {
  height: 27px;
}



.image-float {
  margin-top: -140px;
  margin-bottom: -140px;
}


.home-mobile-sec {
  margin-top: 110px;
  margin-bottom: 100px;

}

.home-mobile-sec-mob-pic {
  height: 500px;
}

.bg-mob-app {
  background: linear-gradient(357deg, #2d1d15, #a9003e);
  color: white;
}

.app-links img {
  border-radius: 10px;
  height: 30px;
  margin-right: 20px;
  box-shadow: 2px 2px 9px #ddd;
}

.category-item {
  background: #f1f1f1FF;
  border: 1px solid #FBFBFB;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  text-align: center;
  padding: 5px 5px;
  margin: 5px 0;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  width: 16%;
}

.category-item .category-title {
  font-weight: 600;
   font-size: var(--fontsize2);
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #222222;
  margin-top: 20px;
}

.category-item i {

  font-size: 25px;
  margin-top: 10px;
}

.category-item:hover {
  transform: translate3d(0, -10px, 0);
  box-shadow: 0px 21px 44px rgba(0, 0, 0, 0.08);
  text-decoration: none;
}

.horoscope-home-sec {
  background-repeat: no-repeat;
  background-position: right bottom;
}

.horoscope-home-sec-bg1 {
  background-color: #3e4062;
  height: 100%;
  color:white;

  border: 10px solid transparent;
  padding: 10px;
  border-image: url(../../assets/images/border-design.png) 27 round;
}

.horoscope-home-sec-bg2 {
  background-color:#ffb60a;
  height: 100%;

  border: 10px solid transparent;
  padding: 10px;
  border-image: url(../../assets/images/border-design1.png) 27 round;
}

.horoscope-home-sec-bg-img1 {
  background: url(../../assets/images/ad-image-3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.horoscope-home-sec-bg-img2 {
  background: url(../../assets/images/ad-image-4.png);
  background-repeat: no-repeat;
  background-position: center;

  background-size: contain;
}

.nav-link {
  padding: .5rem .5rem;
}

.banner-content>div {
  width: 50%;
}

.horo-heading {
  display: flex;
}

.web-display {
  display: block;
}

.mob-display {
  display: none;
}

.sectiontext {
  font-size: 60px;
  font-weight: 900;
  line-height: 50%;
  color: #ddd;
}


.bd1-tempdetails {

  border-style: solid;
  border-width: 15px 10px 10px 10px;
  /* border-image: url(../../assets/images/bd1.png) 26 10 13 10 repeat repeat; */
  border-image-slice: 42 42 42 42;
  border-image-width: 16px 16px 16px 16px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: round round;
  border-image-source: url(https://mdn.github.io/css-examples/tools/border-image-generator/border-image-6.svg);
  border-style: solid;
  margin-top:20px;
}

.temp-details-imagestop {
  background-color: red;
  float: left;
  display: block;
  border-radius: 10px;
  margin: 20px;
  width: 90%;
  display: flex;
  height: 90%;
}

.horo-bg {
  background-image: url(../../assets/images/horo_bg.gif)
}

.temple2-bg {
  background-image: url(../../assets/images/tempbg2.jpg)
}



.service-item {
  padding: 0px 0px;
  transition: all ease-in-out 0.4s;
  height: 100%;
  width: 100%;
  margin-top: 0px;
}


.temple_icon_details_bottom {
  /* background-image: url(/static/media/temple_icon_details.42e03076.svg); */
  width: 45px;
  height: 36px;
  background-repeat: no-repeat;
  float: left;
  border-radius: 50%;
  /* background-color: #ddd; */
  background-size: 60px 60px;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  line-height: 45px;
margin-bottom:5px;

}


.temple_icon_details_bottom i {
   font-size: var(--fontsize3);
  color:#cba30d;
}


.info-item {
  padding: 10px 0px;
 
}

.info-item .icon {
  background-color: firebrick;
  padding: 10px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  margin-right: 10px;
  color: white;
  width: 30px;
  height: 30px;
}

.info-item h3 {
   font-size: var(--fontsize2);
  font-weight: 700;
  margin: 0 0 5px 0;
  color: #b22222;
  border-bottom: 1px solid #c5c5c5;
  width: 100%;
}

.info-item p {
  padding: 0;
  margin-bottom: 0;
   font-size: var(--fontsize2);
  display:none;
}

.temp-details-tiles{
  flex-wrap: wrap;
  width:100%;
 
  justify-content: space-between;
}

.temp-details-slider .slick-prev {
  left: 18px !important;
  top: 50% !important;
  z-index: 1;
}

.temp-details-slider .slick-next {
  right: 18px !important;
  top: 50% !important;
  z-index: 1;
}
.temp-details-slider .rightoutlined{
display: none;
}
.temp-details-slider .leftoutlined{
display: none;
}

.temp-details-slider .slick-prev:before,.temp-details-slider .slick-next:before {
  color: #ffffff;
  text-shadow: 3px 3px 6px #838383;

}
.mainsec-block-addtemple-detailspage-title-text1{
  display: flex;
}

.temp-detils-notifications{
  padding: 10px;
  background-color: #ffffffcf;

}

.temp-detils-notifications .events-block-link-title{
  margin-bottom: 5px;
    float: left;
    font-weight: 500;
}

.details-nearbytemples .tiles-design{
  width:200px;
}

.bold-text-line-popup B{
display: block;
margin-top: 15px;
margin-bottom: 5px;
}

.banner-ad { 

}


.banner-content .categories{
  font-size:18px;
font-weight: bold;
}

.ant-popover .ant-popover-content{
  border: 4px solid #f2f2f2;
  border-radius: 13px;

}

.details-headertxt-singleLine-btns {
  align-items: center;
  display: flex;
}

.goto-class{
background: #ff5600;
    /* border: 1px solid red; */
    border-radius: 16px;
    padding: 1px 8px;
    color: white;
}

.btn-icon-go{
width: 13px;
    height: 13px;
    font-size: 10px;
    align-items: center;
    text-align: center;
    line-height: 13px;
    border-radius: 50%;
}

.deity-img-sec{
margin-top:0;
}

.tiles-designs-events{
  padding: 6px;
  background-color: #f5f5f5bd;
  margin-bottom: 10px;
  /* box-shadow: 2px 2px 1px #ddd; */
  border-radius: 5px;
  border: 1px solid #e9e9e9;
}

.logo_tepleswiki{
  background-image: url(../../assets/images/templogo.png);
  height: 36px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}
























.new-prest .icon-cloudy:before {
  content: "\e60f";
  }
  .new-prest .icon-sun:before {
  content: "\e610";
  }
  .new-prest .icon-cloudy2:before {
  content: "\e611";
  }
  /************************************* END FONTS *************************************/
  
  
  .new-prest ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  }
  
  .new-prest h1 {
  font-size: var(--fontsize3);
  }
  
  .new-prest h2 {
  font-size: var(--fontsize2);
  }
  
  .new-prest p {
  font-size: var(--fontsize1);
  }
  
  .new-prest a {
  text-decoration: none;
  font-size: 15px;
  }
  .new-prest a:hover {
    text-decoration: underline;
  }
  
  /* .new-prest  h1, .new-prest h2, .new-prest p, .new-prest a, .new-prest span{
  color: #000;
  } */
  .new-prest  .scnd-font-color {
    color: #583706;
    margin-bottom:3px;
  }
  
  .input-container {
  position: relative;
  }
  .new-prest input[type=text]{
    width: 92%;
    height: 30px;
    margin-left: 4%;
    margin-bottom: 20px;
    padding-left: 45px;
    background: #ededed;
    color: #363636;
    border: solid 1px #757575;
    border-radius: 5px;		
    font-size: var(--fontsize1);
  }
  .prist-msg input[type=text]{
    width: 90%;
    height: 30px;
    margin-left: 16px;
    margin-bottom: 20px;
    padding-left: 15px;
    background: #ededed;
    color: #363636;
    border: solid 1px #757575;
    border-radius: 5px;		
  }
  .new-prest  input[type=text]::-webkit-input-placeholder { /* WebKit browsers */
         color: grey;	   		
       }
       .new-prest  input[type=text]:-moz-input-placeholder { /* Mozilla Firefox 4 to 18 */
         color: grey;	   
       }
      .new-prest input[type=text]::-moz-input-placeholder { /* Mozilla Firefox 19+ */
        color: grey;	
       }
       .new-prest input[type=text]:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: grey;		
       }
       .new-prest input[type=text]:focus {
         outline: none; /* removes the default orange border when focus */
         border: 1px solid #11a8ab;
       }
       .new-prest .input-icon {
    font-size: 22px;
    position: absolute;
    left: 31px;
    top: 10px;
  }
  .new-prest  .input-icon.password-icon {
      left: 35px;
    }
  
    .new-prest .horizontal-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  float: right;
  }
  .new-prest .horizontal-list li {
    float: left;
  }
  
  .new-prest .clear {
  clear: both;
  }
  
  .new-prest .icon {
  font-size: 25px;
  }
  
  .new-prest .titular {
  display: block;
  line-height: 40px;
  margin: 0;
  /* text-align: center; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  }
  
  .new-prest .button {
  display: block;
  width: 175px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  border-radius: 5px;
  transition: background .3s;
  color:#fff;
  }
  .new-prest .button:hover {
    text-decoration: none;
  }
  
  .new-prest .arrow-btn-container {
  position: relative;
  }
  .new-prest .arrow-btn {
    position: absolute;
    display: block;
    width: 60px;
    height: 60px;
    transition: background .3s;
  }
  .new-prest .arrow-btn:hover {
      text-decoration: none;
    }
    .new-prest .arrow-btn.left {
      border-top-left-radius: 5px;
    }
    .new-prest .arrow-btn.right {
      border-top-right-radius: 5px;
      right: 0;
      top: 0;
    }
    .new-prest .arrow-btn .icon {
      display: block;
      font-size: 18px;
      border: 2px solid #fff;
      border-radius: 100%;
      line-height: 17px;
      width: 21px;
      margin: 20px auto;
      text-align: center;
    }
    .new-prest  .arrow-btn.left .icon {
        padding-right: 2px;
      }
  
      .new-prest .profile-picture {
  border-radius: 100%;
  overflow: hidden;
  box-sizing: content-box;	
  }
  .new-prest .big-profile-picture {
    margin: 0 auto;		
    border: 5px solid #50597b;
    width: 100px;
    height: 100px;
  }
  .new-prest .small-profile-picture {
    border: 2px solid #50597b;
    width: 40px;
    height: 40px;
  }
  
  
  /** MAIN CONTAINER **/
  
  .new-prest .main-container {
  font-family: 'Ubuntu', sans-serif;
  width: 950px;
  height: 1725px;
  margin: 6em auto;
  }	
  /*********************************************** HEADER ***********************************************/
  .new-prest header {
    height: 40px;
  }
  .new-prest .header-menu {
      font-size: 17px;
      line-height: 80px;
    }
    .new-prest .header-menu li {
        position: relative;
            -webkit-transform: translateZ(0); /** To avoid a flash when hover messages **/
      }
      .new-prest  .header-menu-tab {
          padding: 0 27px;
          display: block;
          line-height: 34px;
          font-size: 17px;
          transition: background .3s;
        }
        .new-prest  .header-menu-tab:hover {
            background: #50597b;
            border-bottom: 4px solid #11a8ab;
            text-decoration: none;
          }
          .new-prest  .header-menu-tab .icon {
            padding-right: 15px;
          }
          .new-prest .header-menu-number {
          position: absolute;
          line-height: 22px;
          padding: 0 6px;
          font-weight: 700;
          background: #e64c65;
          border-radius: 100%;
          top: 15px;
          right: 2px;
          transition: all .3s linear;
        }
        .new-prest .header-menu li:hover .header-menu-number {
            text-decoration: none;
            transform: rotate(360deg);
  
          }
          .new-prest .profile-menu {
      float: left;
      height: 40px;
      padding-right: 20px;
    }
    .new-prest .profile-menu p {
        font-size: 17px;
        display: inline-block;
        line-height: 36px;
        margin: 0;
        padding-right: 10px;
      }
      .new-prest  .profile-menu a {
          padding-left: 5px;
        }
        .new-prest  .profile-menu a:hover {
            text-decoration: none;
          }
          .new-prest .small-profile-picture {
        display: inline-block;
        vertical-align: middle;				
      }
  /** CONTAINERS **/
  .new-prest .container {
    float: left;
    width: 300px;
  }
  .new-prest .block {
      margin-bottom: 15px;
      background: linear-gradient(225deg, #e3e3e3, #ffffff);
      border-radius: 5px;
      box-shadow: 1px 1px 7px #e3e3e3;
    }
    /******************************************** LEFT CONTAINER *****************************************/
    .new-prest .left-container {}
    .new-prest   .menu-box {
        /* height: 360px; */
      }
    
        .new-prest  .menu-box-menu .icon {
          display: inline-block;
          vertical-align: top;
          width: 28px;
          margin-left: 20px;
          margin-right: 15px;
        }
        .new-prest .menu-box-number {
          width: 36px;
          line-height: 22px;
          background: #ffeb86;
          text-align: center;
          border-radius: 15px;
          position: absolute;
          top: 8px;
          right: 15px;
          transition: all .3s;
          color: black;
          font-weight: bold;
        }
        .new-prest  .menu-box-menu li{
          height: 40px;
          position: relative;										
        }
        .new-prest .menu-box-tab {
          line-height: 40px;
          display: block;
          border-bottom: 1px solid #b9b9b9;
          transition: background .2s;
          color:#000;
        }
        .new-prest  .menu-box-tab:hover {
          background: #f6f8ff;
          border-top: 1px solid #1f253d;
            text-decoration: none;
          }
          .new-prest  .menu-box-tab:hover .icon {
            color: #fff;
          }
          .new-prest  .menu-box-tab:hover .menu-box-number {
            background: #e64c65;
          }
          .new-prest .donut-chart-block {
        height: 434px;
      }
      .new-prest  .donut-chart-block .titular {
          padding: 10px 0;
        }
        .new-prest  .donut-chart {
          height: 270px;
        }
        /******************************************
        DONUT-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
        ******************************************/
        .new-prest  .donut-chart {
            position: relative;
            width: 200px;
            height: 200px;
            margin: 0 auto 2rem;
            border-radius: 100%
           }
           .new-prest  p.center-date {
            background: #394264;
            position: absolute;
            text-align: center;
            font-size: 28px;
            top:0;left:0;bottom:0;right:0;
            width: 130px;
            height: 130px;
            margin: auto;
            border-radius: 50%;
            line-height: 35px;
            padding: 15% 0 0;
          }
          .new-prest  .center-date span.scnd-font-color {
           line-height: 0; 
          }
          .new-prest  .recorte {
              border-radius: 50%;
              clip: rect(0px, 200px, 200px, 100px);
              height: 100%;
              position: absolute;
              width: 100%;
            }
            .new-prest .quesito {
              border-radius: 50%;
              clip: rect(0px, 100px, 200px, 0px);
              height: 100%;
              position: absolute;
              width: 100%;
              font-family: monospace;
              font-size: 1.5rem;
            }
            .new-prest  #porcion1 {
              transform: rotate(0deg);
            }
  
            .new-prest  #porcion1 .quesito {
              background-color: #E64C65;
              transform: rotate(76deg);
            }
            .new-prest #porcion2 {
              transform: rotate(76deg);
            }
            .new-prest #porcion2 .quesito {
              background-color: #11A8AB;
              transform: rotate(140deg);
            }
            .new-prest  #porcion3 {
              transform: rotate(215deg);
            }
            .new-prest #porcion3 .quesito {
              background-color: #4FC4F6;
              transform: rotate(113deg);
            }
            .new-prest  #porcionFin {
              transform:rotate(-32deg);
            }
            .new-prest  #porcionFin .quesito {
              background-color: #FCB150;
              transform: rotate(32deg);
            }
        /******************************************
        END DONUT-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
        ******************************************/
        .new-prest  .os-percentages {
          padding-top: 36px;
        }
        .new-prest .os-percentages li {
          width: 75px;
          border-left: 1px solid #394264;
          text-align: center;					
          background: #50597b;
        }
        .new-prest   .os {
            margin: 0;
            padding: 10px 0 5px;
            font-size: 15px;		
          }
          .new-prest   .os.ios {
              border-top: 4px solid #e64c65;
            }
            .new-prest   .os.mac {
              border-top: 4px solid #11a8ab;
            }
            .new-prest .os.linux {
              border-top: 4px solid #fcb150;
            }
            .new-prest  .os.win {
              border-top: 4px solid #4fc4f6;
            }
            .new-prest  .os-percentage {
            margin: 0;
            padding: 0 0 15px 10px;
            font-size: 25px;
          }
          .new-prest  .line-chart-block {
        height: 400px;
      }
      .new-prest  .line-chart {
          height: 200px;
          background: #11a8ab;
        }
        /******************************************
        LINE-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
        ******************************************/
        .new-prest .grafico {
          padding: 2rem 1rem 1rem;
          width: 100%;
          height: 100%;
          position: relative;
          color: #fff;
          font-size: 80%;
        }
        .new-prest  .grafico span {
          display: block;
          position: absolute;
          bottom: 3rem;
          left: 2rem;
          height: 0;
          border-top: 2px solid;
          transform-origin: left center;
        }
        .new-prest  .grafico span > span {
          left: 100%; bottom: 0;
        }
        .new-prest [data-valor='25'] {width: 75px; transform: rotate(-45deg);}
        .new-prest  [data-valor='8'] {width: 24px; transform: rotate(65deg);}
        .new-prest [data-valor='13'] {width: 39px; transform: rotate(-45deg);}
        .new-prest [data-valor='5'] {width: 15px; transform: rotate(50deg);}
        .new-prest [data-valor='23'] {width: 69px; transform: rotate(-70deg);}
        .new-prest [data-valor='12'] {width: 36px; transform: rotate(75deg);}
        .new-prest [data-valor='15'] {width: 45px; transform: rotate(-45deg);}
  
        .new-prest [data-valor]:before {
          content: '';
          position: absolute;
          display: block;
          right: -4px;
          bottom: -3px;
          padding: 4px;
          background: #fff;
          border-radius: 50%;
        }
        .new-prest [data-valor='23']:after {
          content: '+' attr(data-valor) '%';
          position: absolute;
          right: -2.7rem;
          top: -1.7rem;
          padding: .3rem .5rem;
          background: #50597B;
          border-radius: .5rem;
          transform: rotate(45deg);  
        }
        .new-prest [class^='eje-'] {
          position: absolute;
          left: 0;
          bottom: 0rem;
          width: 100%;
          padding: 1rem 1rem 0 2rem;
          height: 80%;
        }
        .new-prest .eje-x {
          height: 2.5rem;
        }
        .new-prest .eje-y li {
          height: 25%;
          border-top: 1px solid #777;
        }
        .new-prest [data-ejeY]:before {
          content: attr(data-ejeY);
          display: inline-block;
          width: 2rem;
          text-align: right;
          line-height: 0;
          position: relative;
          left: -2.5rem;
          top: -.5rem;
        } 
        .new-prest .eje-x li {
          width: 33%;
          float: left;
          text-align: center;
        }
        /******************************************
        END LINE-CHART by @kseso https://codepen.io/Kseso/pen/phiyL
        ******************************************/
        .new-prest .time-lenght {
          padding-top: 22px;
          padding-left: 38px;
        }
        .new-prest  .time-lenght-btn {
            display: block;
            width: 70px;
            line-height: 32px;
            background: #50597b;
            border-radius: 5px;
            font-size: 14px;
            text-align: center;
            margin-right: 5px;
            transition: background .3s;
          }
          .new-prest  .time-lenght-btn:hover {
              text-decoration: none;
              background: #e64c65;
            }
            .new-prest .month-data {
          padding-top: 28px;
        }
        .new-prest  .month-data p {
            display: inline-block;
            margin: 0;
            padding: 0 25px 15px;            
            font-size: 16px;
          }
          .new-prest  .month-data p:last-child {
              padding: 0 25px;
              float: right;
              font-size: 15px;
            }
            .new-prest  .increment {
              color: #e64c65;
            }
            .new-prest  .media {
        height: 216px;
      }
      .new-prest  #media-display {
          position: relative;
          height: 180px;
          background: #787878 url('https://www.fancinema.com.ar/wp-content/uploads/catwoman1.jpg') center top;
        }
        .new-prest  #media-display .play {
            position: absolute;
            top: 75px;
            right: 32px;
            border: 2px solid #fff;
            border-radius: 100%;
            padding: 2px 5px 2px 9px;
          }
          .new-prest   #media-display .play:hover {
              border: 2px solid #e64c65;
          }
          .new-prest .media-control-bar {
          padding: 6px 0 0 15px;
        }
        .new-prest .media-btn, .time-passed {
            display: inline-block;
            margin: 0;					
          }
          .new-prest .media-btn {
            font-size: 19px;						
          }
          .new-prest   .media-btn:hover, .media-btn:hover span {
              text-decoration: none;
              color: #e64c65;
            }
            .new-prest  .play {
              margin-right: 100px
            }
            .new-prest  .volume {
              margin-left: 30px;
            }
            .new-prest   .resize {
              margin-left: 12px;
            }
            .new-prest .left-container .social {
        height: 110px;
      }
      .new-prest .left-container .social li {
          width: 75px;
          height: 110px;										
        }
        .new-prest .left-container .social li .icon {
            text-align: center;
            font-size: 20px;
            margin: 0;
            line-height: 75px;
          }
          .new-prest .left-container .social li .number {
            text-align: center;
            margin: 0;
            line-height: 34px;
          }
          .new-prest  .left-container .social .facebook {
            background: #3468af;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          .new-prest  .left-container .social .facebook .number {
              background: #1a4e95;
              border-bottom-left-radius: 5px;
            }
            .new-prest .left-container .social .twitter {
            background: #4fc4f6;						
          }
          .new-prest .left-container .social .twitter .icon {
              font-size: 18px;
            }
            .new-prest  .left-container .social .twitter .number {
              background: #35aadc;
            }
            .new-prest .left-container .social .googleplus {
            background: #e64c65;						
          }
          .new-prest  .left-container .social .googleplus .number{
              background: #cc324b;
            }
            .new-prest .left-container .social .mailbox {
            background: #50597b;						
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          .new-prest  .left-container .social .mailbox .number {
              background: #363f61;
              border-bottom-right-radius: 5px;
            }
    /************************************************** MIDDLE CONTAINER **********************************/
    /* .new-prest .middle-container {
      margin: 0 25px;			
    } */
    .new-prest .profile {
        /* height: 390px; */
        display: flex;
        flex-direction: column;
      }
      .new-prest  .add-button .icon {
          float: right;
          line-height: 18px;
          width: 23px;					
          border: 2px solid;
          border-radius: 100%;
          font-size: 18px;
          text-align: center;
          margin: 10px;					
        }
        .new-prest   .add-button .icon:hover {
            color: #e64c65;
            border-color: #e64c65;
          }
          .new-prest .user-name {
          margin: 0px 0 10px;
          text-align: center;
        }
        .new-prest  .profile-description {
          width: 210px;
          margin: 0 auto;
          text-align: center;
        }
        .new-prest .profile-options {
          padding-top: 5px;
          display: flex;
    justify-content: center;
        }
        .new-prest  .profile-options li {
            border-left: 1px solid #1f253d;
            width: 33.33%;
            height:100%;
          }
          .new-prest  .profile-options li i {
          font-size: 22px;
          }
          .new-prest  .profile-options p {
              margin: 0;
              height:100%;
             }
             .new-prest  .profile-options a {
              display: block;					
              width: 100%;
              line-height: 30px;
              text-align: center;
              transition: background .3s;		
              height:100%;		
            }
            .new-prest  .profile-options a:hover {
                text-decoration: none;
                background: #ffc67e;
              }
              .new-prest  .profile-options a:hover.comments .icon {
                color: #fcb150;
              }
              .new-prest    .profile-options a:hover.views .icon {
                color: #11a8ab;
              }
              .new-prest   .profile-options a:hover.likes .icon {
                color: #e64c65;
              }
              .new-prest  .profile-options .icon {
                /* padding-right: 10px; */
              }
              .new-prest  .profile-options .comments {
                border-top: 4px solid #fcb150;
              }
              .new-prest .profile-options .views {
                border-top: 4px solid #11a8ab;
              }
              .new-prest .profile-options .likes {
                border-top: 4px solid #e64c65;
              }
              .new-prest  .weather {
        height: 555px;
      }
      .new-prest.orangeflavour .weather .titular ,  .new-prest.orangeflavour .tweets .titular,   .new-prest.orangeflavour   .menu-box .titular {
          background: #e9c8b4;
        }
        .new-prest.orangeflavour .pt-activities-list li{
          border-bottom: 2px solid #ff9919;
        }
        .new-prest .weather .titular .icon {
            padding-right: 15px;
            font-size: 26px;
          }
          .new-prest .weather .current-day {
          height: 135px;
          background: #e64c65;
        }
        .new-prest  .weather .current-day p {
            margin: 0;
            padding-left: 50px;					
          }
          .new-prest   .current-day-date {
              font-size: 16px;
              padding-top: 16px;
            }
            .new-prest  .current-day-temperature {
              font-size: 70px;
            }
            .new-prest  .current-day-temperature .icon-cloudy {
                padding-left: 20px;
              }
              .new-prest .weather .next-days{}
              .new-prest  .weather .next-days p {
          margin: 0;
          display: inline-block;
          font-size: 16px;
        }
        .new-prest .weather .next-days a {
          display: block;
          line-height: 58px;
          border-bottom: 1px solid #1f253d;
          transition: background .3s;
        }
        .new-prest  .weather .next-days a:hover {
            background: #50597b;
          }
          .new-prest  .weather .next-days a:hover .day {
            color: #e64c65;
          }
          .new-prest .weather .next-days-date {
            padding-left: 20px;
          }
          .new-prest   .weather .next-days-temperature {
            float: right;
            padding-right: 20px;
          }
          .new-prest   .weather .next-days-temperature .icon {
              padding-left: 10px;
            }
            .new-prest  .new-prest .tweets {
        height: 375px;
      }
     
        .new-prest .tweets .titular .icon {
            font-size: 18px;
            padding-right: 20px;
          }
          .new-prest .tweet.first {
        
          border-bottom: 1px solid #1f253d;
        }
        .new-prest .tweet  {
            margin: 0;
            padding: 15px;
          }
          /* .new-prest .tweet p:last-child {
            margin: 0;
            padding: 15px 30px 0;
          } */
          .new-prest .tweet-link {
            color: #4fc4f6;
          }
          .new-prest .middle-container .social {
        height: 205px;
        background: #1f253d;
      }
      .new-prest .middle-container .social li {
          margin-bottom: 12px;
        }
        .new-prest .middle-container .social a {
          line-height: 60px;			
        }
        .new-prest   .middle-container .social a:hover {
            text-decoration: none;
          }
          .new-prest  .middle-container .social .titular {
            border-radius: 5px;
          }
          .new-prest  .middle-container .social .facebook {
              background: #3468af;
              transition: background .3s;
            }
            .new-prest .middle-container .social a:hover .facebook {
                background: #1a4e95;
              }
              .new-prest  .middle-container .social a:hover .icon.facebook {
                background: #3468af;
              }
              .new-prest .middle-container .social .twitter {
              background: #4fc4f6;
              transition: background .3s;
            }
            .new-prest .middle-container .social a:hover .twitter {
                background: #35aadc;
              }
              .new-prest  .middle-container .social a:hover .icon.twitter {
                background: #4fc4f6;
              }
              .new-prest  .middle-container .social .googleplus {
              background: #e64c65;
              transition: background .3s;
            }
            .new-prest  .middle-container .social a:hover .googleplus {
                background: #cc324b;
              }
              .new-prest  .middle-container .social a:hover .icon.googleplus {
                background: #e64c65;
              }
              .new-prest  .middle-container .social .icon {
          float: left;
          width: 60px;
          height: 60px;
          text-align: center;
          font-size: 20px;
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }
        .new-prest  .middle-container .social .icon.facebook {
            background: #1a4e95;						
          }
          .new-prest  .middle-container .social .icon.twitter {
            background: #35aadc;						
          }
          .new-prest  .middle-container .social .icon.googleplus {
            background: #cc324b;						
          }
    /********************************************* RIGHT CONTAINER ****************************************/
    .new-prest .right-container {}
      .join-newsletter {
        /* height: 230px; */
      }
      .new-prest .join-newsletter .titular {
          /* padding-top: 10px; */
        }
        .new-prest .subscribe.button {
          background: #11a8ab;
          margin-top: 10px;
        }
        .new-prest .subscribe.button:hover {
            background: #0F9295;
          }
          .new-prest  .account {
        height: 390px;
      }
      .new-prest .account .titular {
          padding: 10px 0;
        }
        .new-prest  .sign-in.button {
          background: #e64c65;
          margin: 10px auto;
        }
        .new-prest .sign-in.button:hover {
            background: #cc324b;
          }
          .new-prest  .account p { 
          text-align: center;
        }
        .new-prest  .fb-sign-in {
          margin-top: 28px;
          display: block;
          line-height: 50px;
          background: #3468af;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          transition: background .3s;
        }
        .new-prest  .fb-sign-in:hover {
            background: #1a4e95;
            text-decoration: none;
          }
          .new-prest .fb-sign-in .icon {	
          line-height: 20px;
          font-size: 12px;
          padding-right: 3px;
        }
        .new-prest  .fb-border {
            display: inline-block;
            width: 23px;
            line-height: 20px;
            border: 2px solid #fff;
            border-radius: 100%;
            margin-right: 10px;
          }
          .new-prest .loading {
        height: 200px;
        padding-top: 35px;
      }
      .new-prest  .loading p {
          display: inline-block;
          padding-left: 30px;
          margin: 5px 0 20px;
        }
        .new-prest .loading .icon {
            padding-right: 15px;
          }
          .new-prest .loading .percentage {
            float: right;
            padding: 6px 35px 0 0;
          }
          .new-prest  .loading .progress-bar {
          width: 250px;
          height: 20px;
          background: #50597b;
          border-radius: 5px;
          margin: 0 auto;
        }
        .new-prest  .progress-bar.downloading { /* Chrome10+,Safari5.1+ */ /* IE10+ */
            background: linear-gradient(to right, #11a8ab 81%,#50597b 81%); /* W3C */
          }
          .new-prest .progress-bar.uploading { /* Chrome10+,Safari5.1+ */ /* IE10+ */
            background: linear-gradient(to right, #4fc4f6 43%,#50597b 43%); /* W3C */
          }
      .new-prest .calendar-day {
        height: 320px;
        background: #3468af;
      }
      .new-prest .calendar-day .titular {
          background: #1a4e95;
        }
        .new-prest  .calendar-day .arrow-btn:hover {
            background: #16417E;
          }
          .new-prest .calendar-day .the-day {
          margin: 0;
          text-align: center;
          font-size: 146px;
        }
        .new-prest .add-event.button {
          background: #4fc4f6;
        }
        .new-prest  .add-event.button:hover {
            background: #35aadc;
          }
          .new-prest  .calendar-month {
        height: 380px;
      }
      .new-prest .calendar-month .titular {
          background: #3468af;
        }
        .new-prest  .calendar-month .arrow-btn:hover {
            background: #1a4e95;
          }
          .new-prest .calendar {
          margin: 22px 15px;
          text-align: center;
        }
        .new-prest   .calendar a {
            font-size: 17px;
          }
          .new-prest  .calendar td, .calendar th {
            width: 40px;
            height: 38px;						
          }
          .new-prest .calendar .days-week {
            color: #4fc4f6;
          }
          .new-prest .calendar .today {
            display: block;
            width: 34px;
            line-height: 34px;
            background: #e64c65;
            border-radius: 100%;
          }
          .margin-auto{
            margin:auto;
          }

          .pt-activities-list{

          }
          .pt-activities-list li{
           
            float: left;
            width: 100%;
            padding: 13px 13px;
          }
          .pt-activities-list li:hover{
            background-color: #ddd;
          }
          .pt-activities-list li:active{
           font-weight: bold;
          }
           .pt-activities-list li h6{
            font-size: var(--fontsize1);
           
          }
           .pt-activities-list li h6 i{
            font-size: 20px;
            margin-right: 7px;
          }
  

          .gradient-y-overlay-lg-dark::before {
            background-image: linear-gradient(to bottom, rgb(62 55 0 / 38%) 40%, rgba(12 13 14 / 100%) 100%);
            background-repeat: repeat-x;
            content:'';
            width: 100%;
            height: 150px;
        }
        

          .text-white {
            color: #fff !important;
        }

        .pl-2, .px-2 {
          padding-left: .5rem !important;
      }
      
      .pr-2, .px-2 {
          padding-right: .5rem !important;
      }
      .mb-3, .my-3 {
          margin-bottom: 1rem !important;
      }

          .card {
            position: relative;
            display: flex
        ;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: .0625rem solid rgba(231, 234, 243, .7);
            border-radius: .75rem;
        }
          /* .min-h-270rem {
            min-height: 16.875rem;
        } */
        .bg-img-hero {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
        }
        .transition-zoom-hover {
          transform: scale(1);
          transition: transform .3s;
      }
  

      .transition-zoom-hover:focus, .transition-zoom-hover:hover {
        transform: scale(1.05);
    }

    .rounded-lg-pseudo {
      border-radius: .75rem;
  }
  
  .overflow-hidden {
      overflow: hidden !important;
  }


      .left-0 {
        left: 0;
    }
    
    .bottom-0 {
        bottom: 0;
    }
    .right-0 {
        right: 0;
    }
  
  
    .centered-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 40vh; 
    }
    

    /* input.ant-input.css-11lehqq {
      margin-top: 3px;
  } */
  
  button, input, optgroup, select, textarea {
    color: inherit;
    font: initial;
    margin: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
  filter:saturate(0)
}

.btn-shine.detailpagebtn {
  transform: none;
  margin-left: 15px;
  height: 18px;
  line-height: 10px;
}



.temples-search-icon img{
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 20px;
  width: 130px;
  border-radius: 50%;
  border: 2px solid #ddd;
  box-shadow: 2px 2px 6px #101010;
  background: linear-gradient(45deg, #05a8d9, #1b42498c);
}

/* Main icon styling (circle with background gradient) */
.temple-search-icon1{
  display:none;
}
.temple-search-icon1 img {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 20px;
  width: 60px;
  border-radius: 50%;
  border: 2px solid #ddd;
  box-shadow: 2px 2px 6px #101010;
  background: linear-gradient(45deg, #05a8d9, #1b42498c);
  cursor: pointer;
}

/* Container for the two icons appearing on click */
.icons-container {
  position: absolute;
  bottom: 160px; /* Position above the main icon */
  left: 20px;
  display: flex; /* Align items in a row */
  grid-gap: 15px;
  gap: 15px;
  opacity: 0; /* Hidden initially */
  pointer-events: none; /* Prevent clicks */
  transition: opacity 0.3s ease-in-out;
}

/* Show the icons when the showIcons state is true */
.icons-container.show {
  opacity: 1;
  pointer-events: all;
}

/* Icon animations: scale and fade-in */
.icon-animation {
  width: 70px;
  height: auto;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s ease-out;
}

/* Animation for the first icon */
.icons-container.show .first {
  transform: scale(1);
  opacity: 1;
}

/* Animation for the second icon with delay */
.icons-container.show .second {
  transform: scale(1);
  opacity: 1;
  transition-delay: 0.2s;
}




.map-btns{
  top: -130px;
}

.map-btns button{
  padding: 10px;
  border: 1px solid #c5c5c5;
  margin-left: 10px;
  background: white;
  display: flex;
  align-items: center;
}
.map-btns button i{
  font-size: 20px;
  line-height: 20px;
  color: #666666;
  position: relative;
  top: 2px;
  margin-right: 7px;
  }
  .map-btns button span{
    font-size: 16px;
    line-height: 20px;
  }

  .f-9{
    font-size:9px
  }










  .search-home-sec{
    margin-bottom:30px;
  }
    .search-image{
      background-image: url(../../assets/images/mandalaart.png);
      /* background-image: url(../../assets/images/homepage_pandith.png); */
      height: 100px;
      width: 100px;
      /* float: left; */
      display: inline-block;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      background-position: right;
      bottom: 0px;
  
  }
  .search-home-txt{
  font-size: 18px;
      /* text-align: center; */
      width: 70%;
      /* margin: auto; */
      position: relative;
  
  }
  .search-home-txt2{
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #8f8d8d;
    margin-left: 10px;
    position: absolute;
    top:10px;
  }
  .search-home{
    width:70%;
    position: relative;
    margin:auto;
  }
  .home-search-input{
    width: 100%;
    border-radius: 5px;
    border: 1px dashed #e5e5e5;
    /* border-image-slice: 13 13 13 13; */
    /* border-image-width: 13px 13px 13px 13px; */
    /* border-image-outset: 13px 13px 13px 13px; */
    /* border-image-repeat: repeat repeat; */
    /* border-image-source: url(https://mdn.github.io/css-examples/tools/border-image-generator/border-image-4.png); */
    border-style: solid;
    padding: 10px;
    padding-right: 80px;
    box-shadow: 10px 10px 10px #ddd;
  }
  .home-search-input-icon-sec{
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    height: 100%;
    align-items: center;
  }
  .home-search-input-icon-sec i{
    font-size: 20px;
  }
  .home-search-input-icon-sec i.i-search{
    background: #f49a03;
    color: white;
    padding: 10px;
    border-radius: 4px;
    margin-right: 4px;
    font-size: 15px;
    margin-left: 5px;
  }
  .home-search-input-icon-sec i.i-map{
    padding: 8px;
    color: #f49a03;
  }
  
  .animation-text, .animation-text ul, .animation-text li, .animation-text ul.mask {
    height: 25px;
  }
  .animation-text li {
    font-size: 16px;
    line-height: 25px;
    text-align: left;
  }
  
  
  .animation-text {
    display: inline-block;
    vertical-align: top;
    text-align: center;
  
    overflow: visible;
    white-space: nowrap;
    color: lightgray;
    position: relative;
    top: 0px;
    margin-left: 5px;
  
    font-family: var(--fontenglish);
  }
  
  
  /* .animation-text li {
    position: relative;
    animation: cycle-5-items 5sease-in-out alternate infinite;
    font-size: 25px;
    height: 30px;
    line-height: 30px;
  } */
  
  .animation-text ul {
    list-style: none;
    padding: 0 .5ex;
    margin: 0;
  
    overflow: hidden;
  }
  
  .animation-text li {
    /* font-family: "Kaushan Script", serif; */
    position: relative;
    animation: cycle-5-items 5s ease-in-out alternate infinite;
    color:#f47203;
  }
  
  
  .animation-text ul.mask {
    color: black;
    overflow: hidden;
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 1;
  
  }
  
  
  @keyframes cycle-5-items {
    0%, 10% {
      transform: translateY(0%);
    }
    
    15%, 35% {
      transform: translateY(-100%);
    }
    
    40%, 60% {
      transform: translateY(-200%);
    }
    
    65%, 85% {
      transform: translateY(-300%);
    }
    
    90%, 100% {
      transform: translateY(-400%);
    }
  }

  .btn-tt{
    font-size: var(--fontsize1) !important;
    color: #f37c06 !important;
  }

  .social-icon1{
    cursor: pointer;
  }

  .social-icons-main a img{
    width: 60px;
    height: 60px;
    border:1px solid #ffc04d;
    border-radius: 10px;
    box-shadow: 10px 10px 10px #bdb597a3;
    margin: auto;
    display: block;
    background-color: #ffefdf;
    }
    .social-icons-main-sec {
      width: 100%;
      overflow: auto;
    }
    .social-icons-main {
        white-space: pre;
        overflow: auto;
        /* width: 1050px; */
    padding-bottom: 14px;
    height: 100px;
    overflow: hidden;
  }
.social-icons-main a{
  display: block;
  text-align: center;
  margin-top: 6px;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 600;
  float: left;
  width: 130px;
}

.social-icons-main span{
  display: block;
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 600;
  width: 96%;
  float: left;
  }
.divider-d1{
  margin: auto;
  display: block;
  height: 17px;
  width: 400px;
  opacity: .6;
  margin-top:20px
}
  
  
.icon-container {
  display: inline-flex;
  grid-gap: 4px;
  gap: 4px; /* Adds spacing between icons */
}

.icon-color {
  animation: changeColor 2s infinite linear;
}

.icon-color.delay1 {
  animation-delay: 0.3s;
}

.icon-color.delay2 {
  animation-delay: 0.6s;
}

@keyframes changeColor {
  0% { color: #b22222; }
  50% { color: white; }
  100% { color: #b22222; }
}

.blinking-dot {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  box-shadow: 0 0 5px red;
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.popup-main-search {
  height: 555px;
  background: #fff;
  width: 600px;
  margin-bottom: 10px;
  border-radius: 10px;
  border-radius: 30px 30px #858585;
}


  .popup-main-header-search {
    display: flex
;
    justify-content: space-between;
    padding: 5px 10px;
    background: #ffbe00;
    /* background-color: var(--colororange3); */
    position: relative;
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
}

.popup-main-container-search {
  background-image: linear-gradient(359deg, white, #ffffffa6);

  width: 97%;
  height: calc(100% - 95px);
  box-sizing: border-box;

  margin: auto;
  position: relative;
  overflow: auto;
}
.declineButton-search i{
font-size: 25px;
    color: #cfcfcf;
    text-align: center;
    width: 100%;
}

.declineButton-search {
  background-color: #f3f3f3;
  transition-duration: .2s;
  color: rgb(96 96 96);
  border: none;
  cursor: not-allowed;
  font-weight: 500;
  border-radius: 5px;
  /* box-shadow: 0 4px 6px -1px #d8a4a4, 0 2px 4px -1px #bebdbd; */
  box-shadow: 0 2px 2px -1px #d8a4a4;
  transition: all .6sease;
  white-space: nowrap;
  margin-right: 0px;
  padding: 0px 8px;
  font-size: var(--fontsize1);
  line-height: 22px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 3px 10px;
  margin-left: 10px;
  font-family: var(--fontenglish);
}

/* .h3, h3 {
  font-size: 17px !important;
  line-height: 38px !important;
  margin-bottom: 0 !important;
} */


.popup-back-search {
  display: none;
  background: var(--colororange3);
  width: 30px;
  text-align: center;
  position: absolute;
  float: right;
  top: -36px;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  left: 10px;
  color: white;
  border-radius: 10px;
} 


  
.btn-shine {
 cursor: pointer;
  transform: translate(-50%, -50%);
  padding: 2px 6px;
  color: #fff;
  background: linear-gradient(to right, #ff9900 0, #fff 10%, #ff8100 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  text-decoration: none;
  white-space: nowrap;
  border: 1px solid #ffa500;
  border-radius: 8px;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
 
  100% {
    background-position: 180px;
  }
}

.stamp-border {
  /* Change this value to adjust the stamp perforation size: */
  --radius: 8px;

  background-image: 
    radial-gradient(
      var(--radius),
      transparent 98%,
      white
    ),
    linear-gradient(white 0 0);
  background-repeat: 
    round, 
    no-repeat;
  background-position:
    calc(var(--radius) * -1.5) calc(var(--radius) * -1.5),
    50%;
  background-size:
    calc(var(--radius) * 3) calc(var(--radius) * 3),
    calc(100% - var(--radius) * 3) calc(100% - var(--radius) * 3);
}

.txt-orange{
  color: #c75402;
  font-weight: bold;
}
.txt-orange1{
color: #ff972c;
}

.bg-btns-icon{   
  padding: 2px;
  /* width: 260px; */
  border-radius: 50%;
  /* height: 24px; */
  position: absolute;
  padding: 0px 6px;

  right: 9px;
  z-index: 1;
}
.bg-btns-icon .main-gopuram{  
  font-size: 18px;
  color: #fff;
  text-shadow: 0 1px 2px #8f8f8f;
}
.bg-btns-icon .main-icon{  
  font-size: 20px;
  border-radius: 50%;
  color: #f0470c;
  padding: 2px;
  position: relative;
  left: -10px;
  top: -1px;
  text-shadow: 0px 0px 1px #8d8d8d;
}
.bg-btns-icon a{
margin:0 5px;
}

.searchBlock {
  border: 1px dashed #ffb100;
  margin: 1%;
  padding: 1%;
  margin-top: 0px;
  position: relative;
  border-radius: 10px;
  box-shadow: 2px 2px 6px #ddd;
  background-color: white;
  float: left;
  width: 98%;
  margin-bottom: 8px;
}
.searchBlock h6{

  /* background: #ffffff; */
  color: #000000;
  /* padding: 0px 0px 10px 0; */
  left: -27px;
  font-size: 14px;
  width: 100%;
  line-height: normal;
}
  
.top-border-search
{
  background-image: url(../../assets/images/top-border.png);
  height: 29px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display:none;
}
.top-border-search2
{
  background-image: url(../../assets/images/h1.png);
  height: 11px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat; 
  background-position: center;
  opacity: .35;
  display:none;
}

.main-search{
  height: calc(100% - 73px);
  overflow: auto;
  margin: 0 0px;
  /* border: 1px solid #faad14; */
  border-radius: 10px;
  background-color: #fff;
  padding-top: 5px;
}

  
.popup-main-container-serch{
  background-image: linear-gradient(359deg, white, #ffffffa6);
  width: 97%;
  height: calc(100% - 95px);
  box-sizing: border-box;
  margin: auto;
  position: relative;
  overflow: auto;
}
 
.searchindia{
  background-image: url(../../assets/images/india.jpg);

  height: 45px;
  width: 45px;
  background-size: contain;
  border-radius: 20px;
  float: left;
  
}
.searchcat{
  background-image: url(../../assets/images/seearchcat.jpg);

  height: 45px;
  width: 45px;
  background-size: contain;
  border-radius: 20px;
  float: left;
  
}


.searchall{
  background-image: url(../../assets/images/seearch2.jpg);

  height: 45px;
  width: 45px;
  background-size: contain;
  border-radius: 20px;
  float: left;
  
}

.searchmap{
  background-image: url(../../assets/images/seearchmap.jpg);

  height: 45px;
  width: 45px;
  background-size: contain;
  border-radius: 20px;
  float: left;
  
}
.img_search_sec{
float: left;
    width: calc(100% - 55px);
    margin: 0 10px;
}

.img_search_sec2{
  float: left;
  width:440px;
      margin: 0 10px;
  }


  
.resource-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.resource-row {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; /* Prevents wrapping to the next line */
  overflow-x: auto; /* Enables horizontal scrolling if content overflows */
  grid-gap: 0px;
  gap: 0px; /* Adds spacing between items */
  padding-bottom: 10px;
}

/* .tiles-design {
  flex: 0 0 auto; 
  width: 250px; 
} */

.bg-library{
  background-repeat: no-repeat;
  background-size: 45px;
  background-position: center bottom;
}

.mobileapp-topsec
{
  text-align: center;
  margin: auto;
  justify-content: center;
  margin-top: 10px;
  position: absolute;
  right: 0;
  top: 8px;
}  

.orange {
  color: #ff972c !important;
}

@keyframes slideDown {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.popup-main-notifimodal-container {
  position: fixed;
  top: -100px; /* Start off-screen */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px; /* Adjust as needed */
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0;
  animation: slideDown 0.8s ease-out forwards; /* Apply animation */
}

.bg-highlight-tempdetail{
background: #ffe8c6;
padding: 5px 15px 0px;
border-radius: 10px;
}

.horoscope-item .bold-text-line-popup div,
.horoscope-item .media-body span, .horoscope-item .ant-typography {
  font-size: var(--fontsize1);
  font-weight: 400;
}
.tiles-main .resource-section {
  width: 24%;
  border-radius: 10px;
  margin-left: .5%;
  margin-right: .5%;
  border: 1px dashed orange;
  background-image: url(../../assets/images/mandaladesign.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 80%;
}
.tiles-main .resource-section:nth-child(1) .tiles-designs{
  background:linear-gradient(180deg, #ffebeb, transparent);
  padding-top: 10px;
  margin-top: 0px;
} 
.tiles-main .resource-section:nth-child(2) .tiles-designs{

  background: linear-gradient(180deg, #ffd7a3, transparent);
  padding-top: 10px;
  margin-top: 0px;

} 
.tiles-main .resource-section:nth-child(3) .tiles-designs{
  background: linear-gradient(180deg, #ffeca3, transparent);
  padding-top: 10px;
  margin-top: 0px;
} 
.tiles-main .resource-section:nth-child(4) .tiles-designs{
  background: linear-gradient(181deg, #feffc6, transparent);
  padding-top: 10px;
  margin-top: 0px;
}  

.features .features-item i {
  font-size: 16px;
  padding-right: 10px;
  line-height: 0;
  width:23px;
  text-align: center;
  margin-left:5px;
}

.features .features-item h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}

.features .features-item h3 a {
  color: grey;
  transition: 0.3s;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.stretched-link {
  color: #cba30d;
  font-weight: 600;
  display: block;
  font-size: var(--fontsize1);
  position: relative;
  top: 2px;
}
.features .features-item {
  border-radius: 10px;
  background-color: var(--surface-color);
  display: flex
;
  align-items: center;
  padding: 5px;
  transition: 0.3s;
  box-shadow: 3px 3px 3px #ddd;

  position: relative;
}

.features .features-item:hover {
  border-color: var( --colorheadingv2);
}

.features .features-item:hover h3 a {
  color: var(--colororange3);
}

.temples-search-icon{
  display: none;
}

.indian-flag{
  
  background-image: url(../../assets/images/indiaflag.jpg);
  width: 17px;
  height: 12px;
  background-size: contain;
  margin-right: 6px;
}

.temple-form .tiles-designs{
padding:0px 0 ;
}


/* Hide the browser's default radio button */
.custom-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-input .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}


.custom-input:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-input input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-input .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-input input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-input .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.custom-input .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff4d4f;
  border-color: #ff5600;
}
.custom-input .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff4d4f;
  border-color: #ff5600;
}
.custom-input .ant-checkbox-wrapper:hover  .ant-checkbox-inner {
  background-color: #ff4d4f;
  border-color: #ff5600;
}
.custom-input .ant-checkbox-wrapper span, .ant-form-item-explain-error{
  font-family: var(--fontenglish) !important;
}

.temp-image{

}

.addTemp .info-item {
  background: 
color-mix(in srgb, var(--default-color), transparent 96%);
  padding: 10px;
}

.addTemp .info-item h3 {
  font-size: var(--fontsize1) !important;
  font-weight: 600;
  margin: 0 0 5px 0;
  color: #b22222;
  border-bottom: 0px solid #c5c5c5;
  width: auto;
  position: relative;
  top: 2px;
  float: left;
  margin-left: 20px;
  padding:0;
}
.addTemp .info-item p {
  display: block !important;
}
.addTemp .info-item  {
  width:100%;
  float:left;
}

.addTemp .info-item i {
  display: block;
  font-size: 28px;
  line-height: 28px;
  color: #f49d03;
  float: left;
}
.addTemp .active-details{
  background: none;
}

.addTemp .ant-form-item-label{
  /* display:none; */
  padding-bottom: 0;
}

.btn-shine3 {
  cursor: pointer;
  /* transform: translate(-50%, -50%); */
  padding: 4px 6px;
  color: #585757;
  /* background: linear-gradient(to right, #ff9900 0, #fff 10%, #ff8100 20%); */
  background-position: 0;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  /* animation: shine 3s infinite linear; */
  /* animation-fill-mode: forwards; */
  /* -webkit-text-size-adjust: none; */
  text-decoration: none;
  white-space: nowrap;
  border: 1px solid #ffa500;
  border-radius: 8px;
  font-size: var(--fontsize1);
  font-family: var(--fontenglish);
  height: 27px;
}

.m-m-at{
  /* margin:30px 0; */
}

.resent-viewlist .title-img-bg{
display:none;
}
.resent-viewlist{
justify-content: left;
}
.resent-viewlist .tile-text-temple-cat{
width:198px;
  }



  .resent-viewlist .tile-text-temple-cat span:nth-child(1){
padding:2px;
  }
.at_title{
  background: #ffebc7;
  float: left;
  width: 100%;
  padding: 5px 10px;
  border-bottom: 1px solid #ffc55a;
}

.home_btns2{
padding:5px;
}

.horoscope-item .media-body h3{
color:#bb9a92;
}
.event-details .tiles-designs-event .ant-form-item-control-input-content 
{
display: flex;
    flex-direction: column;
    background: #ebebeb;
    padding: 0 10px;
    border-radius: 10px;
    margin-right: 10px;
    box-shadow: 2px 1px 3px #b5b5b5;
    margin-top:10px;
} 

.event-details .tiles-designs-event .ant-form-item-control-input-content .title {
  color: #c75402;
}

.login_msg3 {
  color: #b83c0d;
  font-family: var(--fontenglish) !important;
  margin-top: 10px;
  font-weight: 400;
  background: #b3b3b35c;
  padding: 5px;
   font-size: var(--fontsize1);
  border-radius: 5px;
  text-align: center;
}

.Toastify__toast-icon{
  font-family: var(--fontenglish) !important;
  font-size: var(--fontsize1);
}

.login-or {
  clear: both;
  float: left;
  width: 100%;
  margin: auto;
  text-align: center;
}
.login-or span {
  font-size: 14px;
  background: white;
  position: relative;
  padding: 0 14px;
  text-transform: capitalize;
  color: #c5946a;
}

.login-or::before{
  content: "";
  display: block;
  border: 1px solid #ddd;
  width: 100%;
  position: relative;
  top:12px;
}
.ft-lt{
  float:left;
}
.clear-both{
  clear: both;
}

.phone-pic{
  background-image: url(../../assets/images/mobile.png);

}

.source-login {
  float: left;
  width: 100%;
  display: flex
;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.gmail-pic{
  background-image: url(../../assets/images/google.png);

}

.source-login-link{
  width: 30px;
  height: 30px;
  background-size: contain;
  /* border: 1px solid red; */
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  background-color: #fdf0be;
  box-shadow: 2px 2px 4px #ddd;
  cursor: pointer;
}
  
  @media (max-width: 1250px) {

 

    .mobileapp-topsec{
      display:none;
    }

    .category-item:hover {
      transform: none;
   }
   .social-icons-main{
     display:flex;
     grid-gap:20px;
     gap:20px;
   }

    .category-item:hover {
       transform: none;
    }
    .social-icons-main{
      width:700px;
    }
    .search-home-txt2{

      margin-left: 0px;
    }

    .social-icons-main a {

      width: 86px;
    }

    .social-icons-main span {
      display: none;
    }
    .gradient-y-overlay-lg-dark::before {
    
      height: 120px;
  }

  .search-image {

    left: -58px;
  }

  .search-home {
    width: 100%;
  }
  
/*   
    .animation-text {
      top: 0px;
      margin-left: 10px;
      }
  
    .animation-text, .animation-text ul, .animation-text li, .animation-text ul.mask {
      height: 35px;
    }
    .animation-text li {
      font-size: 28px;
      line-height: 35px;
    }
    
    .search-home-txt2 {
  
      font-size: 20px;
    }
    .search-home-txt{
      font-size: 25px;
          width: 100%;
          margin-top: 10px;
          margin-bottom: 5px;
      }
      .search-home{
        width:100%;
      }
  

  .temples-search-icon img {

    z-index: 1;
    bottom: 70px;
    left: 0px;
    width: 100px;
    border-radius: 50%;
    border: 4px solid #ddd;
  
  } */



  .temp-details-tiles{
    flex-wrap: wrap;
    width:100%;
   grid-gap:0 2% ;
   gap:0 2% ;
    justify-content: start;
  }

  .temple_icon_details-icons-pos {
    width: 23%;
    background: #fffef6b5;
  }

  .temple-details-map {
    width: 40%;

  }

  .map-details {
    height: 60vh;
    /* Set the same height as modal */
  }

  .search-icon-mobile {
    display: none;
  }

 


  .library-icons {
    /* display: flex; */
  }

  .slider-txt {
    bottom: 80px;
  }

  /* .tiles-main{
    grid-template-columns: auto auto auto auto;
  
  } */
}

@media (max-width: 1024px) {


  .my-custom-select {
    width: 15%;
  }
}


@media (max-width: 992px) {

  .m-m-at {
    margin: 10px 0;
}

  .tiles-main .resource-section {
    width: 48%;
    border-radius: 10px;
    margin-right: 1%;
    padding: 1%;
    border: 1px solid #ffb100;
    margin-left: 1%;
}

  .grp-descr-img {
    height: auto;
  width:100%;
  border:0;
  }
  

  .detaipage-moretemp-align{
    flex-direction: column;
  }
 

  .btn-shine.detailpagebtn {
    text-align: center;
    margin-left:0;
}
  

  .popup-back-search {
    display: flex;
  }

  .horoscope-home-sec-bg1 p, .horoscope-home-sec-bg2 p, .horoscope-home-sec-bg1 a, .horoscope-home-sec-bg2 a{
    display: none;
      }

  .logo_tepleswiki{
    background-image: url(../../assets/images/templogo.png);
    height: 30px;
    width: 120px;
  }

  .category-item .category-title{
margin-top:0px;
padding:2px;
  }

  .temple_detailsbg {
    background-position: bottom right;
    background-size: cover;

  }

  .sectiontext {
    font-size: 50px;
    font-weight: 700;
    line-height: 50%;
    color: #ddd;
  }

  .livetv {
    height: 140px;
    margin-bottom:20px;
  }

  .banner-content {
    flex-direction: column;
  }

  .horoscope-home-sec-bg1 {
    height: auto;
  }

  .banner-content>div {
    width: auto;
    text-align: center;
  }

  .horoscope-home-sec-bg-img1,
  .horoscope-home-sec-bg-img2 {
    height: 100px;
  }

  .web-display {
    display: none;
  }

  .mob-display {
    display: block;
  }

  .horo-icons-main {
    flex-direction: column;
  }


  .horo-heading {
    flex-direction: column;
  }

  .details-icons {

    grid-gap: 0px;

    gap: 0px;
  }

  .category-item {
    margin-right: 0px;
    width: auto;
  }

  .home-mobile-sec-mob-pic {
    height: 250px;
  }

  .home-mobile-sec {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .image-float {
    margin-top: 0px;
    margin-bottom: 0px;
  }


  .mainsec-block-single-buttons-detailspage {
    height: calc(100% - 154px);
  }

  .slider-btn {
    align-items: center;
  }

  .play-button-overlay {
    width: 45px;
    height: 45px;
  }

  .weather-card {
    margin: 10px auto;
  }

  .weather-card .top {
    height: 150px;
  }

  .weather-card .bottom {
    padding: 0 15px;
    background: #fff;
    height: 61px;
  }

  .weather-card .bottom .wrapper .forecast li .date {
    height: 80px;
  }


  .with-share-btn-header {
    width: 74%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mob-search-row {
    flex-direction: row;
  }

  .sec-cat {
    width: 250px;
  }

  .popup-main-login {
    height:96% ;
    /* background-image: url(../../assets/images/bg-login2.jpg); */
    background-repeat: repeat-y;
    background-size: contain;
  }

  .header_star i {
     font-size: var(--fontsize2);
  }

  .header_star {
    margin-bottom: 10px;
  }

  .details-info-txt-label-mob-view {
    display: none;
  }

  .details-img-god {
    width: 70px;
  }
/* 
  .add-w-control {
    width: 100%;
  } */

  .details-info-txt-value {
 
    margin-left: 0px;
  }

  .details-info-txt {
    
  }

  .deityname {
    /* width: calc(100% - 150px); */
    /* overflow: hidden; */
  }

  .tempDetails-w {
    width: 105%;
    flex-direction: column;
    margin-left: -2%;
  }

  .search-icon-web {
    display: none;
  }

  .temp-details-secheader-btnsec {
    flex-direction: column;
  }

  .popup-main-container-artical {
    height: calc(100% - 134px);
  }

  .article-image {
    /* width: 100%;
    height: 180px; */
    background-size: contain
  }

  .popup-main-header-artical {
    /* flex-direction: column; */

  }



  .mainsec-block-horo-detailspage1 {
    height: calc(100% - 196px);
  }


  .mainsec-block-panchang-detailspage {
    height: calc(100% - 179px);
  }

  .mainsec-block-horo-detailspage1 .tiles-designs label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .zs-icon-top {
    height: 16px;
    width: 16px;
    margin-top: 2px;
  }

  .zodaic-logo-main .names {
    padding: 0px;
     font-size: var(--fontsize1);
  }

  .details-icons-main-horoscope-tile {
    flex-direction: column;
  }

  .details-icons-main-horoscope {
    flex: auto;
  }

  .zodaic-logo-main {
    height: 35px;
    width: 16.65%;

  }

  .details-icons i {
     font-size: var(--fontsize2);
  }

  .buttons-left-right-jumping {
    float: left;
  }

  .calendar-wrapper {
    position: relative;
    display: none;
  }

  .popup-main-artical {
    width: 96%;
    height: calc(100% - 50px);
  }

  .popup-main-filter {
    width: 96%;
    height: calc(100% - 50px);
  }

  .popup-main-search {
    width: 96%;
    height: calc(100% - 50px);
  }

  /* .dialog-content-text-articaltxt-articalmain-data {
    max-height: 600px;
  } */



  .add-temple-img {
    width: 50px;
  }

  .breadcrumb-link {
    display: none;
  }

  .mob-nav {
    display: flex;
  }

  .tiles-designs .empty-label .ant-form-item-label {
    display: none;
  }

  .tiles-designs.tiles-designs-no-bg>div.ant-form-item {
    background-color: transparent;
    margin-bottom: 20px;
  }

  .bannerbutton span {
    line-height: 24px;
  }

  .popup-login-main {
    width: 86%;
  }

  .popup-main-container-login {
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .bannerbutton {
    padding: 2px 12px;
  }

  .bannerbutton .svg-wrapper {
    width: 15px;
    height: 15px;
  }

  .popup-bg {

    align-items: end;
  }

  .popup-main {
    height: calc(100% - 50px);
  }

  .popup-main-login {
    height: 96%;
    /* background-image: url(/static/media/bg-login2.9d355281.jpg); */
    background-repeat: repeat-y;
    background-size: contain;
  }

  
  .popup-back {
    display: flex;
  }

  .popup-close {
    display: none;
  }

  .popup-close-search{
    display: none;
  }
  .popup-main {
    width: 96%;
  }

  .tiles-designs .ant-form-item-control {
    min-height: auto;
  }

  .resource-row {
    flex-direction: column; 
    align-items: center; 
  }

  .mainsec {
    height: calc(100% - 131px);
  }

  .main-content {
    height: calc(100% - 131px);

  }

  .main-content-home-search {
   display:none

  }
  .main-content-home {
    height: calc(100% - 97px);

  }


  .today-horo-text {
    display: block;
  }

  .main {
    height: calc(100% - 110px);
  }

  .ignore-styles {
    font-size: 18px !important;
    line-height: 18px;
  }

  .lib-block {
    width: 70px;
  }

  .lib-sec {
    margin-top: 0px;
    /* margin-bottom: 10px; */
    width:100%;
  }

  .lib-sec-img img {
    margin-top: -55px;
  }

  .bg-lib-sec {
    height: 35px;
  }

  .footer-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 55px;
    background-color: white;
    width: 100%;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: grey;
    z-index: 10;
  }

  .content {
    flex: 1 1; /* Pushes footer to the bottom */
  }


  .footer-mobile>a span {
   
     font-size: 14px;
     font-weight: 500;
     position: relative;
     top: 4px;
  }

  .footer-mobile>a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-mobile i {
     font-size: var(--fontsize3);
  }

  .footer-mobile>a {
     font-size: var(--fontsize1);
    font-weight: 500;
    text-decoration: none;

    color: grey !important;
  }

  .footer-mobile>a:hover {
    text-decoration: none;
  }

  .footer-mobile>a.active-nav {
     font-size: var(--fontsize1);
    font-weight: 500;
    text-decoration: none;

    color: var(--colororange3) !important;
  }

  .ham {
    /* height: 45px; */
    flex-direction: column;
  }

  .search-mobile {
    display: flex;
    height: 40px;
    background: #dddddd;
  }

  .mainmenu {
    display: none;
  }

  .detailpage-img {
    display: none;
  }

  .temple-details-map {
    width: 50%;
    /* Adjust the width for large screens */
  }

  .map-details {
    height: 60vh;
    /* Set the same height as modal */
  }

  .cta.container {
    padding: 20px;
  }

  .d-flex.flex-column.justify-content-start {
    margin-top: 9%;
  }

  .mainsec-block-detailspage {
    height: calc(100% - 130px);
  }

  .temple-details-icon-sec {

    background: #ffffff4f;
  }

  .mainsec-block-addtemple-detailspage {
    height: calc(100% - 135px);
  }

  .mainsec-block-addtemple-detailspage2 {
    height: calc(100% - 80px);
  }

  .img-fluid {
    height: 130px !important;
  }

  #hero .btn-get-started {
     font-size: var(--fontsize1);
    padding: 1px 8px 1px 8px;
  }

  /* .card-text-title {
     font-size: var(--fontsize2);
    flex-direction: column;
  } */

  #hero h1 {
     font-size: var(--fontsize1);
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 400;
  }

  #hero h2 {
    color: #f5ccff;
    margin: 10px 0 11px 0;
     font-size: var(--fontsize2);
    font-weight: 300;
  }

  .details-icons-list span {
     font-size: var(--fontsize1);
    cursor: pointer;
    white-space: pre;
    color: black;
  }

  .details-icons-list {
    margin-right: 8px;
  }

  .names span {
    cursor: pointer;
  }

  .details-icons {

    overflow: auto;
  }

  .details-headertxt-singleLine-btns {
    margin-top: 7px;
    margin-bottom: 3px;
    overflow: auto;
    width: 100%;

  }

  .details-headertxt-singleLine {
    text-align: left;
    flex-direction: column;
    align-items: baseline;
    padding: 0px 10px 10px 10px;
  }

  .details-headertxt {
    text-align: left;
    flex-direction: column;
    align-items: baseline;
    padding: 0px 10px 10px 10px;
  }

  .header_txt {
    text-overflow: ellipsis;
    width: 94%;
    overflow: hidden;
    font-weight: 500;
    margin-top: 7px;
    margin-bottom: 5px;
     font-size: var(--fontsize2);
  }

  #scroll-container {
    height: 150px;
  }

  /* .tiles-main{
    grid-template-columns: auto auto auto;
  
  } */

  .bg-details-sec1 {
    width: 99%;
  }

  .bg-details-sec2 {
    width: 99%;
  }

  .bg-details-sec3 {
    width: 83%;
  }

  .bg-details {
    flex-direction: column;
  }

  .search_banner,
  .aside-block {
    width: 100%;
  }

  .aside-block {
    height: 150px;
  }

  .loop-wrapper {
    height: 190px;
  }

  .slider-text-two h2 {
    font-size: 30px;
  }

  .slider-text-two h4 {
     font-size: var(--fontsize3);
  }

  .slider-txt {
    bottom: 40px;
  }

  .card-style {
    margin: 2px 10px;
  }

  .search-breadcrumb {
    padding: 6px;
  }

  .search-breadcrumb aside {
    margin-bottom: 4px;
  }

  .nav-topbanner {
    width: 100%;
    overflow: auto;
  }

  .nav-icon-main span {
     font-size: var(--fontsize2);
  }

  .search-bar {
    min-width: 250px;
  }


  .tiles-designs>div.ant-form-item>div.ant-row {
    flex-direction: column;
  }
/* 
  .tiles-designs>div.ant-form-item {
    border-radius: 4px;
    padding: 10px 10px;

    background-color: #ededed;
  } */



  .mountain {
    bottom: -50px;
  }

  .hill {
    position: absolute;

    width: 200px;
    border-radius: 50%;
    height: 10px;
  }

  .tree {
    height: 40px;
    width: 40px;
  }

  .tree2 {
    height: 40px;
    width: 40px;
  }

  .rock {
    height: 1%;
    width: 1%;
  }

  .breadcrumb-link {
    display: none;
  }

}


@media (max-width: 786px) {

  .submenu-dropdown {
    position: fixed !important;
    top: 153px !important; 
    width: 100% !important;
  }
  .img_search_sec2 {
    width: calc(100% - 142px);
}

  .title-img-bg.templescatbg {
    width: 100%;
    height: 100px;
    background-size: cover;
  }

  .weather-card .showmore {
    height: 60px;
    width: 91.5%;
    margin-left: -15px;
}

  .temple_icon_details-icons-pos {
    width: 31%;
}


  #hero .img-fluid {

    height: 105px !important;

  }

  .user-pic {
    width: 80px;
    height: 80px;
  }


  .timming-grid tr td:nth-child(1) {
    width: 40%;
  }

  .search-city {
    width: 100%;
    margin-right: 0%;
  }


  .dialog-buttons-footer {
    display: block;
  }

  .notification-header h4 {
    display: flex;
    align-items: center;
  }

  .notification-header h4 span {
    margin-right: 10px;
    /* Adjust as needed */
  }

  .notification-header button {
    margin-left: auto;
  }

  .my-link1:hover::after {
    /* content: "Notifications"; */
    top: 40px;
    left: 75%;
  }

  .custom-popover {
    /* max-width: 250px; */
  }

  .cta .content h3 {
    font-size: 2rem;
  }

  .custom-popover .popover-content {
    max-height: 300px;
  }


  .ant-btn-primary>span {
     font-size: var(--fontsize1);
  }

  .ant-popover.custom-popover.css-dev-only-do-not-override-ed5zg0.css-dev-only-do-not-override-ed5zg0.ant-popover-placement-bottomRight {
    /* margin-left: 105px; */
    padding: 10px;
    /* top: 17px; */
    margin-top: -9px;
  }

  a.btn.btn-warning.shadow-0.yellowbtn {

    font-size: small !important;
  }


  .custom-popover .notification-header h4 {
     font-size: var(--fontsize1);
    /* margin-left: -9px; */
  }

  .custom-popover .notification-button {
    background-color: transparent;
    color: #606060;
    border: none;
    padding: 0;
     font-size: var(--fontsize1);
    cursor: pointer;
    transition: color 0.3s;
    margin-right: 22px;
    margin-top: 3px;
  }

  .d-flex.align-items-center.back-arrow {
    margin-top: 10px;
  }

  .diag-close {
    position: absolute;
    top: 9px;
    right: 10px;
    cursor: pointer;

  }

  /* button.ant-btn.css-dev-only-do-not-override-ed5zg0.ant-btn-primary.login-form-button {
  width: 90%;
 height: 15%;
} */

  .custom-popover .notification-header h4 {
     font-size: var(--fontsize1);
  }

  .custom-popover .notification-header a {
    font-size: 10px;
  }

  .ant-popover-content {
    /* margin-left: 25%; */
    width: 100%;
  }

  /* a.btn.btn-outline-secondary {
    width: 98px;
  } */

  .ant-popover-inner {
    /* width: 73%; */
  }

  .initials-placeholder {
    height: 57px !important;
    width: auto;
    padding: 23px !important;
    margin: 2px;
  }

  .initials-placeholder h5 {
    font-size: 0.8rem;
    margin: 1px !important;
  }




  .language {
    flex-direction: column;
    /* Stack items vertically */
    align-items: flex-end;
    /* Align items to the end */
    padding-right: 0;
    /* Remove right padding */
    margin-left: 5px;
    /* Remove left margin */
    width: 50px;
    /* Set width to auto or adjust as needed */
  }

  .language .ant-dropdown-trigger {
    margin-right: 0;
    /* Remove margin-right */
    margin-top: 10px;
    /* Adjust margin-top */
  }

  span.ant-select-arrow {
    margin: -3px;
  }


  .label {
    display: inline-block;
    /* Other default styles */
  }

  .form-box {
    height: calc(100% - 80px);
    overflow: auto;

  }

  .dialog-buttons-login {
    width: 97%;
    display: flex;
    margin: auto;
  }

  .detail-container-image img {
    height: 60px !important;
    width: 100% !important;
  }


  .dialog-content {
    height: calc(100% - 100px);
  }




  .ant-modal-body {
    height: calc(100% - 106px);
    overflow: auto;
    padding: 0;
    height: 100%;
  }

  .ant-modal {
    width: 100%;
    transform-origin: 324px 19px;
    top: 10px;
    height: 98%;
  }

  .ant-modal .ant-modal-content {
    /* height: 100%; */
    width: 100%;
    height: 100%;

  }



  .background-blur {
    filter: blur(5px);
    transition: filter 0.3s ease-in-out;
  }


  iframe {
    height: 30vh;
  }

  .events-icon {
    margin-left: 10px;
  }

  .btn-secondary {
    padding-right: 3px !important;
    padding-left: 3px !important;
    margin-right: 4px;
  }

  .btn-secondary i {
    display: none;
  }

  .bg-details-sec2 {
    align-items: center;
  }

  .tiles-main {
    justify-content: space-evenly;
  }


  .card-img-temple-cat {
    width: 142px;
    height: 100px;
  }

  .bg-details-sec3 {
    min-height: 120px;
  }

  .bg-details-sec3 {
    width: 99%;
  }

  .cal-top span {
     font-size: var(--fontsize1);
  }

  .calendar-block {
    width: 100px;
    margin-right: 10px;
  }


  .ball {
    display: none;
  }

  .day-up,
  .day-down {
    height: 20px;
  }




  /* .tiles-main{
    grid-template-columns: auto auto;
  
  } */

  .search_banner {
    padding-top: 30px;
  }

  .caroselPic img {
    /* height: 180px; */
  }

  .flowers_top {
    height: 30px;
    background-size: contain;
  }

  .search-breadcrumb aside {
     font-size: var(--fontsize2);
  }

  .hero-img {
    height: calc(100% - 20px);
    margin-top: 20px;
  }

  .search-bar {
    min-width: 160px;
  }

  .my-custom-select {
    width: 50%;
  }

  .custom-modal {
    width: 80%;
    padding: 15px;
    max-height: 70vh;
  }

  .ant-modal {
    /* max-height: 60vh;  */
    width: 90%;
    /* Adjust width for mobile */
    top: 20px;
    /* Move it a bit higher on the screen */
  }

  .ant-modal-body {
    max-height: 50vh;
    /* Adjust the height of the modal body */
    overflow-y: auto;
    /* Enable scrolling if content exceeds height */
  }

  .tiles-designs {
    background-color: #f9f9f99e;
  }

  .resource-section  .tiles-designs {
    background-color: transparent;
  }

  .typ {
     font-size: var(--fontsize2);
    padding: 8px 0;
  }


  .horoscope-item {
    display: block;
    /* Stack the calendar and form vertically on mobile */
  }

  .active-details {
    display: block;

    /* Form fields stack on mobile */
    /* padding: 10px; */
  }

  .col-form,
  .col-calendar {
    width: 100%;
    /* Both form and calendar take full width on mobile */
    margin: 0;
    padding: 0;
  }

  .col-calendar {
    margin-top: 20px;
    /* Add some space between the form and calendar */
  }

  .react-calendar {
    width: 100%;
    /* Calendar takes full width on mobile */
    padding: 15px;
    /* Reduce padding for smaller screens */
  }

  .tiles-designs {
    margin-bottom: 0px;
  }

  .react-calendar__navigation button {
     font-size: var(--fontsize1);
    padding: 6px;
    /* Adjust button padding */
  }

  .react-calendar__tile {
     font-size: var(--fontsize1);
    padding: 12px;
    /* Adjust tile padding */
  }

  .search-form {
    width: 100%;
  }

  .example-suggestions {
    margin-top: 10% !important;
    width: 73%;
  }

  .search_button1 {
    margin-top: -10%;
  }

  .search-width {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .select-element,
  .select-element1 {
    width: 40%;
  }

  .button-sec {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

}


@media (max-width: 600px) {
  :root {
    --fontsize1:    15px;
    --fontsize2:    17px;
    --fontsize3:    19px;
  }

  .divider-d1 {
    width: 90%;
  }
  .cat-txt {
    padding: 0 10px;
  }
  
  .activate-btn {
    font-size: 10px; /* Slightly smaller font size on small screens */
  }

  .details-nearbytemples .tiles-design {
    width: 100%;
}
  .card-img-container {
    height: 100px;
  }

 

  /* a.button_move {
    margin-top: -5%;
    height: 2%;
  }

  .search-width {
    width: 90%;
    gap: 3px;
  } */

  .button_move1{
    font-size: 9px;
  }

  .resource-search {
    width: 98.5%;
    /* padding: 0px;

     font-size: var(--fontsize2);
   
    width: 80%;
    margin-left: 5%; */
  }

  .search-width {
    width: 291px;
  }

  .ant-modal-body {
    height: 100%;
    margin-top: -50%;
    /* margin-bottom: 50%; */
  }

  :where(.css-dev-only-do-not-override-ed5zg0).ant-modal {
    top: 20px;
    height: 96%;
    padding: 0;
  }

  .dialog-content {
    padding: 0 15px;
    height: calc(100% - 100px);
    overflow: auto;
  }

  :where(.css-dev-only-do-not-override-ed5zg0).ant-modal .ant-modal-content {
    height: 50%;
  }

  .ant-modal-body {
    height: 83%;
    margin-top: 50%;
  }


  .youtube-video {
    width: 100% !important;
  }

  .resource-search {
    /* margin-right: 13px;
    border-radius: 20px;
    height: 30px;
    width: 20vw;
    margin-bottom: 5px; */
  }

  .ant-table-wrapper {
    overflow-x: auto;
  }

  .ant-table {
    border-collapse: collapse;
    width: 100%;
  }

  .ant-table td,
  .ant-table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    /* Adjust the max-width as needed */
    /* border: 2px solid black;  */
    padding: 8px;
    /* Adjust padding as needed */
  }

  .ant-table th {
    background-color: #f0f0f0;
    /* Optional: Add background color to header cells */
  }

  form.search-form {
    width: 100%;
  }



  .ant-space-item {
    margin-left: 10px;
  }

  span.ant-input-suffix {
    padding: 5px;
  }

  .custom-popover {
    width: 100%;
    max-height: 100%;
    left: 0 !important;
  }

  .ant-drawer-content-wrapper {
    width: 90% !important;
  }

  /* .tiles-main{
    grid-template-columns: auto;
  
  } */
  .tiles-design {
    margin: 3px;
    background-repeat: no-repeat;
        background-size: 45px;
        background-position: center bottom;
        width:100%;
  }

  .tileswidth .tiles-design {
    width: 100%;
}

  /* .tiles-design a>div {
    display: flex;
  } */

  .tiles-design .card-img {
    height: 100px;
    width: 100px;
    float: left;
  }
  .resource-section {
    width: 100%;
  }
  .bg-library>div>a{
    background: none !important  }

  .tiles-design>div>a {
    display: flex;
    background: white;
    width: 100%;
    overflow: hidden;
  }

  .tiles-design .tile-text {
    width: 70%;
    /* display: flex; */
    flex-direction: column;
    /* height: 80px; */
    justify-content: space-between;
    flex: 1 1;
    float: left;
  }

  .tiles-design .tile-text1 {
    /* width: 70%; */
    /* display: flex; */
    flex-direction: column;
    /* height: 80px; */
    justify-content: space-between;
    flex: 1 1;
    float: left;
    margin-left: 5px;
  }

  .cat-detail {
    margin: 1px 3px 3px;
    padding: 10px 10px;
  }
}

@media (max-width: 468px) {


  .home-mobile-sec{
    display:none;
  }
  .resent-viewlist .tile-text-temple-cat {
    width: 100%;
}
  .custom-modal {
    width: 70%;
    max-height: 35vh;
    padding: 10px;
     font-size: var(--fontsize2);
  }

  .label {
    /* Display only the first letter */
    width: 1ch;
    /* Width to accommodate one character */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* Ensures text is truncated with ellipsis if needed */
  }


  .span.ant-select-selection-item {
    width: 1ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .livetv {
    width: 90%;
  }

  .all-buttons {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    width: 90%;
    margin-left: 81px !important;
    margin-top: 25px;
  }

  .line {
    width: 90vw;
    margin-top: 10% !important;
  }

  .det-image {
    /* width: 45vw; */
    height: 160px;
  }

  .deta-image {
    width: 150vw;
    height: 30vh;
  }

  .tmple-name p {
    width: 100%;
    font-size: 10px;
    /* margin-top: -14px; */
    margin-bottom: 5px;
  }

  .details-style h2 {
    font-size: small;
    margin-bottom: 5px;
  }

  .edit-button {
    font-size: small;
    width: 35%;
    align-items: self-start;
  }

  .tiles-design-temple-cat {

    width: 43%;
  }

  .card-img-temple-cat {
    width: 100%;
  }

  .tiles-main>a {
    width: 43%;
    margin: 10px;
  }

  .tiles-main>a .tiles-design-temple-cat {
    margin: 0;
    width: 100%;
  }

  .bg-details-sec2 {
    flex-direction: column;
  }

  .calendar-content {
    display: flex;
    width: 100% !important;
    border-radius: 10px;
    overflow:hidden;
  }

  .calendar-block {
    width: 100%;
    margin-right: 0px;
  }

  .cal-top {
    flex: 1 1;
  }

  .cal-bottom.fold-in {
    height: 76px;
    border-radius: 0px;
    color: #341333;
    box-shadow: inset 10px -10px 20px #838282;
    width: 70px;
  }

  .day-down {
    color: #212529;
  }

  .day-up,
  .day-down {
    background: var(--colororange3);
    width: 60px;
    height: 77px !important;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .day-up i,
  .day-down i {

     font-size: var(--fontsize3);
  }

  .year-txt {
    margin-top: 9px;
  }

}

@media screen and (orientation:landscape)
and (min-device-width: 319px) 
and (max-device-width: 480px) {


 }

 .popup-main-notifimodal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px; /* Adjust as needed */
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.popup-main-notifimodal-container .dialog-content-text {
  font-size: 16px;
  color: #333;
}

.popup-main-notifimodal-container .popup-footer {
  text-align: right;
  margin-top: 10px;
}

.popup-main-notifimodal-container .modal-close {
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.popup-main-notifimodal-container .modal-close:hover {
  background: darkred;
}

/* .title-text {
  font-size: 20px;
} */

.category-wrapper {
  position: relative;
  display: inline-block;
}

.submenu-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  width: 180px;
  padding: 8px 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.category-wrapper:hover .submenu-dropdown {
  opacity: 1;
  visibility: visible;
}

.submenu-item {
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submenu-item:hover {
  background: #f2f2f2;
}

