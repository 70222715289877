Chatbot container styles
.chatbot-container {
    max-width: 400px;
    margin: 20px auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Chat messages styles */
  .chatbot-messages {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    width: 100%;
  }
  
  .message {
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 8px;
    margin: 5px 0;
    max-width: 80%;
    word-wrap: break-word;
  }
  
  .user {
    background-color: #0084ff;
    color: #fff;
    align-self: flex-end;
  }
  
  /* Input styles */
  .user-input {
    width: 100%;
    padding: 10px;
    border: none;
    border-top: 1px solid #ccc;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 16px;
    margin-top: 10px;
  }
  
  /* Send button styles */
  .send-button {
    background-color: #0084ff;
    color: #fff;
    border: none;
    border-radius: 0;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
  }
  
  /* Robot chatbot styles */
  .bot {
    background-color: #f0f0f0;
    color: #000;
  }
  
  /* Robot icon styles */
  .chatbot-icon {
    width: 100px;
    height: 100px;
    background-image: url('../../../../assets/images/chatbot.png');
    background-size: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  /* Chatbot icon styles */
.chatbot-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
  }
  
  /* Full-screen popup styles */
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
  }
  
  /* Chat messages styles */
  .chat-messages {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .message {
    padding: 8px;
    margin: 5px 0;
    border-radius: 5px;
  }
  
  .user {
    background-color: #0084ff;
    color: #fff;
    align-self: flex-end;
  }
  
  /* User input and send button styles */
  .user-input {
    width: 100%;
    padding: 10px;
    border: none;
    border-top: 1px solid #ccc;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 16px;
    margin-top: 10px;
  }
  
  send {
    background-color: #0084ff;
    color: #fff;
    border: none;
    border-radius: 0;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
  }
  /* Add this CSS to your Chatbot.css file */
  .chatbot-container {
    position: fixed;
    bottom: 2px;
    right: 2px;
    cursor: pointer;
    animation: shake 0.5s ease-in-out infinite;
    /* animation-delay: 5s; Delay the start of the animation for 5 seconds */
    animation-iteration-count: 4;
  }

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-2px); /* Adjust the value for the desired shake intensity */
  }
  20%, 40%, 60%, 80% {
    transform: translateX(3px); /* Adjust the value for the desired shake intensity */
  }
}

